import React, { useEffect, useMemo, useState } from "react";
import "./Pricenew.css";
import PPDog from "./img/PPDog.png";
import PPGirl from "./img/PPGirl.png";
import { Link, useNavigate } from "react-router-dom";
import priceBgImg from "./img/PricingPageBackground.png";
import HttpClient from "../../../utils/HttpClient";
import { useLanguage } from "../../../Providers/LanguageContext";
import { useUserData } from "../../../Providers/UserWrapper";
import { reactLocalStorage } from "reactjs-localstorage";
import toast from "react-hot-toast";

const Pricenew = () => {
  const { language, currentLanguage } = useLanguage();
  const { profileData, isLogin, isBusiness, isPersonal, isBookKeeper, isInvestor, setGetNowUser, regCodeData } = useUserData();
  const navigate = useNavigate();
  const [isMonthly, setIsmonthly] = useState(true);

  const [isBuSelect, setIsBuSelect] = useState(true);
  const [businessBtn, setBusinessBtn] = useState(false);
  const [personalBtn, setPersonalBtn] = useState(false);
  const [bookkeeperBtn, setBookkeeperBtn] = useState(false);
  const [investorBtn, setInvestorBtn] = useState(false);

  const [price, setPrice] = useState([]);
  const [stripeData, setStripeData] = useState({
    ammount: "",
    id: "",
    duration: "",
    devPriceID: "",
  });

  const [priceData, setPriceData] = useState({
    unlimited: "",
    starter: "",
    smallBusiness: "",
    midSize: "",

    unlimited_id: "",
    starter_id: "",
    smallBusiness_id: "",
    midSize_id: "",

    starterBook: "",
    smallBusinessBook: "",
    midSizeBook: "",
    starterBook_id: "",
    smallBusinessBook_id: "",
    midSizeBook_id: "",

    starterInv: "",
    smallBusinessInv: "",
    midSizeInv: "",
    starterInv_id: "",
    smallBusinessInv_id: "",
    midSizeInv_id: "",
  });

  const handClick = () => {
    // setPersonal(true);
    navigate("/creataccount", { state: { userType: "Personal" } });
    setGetNowUser("Personal");
  };

  const [empresa, setEmpresa] = useState(false);
  const handClick2 = () => {
    // setEmpresa(true);
    navigate("/creataccount", { state: { userType: "Business" } });
    setGetNowUser("Business");
  };

  const getPrices = async () => {
    let result = await HttpClient.requestData("get-price-new", "GET", {});
    // let result = await HttpClient.requestData("get-price", "GET", {});
    console.log("resultPrice", result);
    if (result && result.status) {
      setPrice(result.data);
    }

    // console.log('result2', result2);
  };

  const monthYearMemo = useMemo(() => {
    if (isMonthly) {
      return language.monthly;
    } else {
      return language.yearly;
    }
  }, [isMonthly, currentLanguage]);

  const currencyMemo = useMemo(() => {
    if (currentLanguage === "eng") {
      return " USD";
    } else {
      return " MXN";
    }
  }, [currentLanguage]);

  const showBusinessMemo = useMemo(() => {
    if (isLogin) {
      if (isBusiness) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }, [profileData, isLogin]);

  const showPersonalMemo = useMemo(() => {
    if (isLogin) {
      if (isBusiness) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }, [profileData, isLogin]);

  useEffect(() => {
    getPrices();
  }, []);

  // to show price in ui
  const changePriceData = (arrCurrency) => {
    // console.log("arrCurrency", arrCurrency)
    setPriceData({
      unlimited: arrCurrency.filter((item) => item.payType == "unlimited")[0]?.amount,
      starter: arrCurrency.filter((item) => item.payType == "starter")[0]?.amount,
      smallBusiness: arrCurrency.filter((item) => item.payType == "small business plan")[0]?.amount,
      midSize: arrCurrency.filter((item) => item.payType == "mid size plan")[0]?.amount,

      unlimited_id: arrCurrency.filter((item) => item.payType == "unlimited")[0]?._id,
      starter_id: arrCurrency.filter((item) => item.payType == "starter")[0]?._id,
      smallBusiness_id: arrCurrency.filter((item) => item.payType == "small business plan")[0]?._id,
      midSize_id: arrCurrency.filter((item) => item.payType == "mid size plan")[0]?._id,

      starterBook: arrCurrency.filter((item) => item.payType == "starter firm")[0]?.amount,
      smallBusinessBook: arrCurrency.filter((item) => item.payType == "small firm")[0]?.amount,
      midSizeBook: arrCurrency.filter((item) => item.payType == "mid-size firm")[0]?.amount,
      starterBook_id: arrCurrency.filter((item) => item.payType == "starter firm")[0]?._id,
      smallBusinessBook_id: arrCurrency.filter((item) => item.payType == "small firm")[0]?._id,
      midSizeBook_id: arrCurrency.filter((item) => item.payType == "mid-size firm")[0]?._id,

      starterInv: arrCurrency.filter((item) => item.payType == "starter inv")[0]?.amount,
      smallBusinessInv: arrCurrency.filter((item) => item.payType == "small inv")[0]?.amount,
      midSizeInv: arrCurrency.filter((item) => item.payType == "Mid-Size inv")[0]?.amount,
      starterInv_id: arrCurrency.filter((item) => item.payType == "starter inv")[0]?._id,
      smallBusinessInv_id: arrCurrency.filter((item) => item.payType == "small inv")[0]?._id,
      midSizeInv_id: arrCurrency.filter((item) => item.payType == "Mid-Size inv")[0]?._id,

    });
  };

  useEffect(() => {
    // setBusinessBtn(true);
    if (price.length) {
      if (isMonthly) {
        let arr1 = price.filter((item) => item.duration === "Monthly");
        if (currentLanguage === "eng") {
          let arrCurrency = arr1.filter((item) => item.currency === "USD");
          changePriceData(arrCurrency);
        } else {
          let arrCurrency = arr1.filter((item) => item.currency === "MXN");
          changePriceData(arrCurrency);
        }
      } else {
        let arr1 = price.filter((item) => item.duration === "Yearly");
        if (currentLanguage === "eng") {
          let arrCurrency = arr1.filter((item) => item.currency === "USD");
          changePriceData(arrCurrency);
        } else {
          let arrCurrency = arr1.filter((item) => item.currency === "MXN");
          changePriceData(arrCurrency);
        }
      }
    }
  }, [isMonthly, price, currentLanguage]);

  // show business price
  const showBuPriceMemo = useMemo(() => {
    if (isLogin) {
      if (isBusiness) return true;
      else return false;
    } else {
      if (isBuSelect) return true;
      else return false;
    }
  }, [isLogin, isBuSelect, isBusiness]);

  // show personal price
  const showPuPriceMemo = useMemo(() => {
    if (isLogin) {
      if (isPersonal) return true;
      else return false;
    } else {
      if (personalBtn) return true;
      else return false;
    }
  }, [isLogin, personalBtn, isPersonal]);

  // show personal price
  const showBookPriceMemo = useMemo(() => {
    if (isLogin) {
      if (isBookKeeper) return true;
      else return false;
    } else {
      if (bookkeeperBtn) return true;
      else return false;
    }
  }, [isLogin, bookkeeperBtn, isBookKeeper]);

  // show personal price
  const showInvestPriceMemo = useMemo(() => {
    if (isLogin) {
      if (isInvestor) return true;
      else return false;
    } else {
      if (investorBtn) return true;
      else return false;
    }
  }, [isLogin, investorBtn, isInvestor]);



  const navigateNameMemo = useMemo(() => {
    if (businessBtn) return "Business"
    else if (personalBtn) return "Personal"
    else if (bookkeeperBtn) return "Bookkeeper"
    else if (investorBtn) return "Investor"
    else return "Business"
  }, [businessBtn, personalBtn, bookkeeperBtn])

  // console.log("bbttnn", navigateNameMemo)

  // **********business price**************
  const [anualPrice, setAnualPrice] = useState({
    starter: "",
    smallBusiness: "",
    midSize: "",
    starter_id: "",
    smallBusiness_id: "",
    midSize_id: "",
    devPriceID_start: "",
    devPriceID_small: "",
    devPriceID_mid: "",
    unlimited: "",
    unlimited_id: "",
    devPriceID: "",

    // bookkeeper fields
    starterBook: "",
    smallBusinessBook: "",
    midSizeBook: "",

    devPriceID_starterBook: "",
    devPriceID_smallBusinessBook: "",
    devPriceID_midSizeBook: "",

    starterBook_id: "",
    smallBusinessBook_id: "",
    midSizeBook_id: "",

    // investor
    starterInv: "",
    smallBusinessInv: "",
    midSizeInv: "",

    devPriceID_starterInv: "",
    devPriceID_smallBusinessInv: "",
    devPriceID_midSizeInv: "",

    starterInv_id: "",
    smallBusinessInv_id: "",
    midSizeInv_id: "",

  });

  const [monthlyPrice, setMonthlyPrice] = useState({
    starter: "",
    smallBusiness: "",
    midSize: "",
    unlimited: "",

    starter_id: "",
    smallBusiness_id: "",
    midSize_id: "",
    unlimited_id: "",

    priceId: "",
    devPriceID_start: "",
    devPriceID_small: "",
    devPriceID_mid: "",

    devPriceID: "",
    student: "",
    student_id: "",
    devPriceID_std: "",

    // bookkeeper fields
    starterBook: "",
    smallBusinessBook: "",
    midSizeBook: "",

    devPriceID_starterBook: "",
    devPriceID_smallBusinessBook: "",
    devPriceID_midSizeBook: "",

    starterBook_id: "",
    smallBusinessBook_id: "",
    midSizeBook_id: "",

    // investor
    starterInv: "",
    smallBusinessInv: "",
    midSizeInv: "",

    devPriceID_starterInv: "",
    devPriceID_smallBusinessInv: "",
    devPriceID_midSizeInv: "",

    starterInv_id: "",
    smallBusinessInv_id: "",
    midSizeInv_id: "",
  });

  // console.log("anualPrice", anualPrice)

  const getNewPrice = async () => {
    const res = await HttpClient.requestData(isLogin ? "get-price-new" : "get-price", "GET");
    // const res = await HttpClient.requestData("get-price", "GET");
    console.log('newpriceRes', res);
    if (res && res.status) {
      setPrice(res.data);
    }
  };

  const isOwnerMemo = useMemo(() => {
    if (profileData) {
      if (profileData.userType && profileData.accountType === "Owner") {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }, [profileData]);

  const isStudentMemo = useMemo(() => {
    if (profileData) {
      if (profileData.fromWhichReffCode) {
        if (profileData.fromWhichReffCode == "PUEDU") {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }, [profileData]);

  const isManagerMemo = useMemo(() => {
    if (profileData) {
      if (
        profileData.userType === "Personal" &&
        profileData.accountType === "Manager"
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }, [profileData]);

  useEffect(() => {
    getNewPrice();
    setBusinessBtn(true);
  }, []);

  useEffect(() => {
    if (Object.values(stripeData).every((item) => item !== "")) {
      reactLocalStorage.setObject("stripeData", stripeData);
    }
  }, [stripeData]);

  useEffect(() => {
    if (price.length) {
      // let arr1 = price.filter((item) => item.userType === "business");
      let arr1 = price;

      let montprice = arr1.filter((item) => item.duration === "Monthly");
      if (currentLanguage === "eng") {
        let arrCurrency = montprice.filter((item) => item.currency === "USD");
        changeMonthlyPrice(arrCurrency);
      } else {
        let arrCurrency = montprice.filter((item) => item.currency === "MXN");
        changeMonthlyPrice(arrCurrency);
      }

      let yearPrice = arr1.filter((item) => item.duration === "Yearly");
      if (currentLanguage === "eng") {
        let arrCurrency = yearPrice.filter((item) => item.currency === "USD");
        changeAnualPrice(arrCurrency);
      } else {
        let arrCurrency = yearPrice.filter((item) => item.currency === "MXN");
        changeAnualPrice(arrCurrency);
      }
    }
  }, [price, currentLanguage]);

  // change monthly price
  const changeMonthlyPrice = (arrCurrency) => {
    // console.log('arrCurrency', arrCurrency)
    if (profileData && profileData.fromWhichReffCode && !profileData.name) {
      setMonthlyPrice({
        starter: (arrCurrency.filter((item) => item.payType == "starter")[0].amount * 0.85)?.toFixed(2),
        smallBusiness: (arrCurrency.filter((item) => item.payType == "small business plan")[0].amount * 0.85)?.toFixed(2),
        midSize: (arrCurrency.filter((item) => item.payType == "mid size plan")[0].amount * 0.85)?.toFixed(2),

        starter_id: arrCurrency.filter((item) => item.payType == "starter")[0]._id,
        smallBusiness_id: arrCurrency.filter((item) => item.payType == "small business plan")[0]._id,
        midSize_id: arrCurrency.filter((item) => item.payType == "mid size plan")[0]._id,

        devPriceID_start: arrCurrency.filter((item) => item.payType == "starter")[0].discountedDevPriceID,
        devPriceID_small: arrCurrency.filter((item) => item.payType == "small business plan")[0].discountedDevPriceID,
        devPriceID_mid: arrCurrency.filter((item) => item.payType == "mid size plan")[0].discountedDevPriceID,

        unlimited: (arrCurrency.filter((item) => item.payType == "unlimited")[0].amount * 0.85)?.toFixed(2),
        unlimited_id: arrCurrency.filter((item) => item.payType == "unlimited")[0]._id,
        devPriceID: arrCurrency.filter((item) => item.payType == "unlimited")[0].discountedDevPriceID,

        student: (arrCurrency.filter((item) => item.payType == "university students")[0].amount * 0.85)?.toFixed(2),
        student_id: arrCurrency.filter((item) => item.payType == "university students")[0]._id,
        devPriceID_std: arrCurrency.filter((item) => item.payType == "university students")[0].devPriceID,

        // bookkeeper fields
        starterBook: (arrCurrency.filter((item) => item.payType == "starter firm")[0].amount * 0.85)?.toFixed(2),
        smallBusinessBook: (arrCurrency.filter((item) => item.payType == "small firm")[0].amount * 0.85)?.toFixed(2),
        midSizeBook: (arrCurrency.filter((item) => item.payType == "mid-size firm")[0].amount * 0.85)?.toFixed(2),

        starterBook_id: arrCurrency.filter((item) => item.payType == "starter firm")[0]._id,
        smallBusinessBook_id: arrCurrency.filter((item) => item.payType == "small firm")[0]._id,
        midSizeBook_id: arrCurrency.filter((item) => item.payType == "mid-size firm")[0]._id,

        devPriceID_starterBook: arrCurrency.filter((item) => item.payType == "starter firm")[0]?.discountedDevPriceID,
        devPriceID_smallBusinessBook: arrCurrency.filter((item) => item.payType == "small firm")[0]?.discountedDevPriceID,
        devPriceID_midSizeBook: arrCurrency.filter((item) => item.payType == "mid-size firm")[0]?.discountedDevPriceID,

        // investor
        starterInv: (arrCurrency.filter((item) => item.payType == "starter inv")[0].amount * 0.85)?.toFixed(2),
        smallBusinessInv: (arrCurrency.filter((item) => item.payType == "small inv")[0].amount * 0.85)?.toFixed(2),
        midSizeInv: (arrCurrency.filter((item) => item.payType == "Mid-Size inv")[0].amount * 0.85)?.toFixed(2),

        starterInv_id: arrCurrency.filter((item) => item.payType == "starter firm")[0]._id,
        smallBusinessInv_id: arrCurrency.filter((item) => item.payType == "small firm")[0]._id,
        midSizeInv_id: arrCurrency.filter((item) => item.payType == "mid-size firm")[0]._id,

        devPriceID_starterInv: arrCurrency.filter((item) => item.payType == "starter firm")[0]?.discountedDevPriceID,
        devPriceID_smallBusinessInv: arrCurrency.filter((item) => item.payType == "small firm")[0]?.discountedDevPriceID,
        devPriceID_midSizeInv: arrCurrency.filter((item) => item.payType == "mid-size firm")[0]?.discountedDevPriceID,
      });
    } else {
      setMonthlyPrice({
        starter: arrCurrency.filter((item) => item.payType == "starter")[0].amount,
        smallBusiness: arrCurrency.filter((item) => item.payType == "small business plan")[0].amount,
        midSize: arrCurrency.filter((item) => item.payType == "mid size plan")[0].amount,

        starter_id: arrCurrency.filter((item) => item.payType == "starter")[0]._id,
        smallBusiness_id: arrCurrency.filter((item) => item.payType == "small business plan")[0]._id,
        midSize_id: arrCurrency.filter((item) => item.payType == "mid size plan")[0]._id,

        devPriceID_start: arrCurrency.filter((item) => item.payType == "starter")[0].devPriceID,
        devPriceID_small: arrCurrency.filter((item) => item.payType == "small business plan")[0].devPriceID,
        devPriceID_mid: arrCurrency.filter((item) => item.payType == "mid size plan")[0].devPriceID,

        unlimited: arrCurrency.filter((item) => item.payType == "unlimited")[0].amount,
        unlimited_id: arrCurrency.filter((item) => item.payType == "unlimited")[0]._id,
        devPriceID: arrCurrency.filter((item) => item.payType == "unlimited")[0].devPriceID,

        student: arrCurrency.filter((item) => item.payType == "university students")[0].amount,
        student_id: arrCurrency.filter((item) => item.payType == "university students")[0]._id,
        devPriceID_std: arrCurrency.filter((item) => item.payType == "university students")[0].devPriceID,

        // bookkeeper field
        starterBook: arrCurrency.filter((item) => item.payType == "starter firm")[0].amount,
        smallBusinessBook: arrCurrency.filter((item) => item.payType == "small firm")[0].amount,
        midSizeBook: arrCurrency.filter((item) => item.payType == "mid-size firm")[0].amount,

        starterBook_id: arrCurrency.filter((item) => item.payType == "starter firm")[0]._id,
        smallBusinessBook_id: arrCurrency.filter((item) => item.payType == "small firm")[0]._id,
        midSizeBook_id: arrCurrency.filter((item) => item.payType == "mid-size firm")[0]._id,

        devPriceID_starterBook: arrCurrency.filter((item) => item.payType == "starter firm")[0].devPriceID,
        devPriceID_smallBusinessBook: arrCurrency.filter((item) => item.payType == "small firm")[0].devPriceID,
        devPriceID_midSizeBook: arrCurrency.filter((item) => item.payType == "mid-size firm")[0].devPriceID,

        // investor
        starterInv: arrCurrency.filter((item) => item.payType == "starter inv")[0]?.amount,
        smallBusinessInv: arrCurrency.filter((item) => item.payType == "small inv")[0]?.amount,
        midSizeInv: arrCurrency.filter((item) => item.payType == "Mid-Size inv")[0]?.amount,

        starterInv_id: arrCurrency.filter((item) => item.payType == "starter inv")[0]?._id,
        smallBusinessInv_id: arrCurrency.filter((item) => item.payType == "small inv")[0]?._id,
        midSizeInv_id: arrCurrency.filter((item) => item.payType == "Mid-Size inv")[0]?._id,

        devPriceID_starterInv: arrCurrency.filter((item) => item.payType == "starter inv")[0]?.devPriceID,
        devPriceID_smallBusinessInv: arrCurrency.filter((item) => item.payType == "small inv")[0]?.devPriceID,
        devPriceID_midSizeInv: arrCurrency.filter((item) => item.payType == "Mid-Size inv")[0]?.devPriceID,
      });
    }
  };

  // change Annual price
  const changeAnualPrice = (arrCurrency) => {
    if (profileData && profileData.fromWhichReffCode && !profileData.name) {
      setAnualPrice({
        starter: (arrCurrency.filter((item) => item.payType == "starter")[0].amount * 0.85)?.toFixed(2),
        smallBusiness: (arrCurrency.filter((item) => item.payType == "small business plan")[0].amount * 0.85)?.toFixed(2),
        midSize: (arrCurrency.filter((item) => item.payType == "mid size plan")[0].amount * 0.85)?.toFixed(2),

        starter_id: arrCurrency.filter((item) => item.payType == "starter")[0]._id,
        smallBusiness_id: arrCurrency.filter((item) => item.payType == "small business plan")[0]._id,
        midSize_id: arrCurrency.filter((item) => item.payType == "mid size plan")[0]._id,

        devPriceID_start: arrCurrency.filter((item) => item.payType == "starter")[0].discountedDevPriceID,
        devPriceID_small: arrCurrency.filter((item) => item.payType == "small business plan")[0].discountedDevPriceID,
        devPriceID_mid: arrCurrency.filter((item) => item.payType == "mid size plan")[0].discountedDevPriceID,

        unlimited: (arrCurrency.filter((item) => item.payType == "unlimited")[0].amount * 0.85)?.toFixed(2),
        unlimited_id: arrCurrency.filter((item) => item.payType == "unlimited")[0]._id,
        devPriceID: arrCurrency.filter((item) => item.payType == "unlimited")[0].discountedDevPriceID,

        // bookkeeper fields
        starterBook: (arrCurrency.filter((item) => item.payType == "starter firm")[0].amount * 0.85)?.toFixed(2),
        smallBusinessBook: (arrCurrency.filter((item) => item.payType == "small firm")[0].amount * 0.85)?.toFixed(2),
        midSizeBook: (arrCurrency.filter((item) => item.payType == "mid-size firm")[0].amount * 0.85)?.toFixed(2),

        starterBook_id: arrCurrency.filter((item) => item.payType == "starter firm")[0]._id,
        smallBusinessBook_id: arrCurrency.filter((item) => item.payType == "small firm")[0]._id,
        midSizeBook_id: arrCurrency.filter((item) => item.payType == "mid-size firm")[0]._id,

        devPriceID_starterBook: arrCurrency.filter((item) => item.payType == "starter firm")[0]?.discountedDevPriceID,
        devPriceID_smallBusinessBook: arrCurrency.filter((item) => item.payType == "small firm")[0]?.discountedDevPriceID,
        devPriceID_midSizeBook: arrCurrency.filter((item) => item.payType == "mid-size firm")[0]?.discountedDevPriceID,

        // investor
        starterInv: (arrCurrency.filter((item) => item.payType == "starter firm")[0].amount * 0.85)?.toFixed(2),
        smallBusinessInv: (arrCurrency.filter((item) => item.payType == "small firm")[0].amount * 0.85)?.toFixed(2),
        midSizeInv: (arrCurrency.filter((item) => item.payType == "mid-size firm")[0].amount * 0.85)?.toFixed(2),

        starterInv_id: arrCurrency.filter((item) => item.payType == "starter firm")[0]._id,
        smallBusinessInv_id: arrCurrency.filter((item) => item.payType == "small firm")[0]._id,
        midSizeInv_id: arrCurrency.filter((item) => item.payType == "mid-size firm")[0]._id,

        devPriceID_starterInv: arrCurrency.filter((item) => item.payType == "starter firm")[0]?.discountedDevPriceID,
        devPriceID_smallBusinessInv: arrCurrency.filter((item) => item.payType == "small firm")[0]?.discountedDevPriceID,
        devPriceID_midSizeInv: arrCurrency.filter((item) => item.payType == "mid-size firm")[0]?.discountedDevPriceID,
      });
    } else {
      setAnualPrice({
        starter: arrCurrency.filter((item) => item.payType == "starter")[0].amount,
        smallBusiness: arrCurrency.filter((item) => item.payType == "small business plan")[0].amount,
        midSize: arrCurrency.filter((item) => item.payType == "mid size plan")[0].amount,

        starter_id: arrCurrency.filter((item) => item.payType == "starter")[0]._id,
        smallBusiness_id: arrCurrency.filter((item) => item.payType == "small business plan")[0]._id,
        midSize_id: arrCurrency.filter((item) => item.payType == "mid size plan")[0]._id,

        devPriceID_start: arrCurrency.filter((item) => item.payType == "starter")[0].devPriceID,
        devPriceID_small: arrCurrency.filter((item) => item.payType == "small business plan")[0].devPriceID,
        devPriceID_mid: arrCurrency.filter((item) => item.payType == "mid size plan")[0].devPriceID,

        unlimited: arrCurrency.filter((item) => item.payType == "unlimited")[0].amount,
        unlimited_id: arrCurrency.filter((item) => item.payType == "unlimited")[0]._id,
        devPriceID: arrCurrency.filter((item) => item.payType == "unlimited")[0].devPriceID,

        // bookkeeper field
        starterBook: arrCurrency.filter((item) => item.payType == "starter firm")[0].amount,
        smallBusinessBook: arrCurrency.filter((item) => item.payType == "small firm")[0].amount,
        midSizeBook: arrCurrency.filter((item) => item.payType == "mid-size firm")[0].amount,

        starterBook_id: arrCurrency.filter((item) => item.payType == "starter firm")[0]._id,
        smallBusinessBook_id: arrCurrency.filter((item) => item.payType == "small firm")[0]._id,
        midSizeBook_id: arrCurrency.filter((item) => item.payType == "mid-size firm")[0]._id,

        devPriceID_starterBook: arrCurrency.filter((item) => item.payType == "starter firm")[0].devPriceID,
        devPriceID_smallBusinessBook: arrCurrency.filter((item) => item.payType == "small firm")[0].devPriceID,
        devPriceID_midSizeBook: arrCurrency.filter((item) => item.payType == "mid-size firm")[0].devPriceID,

        // investor
        starterInv: arrCurrency.filter((item) => item.payType == "starter firm")[0].amount,
        smallBusinessInv: arrCurrency.filter((item) => item.payType == "small firm")[0].amount,
        midSizeInv: arrCurrency.filter((item) => item.payType == "mid-size firm")[0].amount,

        starterInv_id: arrCurrency.filter((item) => item.payType == "starter firm")[0]._id,
        smallBusinessInv_id: arrCurrency.filter((item) => item.payType == "small firm")[0]._id,
        midSizeInv_id: arrCurrency.filter((item) => item.payType == "mid-size firm")[0]._id,

        devPriceID_starterInv: arrCurrency.filter((item) => item.payType == "starter firm")[0].devPriceID,
        devPriceID_smallBusinessInv: arrCurrency.filter((item) => item.payType == "small firm")[0].devPriceID,
        devPriceID_midSizeInv: arrCurrency.filter((item) => item.payType == "mid-size firm")[0].devPriceID,
      });
    }
  };

  const createCheckout = async (priceID) => {
    // const user = reactLocalStorage.getObject("userData");
    console.log("usertt", priceID)

    const data = {
      line_items: [
        {
          price: priceID,
          quantity: 1,
        },
      ],
    };
    const res = await HttpClient.requestData("create-checkout", "POST", data);
    // console.log('resbaler', res)
    if (res?.status) {
      window.location.href = res.url;
    }
  };
  // **********End business price**************


  return (
    <>
      <section
        className="pricenewSection"
        style={{ backgroundImage: `url(${priceBgImg})` }}
      >
        <main>

          {/* Chavez */}
          <div className="container-all">
            <h1 id="pricing--header">{language.industry_lead_price_plan}</h1>
            <h2 id="pricing--tagline">{language.because_youare_worth_it}</h2>
            <div className="contenedor">
              <div className="izquierda">
                <img src={PPDog} alt="imagen 1" />
              </div>

              {/* button group */}
              <div className="centro">
                {!isLogin && (
                  <div className="botones-contenedor">

                    {/* Business Button */}
                    <button
                      id="business--button"
                      className="boton-circular seleccionado"
                      style={{
                        backgroundColor: businessBtn ? "#0D283D" : "",
                        color: businessBtn ? "white" : "",
                      }}
                      onClick={() => {
                        setIsBuSelect(true);
                        setPersonalBtn(false);
                        setBusinessBtn(true);
                        setBookkeeperBtn(false);
                        setInvestorBtn(false);
                      }}
                    >
                      Business
                    </button>

                    {/* Personal BUtton */}
                    <button
                      id="personal--button"
                      style={{
                        backgroundColor: personalBtn ? "#297F74" : "",
                        color: personalBtn ? "white" : "",
                      }}
                      className="boton-circular seleccionado"
                      onClick={() => {
                        setPersonalBtn(true);
                        setBusinessBtn(false);
                        setIsBuSelect(false);
                        setBookkeeperBtn(false);
                        setInvestorBtn(false);
                      }}
                    >
                      Personal
                    </button>

                    {/* Bookkeeper Button */}
                    <button
                      id="personal--button"
                      style={{
                        backgroundColor: bookkeeperBtn ? "#0D283D" : "",
                        color: bookkeeperBtn ? "white" : "",
                      }}
                      className="boton-circular seleccionado"
                      onClick={() => {
                        setBookkeeperBtn(true)
                        setPersonalBtn(false);
                        setBusinessBtn(false);
                        setIsBuSelect(false);
                        setInvestorBtn(false);
                      }}
                    >
                      Bookkeeper
                    </button>

                    {/* Investor Button */}
                    <button
                      id="personal--button"
                      style={{
                        backgroundColor: investorBtn ? "#297F74" : "",
                        color: investorBtn ? "white" : "",
                      }}
                      className="boton-circular seleccionado"
                      onClick={() => {
                        setPersonalBtn(false);
                        setBusinessBtn(false);
                        setIsBuSelect(false);
                        setBookkeeperBtn(false)
                        setInvestorBtn(true)
                      }}
                    >
                      Investor
                    </button>

                  </div>
                )}
                <p id="period--txt">{language.billing_period}</p>

                {/* toggle button */}
                <section>
                  <div className="boton">
                    <p id="month--opt">{language.monthly}</p>
                    <input
                      type="checkbox"
                      id="btn-switch"
                      onChange={(e) => {
                        // console.log("checkedPrice", e.target.checked);
                        const check = e.target.checked;
                        setIsmonthly(!check);
                      }}
                    />
                    <label htmlFor="btn-switch" className="lbl-switch" />
                    <p id="year--opt">{language.annually}</p>
                  </div>
                </section>

              </div>

              {/* right side image */}
              <div className="derecha">
                <img src={PPGirl} alt="imagen 2" />
              </div>

            </div>
          </div>

          {/* price card section */}
          <div className="My_card-container">
            <div className="card-container">

              {/* free 1 */}
              <div className="card businessCard">
                <div className="card-content-price">
                  <p id="card1--p1">{language.no_credit_card_required}</p>
                  <h3 id="card1--h3--1">{language.Free}</h3>
                  <div className="user">
                    <p id="card1--p2">
                      <span class="subrayar">1</span> {language.User} (ADMIN)
                    </p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      width={34}
                      height={34}
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                        stroke="#0D283D"
                      />
                    </svg>
                  </div>
                  <h3>$0</h3>
                  <p id="card1--p3">{language.forever}</p>

                  {/* <Link to="/" className="btn" id="card1--abtn">
                  {language.Get_Started_For_Free}
                </Link> */}
                  <button
                    onClick={() => {
                      if (!isLogin) {
                        navigate("/creataccount", {
                          state: {
                            userType: navigateNameMemo,
                            discCode: "ADMIN",
                          },
                        });
                      }
                    }}
                    className="btn"
                    id="card1--abtn"
                    disabled={isLogin ? true : false}
                    style={{ marginTop: "5px" }}
                  >
                    {language.Get_Started_For_Free}
                  </button>
                  <div className="checkPrice">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      width={34}
                      height={34}
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 12.75 11.25 15 15 9.75"
                        stroke="#0D283D"
                        fill="none"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                        stroke="#0D283D"
                      />
                    </svg>
                    <p id="card1--p4">{language.Instant_User_Benefits}</p>
                  </div>

                  {/* 1st */}
                  <p id="card1--p5">
                    <span class="subrayar">{language.Organize}</span>{" "}
                    {language.Organize_value}
                  </p>

                  {/* 2nd */}
                  {showPuPriceMemo && (
                    <p id="card1--p6">
                      <span class="subrayar">{language.Streamline}</span>{" "}
                      {language.Streamline_value}
                    </p>
                  )}

                  {showBuPriceMemo && (
                    <p id="card1--p6">
                      <span class="subrayar">{language.stream_}</span>{" "}
                      {language.stream_Desc}
                    </p>
                  )}

                  {
                    showBookPriceMemo &&
                    <p id="card1--p6">
                      <span class="subrayar">{language.stream_}</span>{" "}
                      {language.comm_client_team}
                    </p>
                  }


                  {/* 3rd */}
                  {showPuPriceMemo && (
                    <p id="card1--p7">
                      <span class="subrayar">{language.Showcase}</span>{" "}
                      {language.Showcase_value}
                    </p>
                  )}

                  {showBuPriceMemo && (
                    <p id="card1--p7">
                      <span class="subrayar">
                        {language.Personal_Eliminate}
                      </span>{" "}
                      {language.personal_emiminate_desc}
                    </p>
                  )}

                  {
                    showBookPriceMemo &&
                    <p id="card1--p7">
                      <span class="subrayar">
                        {language.Connect}
                      </span>{" "}
                      {language.link_all_client}
                    </p>
                  }

                </div>
              </div>

              {/* for business price */}
              {showBuPriceMemo && (
                <>
                  {/* Starter */}
                  <div className="card businessCard">
                    <div className="card-content-price">
                      <p
                        className="p:first-child"
                        id="card2--p1"
                        style={{ color: "red", height: "10px" }}
                      >
                        {!isMonthly ? language.Strarter_SAVE : " "}
                      </p>

                      <h3 id="card2--h3--1">{language.Starter}</h3>
                      <div className="user">
                        <p id="card2--p2">
                          {language.Includes} <span class="subrayar">5</span>{" "}
                          {language.users}
                        </p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          width={34}
                          height={34}
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                            stroke="#0D283D"
                          />
                        </svg>
                      </div>
                      <h3 id="card2--h3--2">
                        {priceData.starter + `${currencyMemo}`}
                      </h3>
                      <p id="card2--p3">
                        / {monthYearMemo} ({language.billed} {monthYearMemo})
                      </p>
                      <a
                        className="btn"
                        id="card2--abtn"
                        onClick={() => {
                          if (isLogin) {
                            if (isOwnerMemo) {
                              if (regCodeData.total <= 5) {
                                if (isMonthly) {
                                  createCheckout(monthlyPrice.devPriceID_start);

                                  setStripeData({
                                    ammount: monthlyPrice.starter,
                                    id: monthlyPrice.starter_id,
                                    duration: "Monthly",
                                    devPriceID: monthlyPrice.devPriceID_start,
                                  });
                                } else {
                                  createCheckout(anualPrice.devPriceID_start);

                                  // setShowStripeModal(true)
                                  setStripeData({
                                    ammount: anualPrice.starter,
                                    id: anualPrice.starter_id,
                                    duration: "Yearly",
                                    devPriceID: anualPrice.devPriceID_start,
                                  });
                                }
                              } else {
                                toast.error(language.up_to_five_user);
                              }
                            } else {
                              toast.error(language.only_owner_Pay);
                            }
                          } else {
                            navigate("/creataccount", {
                              state: {
                                userType: isBuSelect ? "Business" : "Personal",
                                discCode: "",
                                priceId: isMonthly
                                  ? monthlyPrice.devPriceID_start
                                  : anualPrice.devPriceID_start,
                              },
                            });
                          }
                        }}
                      >
                        {language.Sign_Up_Pay_Now}
                      </a>
                      <div className="checkPrice">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          width={34}
                          height={34}
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 12.75 11.25 15 15 9.75"
                            stroke="#0D283D"
                            fill="none"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                            stroke="#0D283D"
                          />
                        </svg>
                        <p id="card2--p4">{language.Product_Features}</p>
                      </div>
                      <p id="card2--p5">
                        <span class="subrayar">{language.Web_Mobile}</span>{" "}
                        {language.interfaces_for_data}
                      </p>
                      <p id="card2--p6">
                        <span class="subrayar">{language.Central_Console}</span>{" "}
                        {language.access_control}
                      </p>
                      <p id="card2--p7">
                        <span class="subrayar">
                          {language.Dashboards_Reporting}
                        </span>{" "}
                        {language.for_real_time_visibility_insights}
                      </p>
                    </div>
                  </div>

                  {/* Small Business */}
                  <div className="card businessCard">
                    <div className="card-content-price">
                      {/*Aqui esta el div "popular"*/}
                      <div className="popular" id="card3--div">
                        {language.Most_popular}
                      </div>

                      <p
                        className="parrafo-centrar"
                        id="card3--p1"
                        style={{ color: "red", height: "10px", textAlign: "center", }}
                      >
                        {!isMonthly ? language.Bus_SAVE : " "}
                      </p>
                      <h3 id="card3--h3--1" style={{ color: "#297f74" }}>
                        {language.Small_Business}
                      </h3>
                      <div className="user">
                        <p
                          className="parrafo-especial"
                          id="card3--p2"
                          style={{ color: "#297f74" }}
                        >
                          {language.Includes}{" "}
                          <span class="subrayar" style={{ color: "#297f74" }}>
                            10
                          </span>{" "}
                          {language.users}
                        </p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          width={34}
                          height={34}
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                            stroke="#297F74"
                          />
                        </svg>
                      </div>
                      <h3 id="card3--h3--2" style={{ color: "#297f74" }}>
                        $ {priceData.smallBusiness + `${currencyMemo}`}
                      </h3>
                      <p id="card3--p3" style={{ color: "#297f74" }}>
                        / {monthYearMemo} ({language.billed} {monthYearMemo})
                      </p>
                      <a
                        className="btn btn-tercera-carta"
                        id="card3--abtn"
                        style={{ color: "white", backgroundColor: "#297f74" }}
                        onClick={() => {
                          if (isLogin) {
                            if (isOwnerMemo) {
                              if (regCodeData.total <= 10) {
                                if (isMonthly) {
                                  createCheckout(monthlyPrice.devPriceID_small);

                                  setStripeData({
                                    ammount: monthlyPrice.smallBusiness,
                                    id: monthlyPrice.smallBusiness_id,
                                    duration: "Monthly",
                                    devPriceID: monthlyPrice.devPriceID_small,
                                  });
                                } else {
                                  createCheckout(anualPrice.devPriceID_small);

                                  setStripeData({
                                    ammount: anualPrice.smallBusiness,
                                    id: anualPrice.smallBusiness_id,
                                    duration: "Yearly",
                                    devPriceID: anualPrice.devPriceID_small,
                                  });
                                }
                              } else {
                                toast.error(language.up_to_ten_user);
                              }
                            } else {
                              toast.error(language.only_owner_Pay);
                            }
                          } else {
                            navigate("/creataccount", {
                              state: {
                                userType: isBuSelect ? "Business" : "Personal",
                                discCode: "",
                                priceId: isMonthly
                                  ? monthlyPrice.devPriceID_small
                                  : anualPrice.devPriceID_small,
                              },
                            });
                          }
                        }}
                      >
                        {language.Sign_Up_Pay_Now}
                      </a>
                      <div className="checkPrice">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          width={34}
                          height={34}
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 12.75 11.25 15 15 9.75"
                            stroke="#297F74"
                            fill="none"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                            stroke="#297F74"
                          />
                        </svg>
                        <p id="card3--p4" style={{ color: "#297f74" }}>
                          {language.Additional_Features}
                        </p>
                      </div>
                      <p id="card3--p5" style={{ color: "#297f74" }}>
                        <span class="subrayar" style={{ color: "#297f74" }}>
                          {language.Bookkeeping_Accounting}
                        </span>{" "}
                        {language.Bookkeeping_value}
                      </p>
                      <p id="card3--p6" style={{ color: "#297f74" }}>
                        <span style={{ color: "#297f74" }} class="subrayar">
                          {language.Instant_Integrations}
                        </span>{" "}
                        {language.Instant_Integrations_value}
                      </p>
                      <p id="card3--p7" style={{ color: "#297f74" }}>
                        <span style={{ color: "#297f74" }} class="subrayar">
                          {language.Invoicing}
                        </span>{" "}
                        {language.Invoicing_value}
                      </p>
                    </div>
                  </div>

                  {/* Mid-Size */}
                  <div className="card businessCard">
                    <div className="card-content-price">
                      <p
                        className="p:first-child"
                        id="card4--p1"
                        style={{ color: "red", height: "10px" }}
                      >
                        {!isMonthly ? language.SAVE : " "}
                      </p>

                      <h3 id="card4--h3--1" style={{ color: "black" }}>
                        {language.Mid_Size}
                      </h3>
                      <div className="user">
                        <p id="card4--p2">
                          {language.Includes} <span class="subrayar">20</span>{" "}
                          {language.users}
                        </p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          width={34}
                          height={34}
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                            stroke="#0D283D"
                          />
                        </svg>
                      </div>
                      <h3 id="card4--h3--2" style={{ color: "black" }}>
                        {priceData.midSize + `${currencyMemo}`}
                      </h3>
                      <p id="cardP2--p3">
                        / {monthYearMemo} ({language.billed} {monthYearMemo})
                      </p>
                      <a
                        className="btn"
                        id="card4--abtn"
                        onClick={() => {
                          if (isLogin) {
                            if (isOwnerMemo) {
                              if (regCodeData.total <= 20) {
                                if (isMonthly) {
                                  createCheckout(monthlyPrice.devPriceID_mid);

                                  setStripeData({
                                    ammount: monthlyPrice.midSize,
                                    id: monthlyPrice.midSize_id,
                                    duration: "Monthly",
                                    devPriceID: monthlyPrice.devPriceID_mid,
                                  });
                                } else {
                                  createCheckout(anualPrice.devPriceID_mid);

                                  setStripeData({
                                    ammount: anualPrice.midSize,
                                    id: anualPrice.midSize_id,
                                    duration: "Yearly",
                                    devPriceID: anualPrice.devPriceID_mid,
                                  });
                                }
                              } else {
                                toast.error(language.up_to_twenty_user);
                              }
                            } else {
                              toast.error(language.only_owner_Pay);
                            }
                          } else {
                            navigate("/creataccount", {
                              state: {
                                userType: isBuSelect ? "Business" : "Personal",
                                discCode: "",
                                priceId: isMonthly
                                  ? monthlyPrice.devPriceID_mid
                                  : anualPrice.devPriceID_mid,
                              },
                            });
                          }
                        }}
                      >
                        {language.Sign_Up_Pay_Now}
                      </a>
                      <div className="checkPrice">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          width={34}
                          height={34}
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 12.75 11.25 15 15 9.75"
                            stroke="#0D283D"
                            fill="none"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                            stroke="#0D283D"
                          />
                        </svg>
                        <p id="card4--p4">{language.Obstacles_Solved}</p>
                      </div>
                      <p id="card4--p5">
                        <span class="subrayar">{language.Money_Leaks}</span>{" "}
                        {language.Money_Leaks_value}
                      </p>
                      <p id="card4--p6">
                        <span class="subrayar">{language.Disconnects}</span>{" "}
                        {language.Disconnects_value}
                      </p>
                      <p id="card4--p7">
                        <span class="subrayar">
                          {language.Headaches_Stress}
                        </span>{" "}
                        {language.Headaches_Stress_value}
                      </p>
                    </div>
                  </div>
                </>
              )}

              {/* for personal price */}
              {/* *Unlimited Users */}
              {showPuPriceMemo && (
                <div className="card personalCard">
                  <div className="card-content-price">
                    {/*Aqui esta el div "popular"*/}
                    <div className="popular" id="cardP2--div">
                      {language.per_Most_popular}
                    </div>

                    <p
                      className="parrafo-centrar"
                      id="cardP2--p1"
                      style={{
                        color: "red",
                        height: "10px",
                        textAlign: "center",
                      }}
                    >
                      {!isMonthly ? language.per_SAVE : ""}
                    </p>
                    <h3 id="cardP2--h3--1" style={{ color: "#297f74" }}>
                      {language.Per_Unlimited_Users}
                    </h3>
                    <div className="user">
                      <p
                        className="parrafo-especial"
                        id="cardP2--p2"
                        style={{ color: "#297f74" }}
                      >
                        {language.Unlimited_Per_Users}
                      </p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        width={34}
                        height={34}
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                          stroke="#297F74"
                        />
                      </svg>
                    </div>
                    <h3 id="cardP2--h3--2" style={{ color: "#297f74" }}>
                      ${priceData.unlimited + `${currencyMemo}`}
                    </h3>
                    <p id="cardP2--p3" style={{ color: "#297f74" }}>
                      / {monthYearMemo} (billed {monthYearMemo})
                    </p>
                    <a
                      className="btn btn-tercera-carta"
                      id="cardP2--abtn"
                      style={{ backgroundColor: "#297f74" }}
                      onClick={() => {
                        if (isLogin) {
                          if (isManagerMemo) {
                            if (isMonthly) {
                              if (isStudentMemo) {
                                createCheckout(monthlyPrice.devPriceID_std);

                                setStripeData({
                                  ammount: monthlyPrice.student,
                                  id: monthlyPrice.student_id,
                                  devPriceID: monthlyPrice.devPriceID_std,
                                  duration: "Monthly",
                                });
                              } else {
                                createCheckout(monthlyPrice.devPriceID);

                                setStripeData({
                                  ammount: monthlyPrice.unlimited,
                                  id: monthlyPrice.unlimited_id,
                                  devPriceID: monthlyPrice.devPriceID,
                                  duration: "Monthly",
                                });
                              }
                            } else {
                              createCheckout(anualPrice.devPriceID);
                              setStripeData({
                                ammount: anualPrice.unlimited,
                                id: anualPrice.unlimited_id,
                                devPriceID: anualPrice.devPriceID,
                                duration: "Yearly",
                              });
                            }
                          } else {
                            toast.error(language.only_manager_pay);
                          }
                        } else {
                          navigate("/creataccount", {
                            state: {
                              userType: isBuSelect ? "Business" : "Personal",
                              discCode: "",
                              priceId: isMonthly
                                ? monthlyPrice.devPriceID
                                : anualPrice.devPriceID,
                            },
                          });
                        }
                      }}
                    >
                      {language.PerButton}
                    </a>
                    <div className="checkPrice">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        width={34}
                        height={34}
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 12.75 11.25 15 15 9.75"
                          stroke="#297F74"
                          fill="none"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                          stroke="#297F74"
                        />
                      </svg>
                      <p id="cardP2--p4" style={{ color: "#297f74" }}>
                        {language.Per_addition_feature}
                      </p>
                    </div>
                    <p id="cardP2--p5" style={{ color: "#297f74" }}>
                      <span class="subrayar" style={{ color: "#297f74" }}>
                        {language.Per_Bookkeeping_Accounting}
                      </span>
                      {language.Per_Bookkeeping_Accounting_desc}
                    </p>
                    <p id="cardP2--p6" style={{ color: "#297f74" }}>
                      <span class="subrayar" style={{ color: "#297f74" }}>
                        {language.Per_Instant_Integrations}
                      </span>
                      {language.Per_InstantDesc}
                    </p>
                    <p id="cardP2--p7" style={{ color: "#297f74" }}>
                      <span class="subrayar" style={{ color: "#297f74" }}>
                        {language.Per_Tasking}
                      </span>{" "}
                      {language.Per_task_desc}
                    </p>
                  </div>
                </div>
              )}

              {/* for bookkeeper price */}
              {showBookPriceMemo && (
                <>
                  {/* Starter book */}
                  <div className="card businessCard">
                    <div className="card-content-price">
                      <p
                        className="p:first-child"
                        id="card2--p1"
                        style={{ color: "red", height: "10px" }}
                      >
                        {!isMonthly ? language.Strarter_SAVE : " "}
                      </p>

                      <h3 id="card2--h3--1">Starter Firm</h3>
                      <div className="user">
                        <p id="card2--p2">
                          {language.Includes} <span class="subrayar">5</span>{" "}
                          {language.users}
                        </p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          width={34}
                          height={34}
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                            stroke="#0D283D"
                          />
                        </svg>
                      </div>
                      <h3 id="card2--h3--2">
                        {priceData.starterBook + `${currencyMemo}`}
                      </h3>
                      <p id="card2--p3">
                        / {monthYearMemo} ({language.billed} {monthYearMemo})
                      </p>
                      <a
                        className="btn"
                        id="card2--abtn"
                        onClick={() => {
                          if (isLogin) {
                            if (isOwnerMemo) {
                              if (regCodeData.total <= 5) {
                                if (isMonthly) {
                                  createCheckout(monthlyPrice.devPriceID_starterBook);

                                  setStripeData({
                                    ammount: monthlyPrice.starterBook,
                                    id: monthlyPrice.starterBook_id,
                                    duration: "Monthly",
                                    devPriceID: monthlyPrice.devPriceID_starterBook,
                                  });
                                } else {
                                  createCheckout(anualPrice.devPriceID_starterBook);

                                  // setShowStripeModal(true)
                                  setStripeData({
                                    ammount: anualPrice.starterBook,
                                    id: anualPrice.starterBook_id,
                                    duration: "Yearly",
                                    devPriceID: anualPrice.devPriceID_starterBook,
                                  });
                                }
                              } else {
                                toast.error(language.up_to_five_user);
                              }
                            } else {
                              toast.error(language.only_owner_Pay);
                            }
                          } else {
                            navigate("/creataccount", {
                              state: {
                                userType: "Bookkeeper",
                                discCode: "",
                                priceId: isMonthly
                                  ? monthlyPrice.devPriceID_starterBook
                                  : anualPrice.devPriceID_starterBook,
                              }
                            })
                          }
                        }}
                      >
                        {language.Sign_Up_Pay_Now}
                      </a>
                      <div className="checkPrice">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          width={34}
                          height={34}
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 12.75 11.25 15 15 9.75"
                            stroke="#0D283D"
                            fill="none"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                            stroke="#0D283D"
                          />
                        </svg>
                        <p id="card2--p4">{language.Product_Features}</p>
                      </div>
                      <p id="card2--p5">
                        <span class="subrayar">{language.Web_Mobile}</span>{" "}
                        {language.interfaces_for_data}
                      </p>
                      <p id="card2--p6">
                        <span class="subrayar">{language.Central_Console}</span>{" "}
                        {language.for_easy_client}
                      </p>
                      <p id="card2--p7">
                        <span class="subrayar">
                          {language.Dashboards_Reporting}
                        </span>{" "}
                        {language.for_real_time_visibility_insights}
                      </p>
                    </div>
                  </div>

                  {/* Small Business book */}
                  <div className="card businessCard">
                    <div className="card-content-price">
                      {/*Aqui esta el div "popular"*/}
                      <div className="popular" id="card3--div">
                        {language.Most_popular}
                      </div>

                      <p
                        className="parrafo-centrar"
                        id="card3--p1"
                        style={{ color: "red", height: "10px", textAlign: "center", }}
                      >
                        {!isMonthly ? language.Bus_SAVE : " "}
                      </p>
                      <h3 id="card3--h3--1" style={{ color: "#297f74" }}>
                        *Small Firm
                      </h3>
                      <div className="user">
                        <p
                          className="parrafo-especial"
                          id="card3--p2"
                          style={{ color: "#297f74" }}
                        >
                          {language.Includes}{" "}
                          <span class="subrayar" style={{ color: "#297f74" }}>
                            10
                          </span>{" "}
                          {language.users}
                        </p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          width={34}
                          height={34}
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                            stroke="#297F74"
                          />
                        </svg>
                      </div>
                      <h3 id="card3--h3--2" style={{ color: "#297f74" }}>
                        $ {priceData.smallBusinessBook + `${currencyMemo}`}
                      </h3>
                      <p id="card3--p3" style={{ color: "#297f74" }}>
                        / {monthYearMemo} ({language.billed} {monthYearMemo})
                      </p>
                      <a
                        className="btn btn-tercera-carta"
                        id="card3--abtn"
                        style={{ color: "white", backgroundColor: "#297f74" }}
                        onClick={() => {
                          if (isLogin) {
                            if (isOwnerMemo) {
                              if (regCodeData.total <= 10) {
                                if (isMonthly) {
                                  createCheckout(monthlyPrice.devPriceID_smallBusinessBook);

                                  setStripeData({
                                    ammount: monthlyPrice.smallBusinessBook,
                                    id: monthlyPrice.smallBusinessBook_id,
                                    duration: "Monthly",
                                    devPriceID: monthlyPrice.devPriceID_smallBusinessBook,
                                  });
                                } else {
                                  createCheckout(anualPrice.devPriceID_smallBusinessBook);

                                  setStripeData({
                                    ammount: anualPrice.smallBusinessBook,
                                    id: anualPrice.smallBusinessBook_id,
                                    duration: "Yearly",
                                    devPriceID: anualPrice.devPriceID_smallBusinessBook,
                                  });
                                }
                              } else {
                                toast.error(language.up_to_ten_user);
                              }
                            } else {
                              toast.error(language.only_owner_Pay);
                            }
                          } else {
                            navigate("/creataccount", {
                              state: {
                                userType: "Bookkeeper",
                                discCode: "",
                                priceId: isMonthly
                                  ? monthlyPrice.devPriceID_smallBusinessBook
                                  : anualPrice.devPriceID_smallBusinessBook,
                              }
                            })
                          }
                        }}
                      >
                        {language.Sign_Up_Pay_Now}
                      </a>
                      <div className="checkPrice">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          width={34}
                          height={34}
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 12.75 11.25 15 15 9.75"
                            stroke="#297F74"
                            fill="none"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                            stroke="#297F74"
                          />
                        </svg>
                        <p id="card3--p4" style={{ color: "#297f74" }}>
                          {language.Additional_Features}
                        </p>
                      </div>
                      <p id="card3--p5" style={{ color: "#297f74" }}>
                        <span class="subrayar" style={{ color: "#297f74" }}>
                          {language.Bookkeeping_Accounting}
                        </span>{" "}
                        {language.Bookkeeping_value}
                      </p>
                      <p id="card3--p6" style={{ color: "#297f74" }}>
                        <span style={{ color: "#297f74" }} class="subrayar">
                          {language.Instant_Integrations}
                        </span>{" "}
                        {language.Instant_Integrations_value}
                      </p>
                      <p id="card3--p7" style={{ color: "#297f74" }}>
                        <span style={{ color: "#297f74" }} class="subrayar">
                          {language.Invoicing}
                        </span>{" "}
                        {language.Invoicing_value}
                      </p>
                    </div>
                  </div>

                  {/* Mid-Size book */}
                  <div className="card businessCard">
                    <div className="card-content-price">
                      <p
                        className="p:first-child"
                        id="card4--p1"
                        style={{ color: "red", height: "10px" }}
                      >
                        {!isMonthly ? language.SAVE : " "}
                      </p>

                      <h3 id="card4--h3--1" style={{ color: "black" }}>
                        Mid-Size Firm
                      </h3>
                      <div className="user">
                        <p id="card4--p2">
                          {language.Includes} <span class="subrayar">20</span>{" "}
                          {language.users}
                        </p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          width={34}
                          height={34}
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                            stroke="#0D283D"
                          />
                        </svg>
                      </div>
                      <h3 id="card4--h3--2" style={{ color: "black" }}>
                        {priceData.midSizeBook + `${currencyMemo}`}
                      </h3>
                      <p id="cardP2--p3">
                        / {monthYearMemo} ({language.billed} {monthYearMemo})
                      </p>
                      <a
                        className="btn"
                        id="card4--abtn"
                        onClick={() => {
                          if (isLogin) {
                            if (isOwnerMemo) {
                              if (regCodeData.total <= 20) {
                                if (isMonthly) {
                                  createCheckout(monthlyPrice.devPriceID_midSizeBook);

                                  setStripeData({
                                    ammount: monthlyPrice.midSizeBook,
                                    id: monthlyPrice.midSizeBook_id,
                                    duration: "Monthly",
                                    devPriceID: monthlyPrice.devPriceID_midSizeBook,
                                  });
                                } else {
                                  createCheckout(anualPrice.devPriceID_midSizeBook);

                                  setStripeData({
                                    ammount: anualPrice.midSizeBook,
                                    id: anualPrice.midSizeBook_id,
                                    duration: "Yearly",
                                    devPriceID: anualPrice.devPriceID_midSizeBook,
                                  });
                                }
                              } else {
                                toast.error(language.up_to_twenty_user);
                              }
                            } else {
                              toast.error(language.only_owner_Pay);
                            }
                          } else {
                            navigate("/creataccount", {
                              state: {
                                userType: "Bookkeeper",
                                discCode: "",
                                priceId: isMonthly
                                  ? monthlyPrice.devPriceID_midSizeBook
                                  : anualPrice.devPriceID_midSizeBook,
                              }
                            })
                          }
                        }}
                      >
                        {language.Sign_Up_Pay_Now}
                      </a>
                      <div className="checkPrice">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          width={34}
                          height={34}
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 12.75 11.25 15 15 9.75"
                            stroke="#0D283D"
                            fill="none"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                            stroke="#0D283D"
                          />
                        </svg>
                        <p id="card4--p4">{language.Obstacles_Solved}</p>
                      </div>
                      <p id="card4--p5">
                        <span class="subrayar">{language.Money_Leaks}</span>{" "}
                        {language.are_identified_ele_client}
                      </p>
                      <p id="card4--p6">
                        <span class="subrayar">{language.Disconnects}</span>{" "}
                        {language.Disconnects_value}
                      </p>
                      <p id="card4--p7">
                        <span class="subrayar">
                          {language.Headaches_Stress}
                        </span>{" "}
                        {language.Headaches_Stress_value}
                      </p>
                    </div>
                  </div>
                </>
              )}

              {/* for Investor price */}
              {showInvestPriceMemo && (
                <>

                  {/* Starter inv */}
                  <div className="card businessCard">
                    <div className="card-content-price">
                      <p
                        className="p:first-child"
                        id="card2--p1"
                        style={{ color: "red", height: "10px" }}
                      >
                        {!isMonthly ? language.Strarter_SAVE : " "}
                      </p>

                      <h3 id="card2--h3--1">Starter Fund</h3>
                      <div className="user">
                        <p id="card2--p2">
                          {language.Includes} <span class="subrayar">5</span>{" "}
                          {language.users}
                        </p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          width={34}
                          height={34}
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                            stroke="#0D283D"
                          />
                        </svg>
                      </div>
                      <h3 id="card2--h3--2">
                        {priceData.starterInv + `${currencyMemo}`}
                      </h3>
                      <p id="card2--p3">
                        / {monthYearMemo} ({language.billed} {monthYearMemo})
                      </p>
                      <a
                        className="btn"
                        id="card2--abtn"
                        onClick={() => {
                          if (isLogin) {
                            if (isOwnerMemo) {
                              if (regCodeData.total <= 5) {
                                if (isMonthly) {
                                  createCheckout(monthlyPrice.devPriceID_starterInv);

                                  setStripeData({
                                    ammount: monthlyPrice.starterInv,
                                    id: monthlyPrice.starterInv_id,
                                    duration: "Monthly",
                                    devPriceID: monthlyPrice.devPriceID_starterInv,
                                  });
                                } else {
                                  createCheckout(anualPrice.devPriceID_starterInv);

                                  // setShowStripeModal(true)
                                  setStripeData({
                                    ammount: anualPrice.starterInv,
                                    id: anualPrice.starterInv_id,
                                    duration: "Yearly",
                                    devPriceID: anualPrice.devPriceID_starterInv,
                                  });
                                }
                              } else {
                                toast.error(language.up_to_five_user);
                              }
                            } else {
                              toast.error(language.only_owner_Pay);
                            }
                          } else {
                            navigate("/creataccount", {
                              state: {
                                userType: "Investor",
                                discCode: "",
                                priceId: isMonthly
                                  ? monthlyPrice.devPriceID_starterInv
                                  : anualPrice.devPriceID_starterInv,
                              }
                            })
                          }
                        }}
                      >
                        {language.Sign_Up_Pay_Now}
                      </a>
                      <div className="checkPrice">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          width={34}
                          height={34}
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 12.75 11.25 15 15 9.75"
                            stroke="#0D283D"
                            fill="none"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                            stroke="#0D283D"
                          />
                        </svg>
                        <p id="card2--p4">{language.Product_Features}</p>
                      </div>
                      <p id="card2--p5">
                        <span class="subrayar">{language.Web_Mobile}</span>{" "}
                        {language.interfaces_for_data}
                      </p>
                      <p id="card2--p6">
                        <span class="subrayar">{language.Central_Console}</span>{" "}
                        {language.for_easy_client}
                      </p>
                      <p id="card2--p7">
                        <span class="subrayar">
                          {language.Dashboards_Reporting}
                        </span>{" "}
                        {language.for_real_time_visibility_insights}
                      </p>
                    </div>
                  </div>

                  {/* Small Business inv */}
                  <div className="card businessCard">
                    <div className="card-content-price">
                      {/*Aqui esta el div "popular"*/}
                      <div className="popular" id="card3--div">
                        {language.Most_popular}
                      </div>

                      <p
                        className="parrafo-centrar"
                        id="card3--p1"
                        style={{ color: "red", height: "10px", textAlign: "center", }}
                      >
                        {!isMonthly ? language.Bus_SAVE : " "}
                      </p>
                      <h3 id="card3--h3--1" style={{ color: "#297f74" }}>
                        *Small Fund
                      </h3>
                      <div className="user">
                        <p
                          className="parrafo-especial"
                          id="card3--p2"
                          style={{ color: "#297f74" }}
                        >
                          {language.Includes}{" "}
                          <span class="subrayar" style={{ color: "#297f74" }}>
                            10
                          </span>{" "}
                          {language.users}
                        </p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          width={34}
                          height={34}
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                            stroke="#297F74"
                          />
                        </svg>
                      </div>
                      <h3 id="card3--h3--2" style={{ color: "#297f74" }}>
                        $ {priceData.smallBusinessInv + `${currencyMemo}`}
                      </h3>
                      <p id="card3--p3" style={{ color: "#297f74" }}>
                        / {monthYearMemo} ({language.billed} {monthYearMemo})
                      </p>
                      <a
                        className="btn btn-tercera-carta"
                        id="card3--abtn"
                        style={{ color: "white", backgroundColor: "#297f74" }}
                        onClick={() => {
                          if (isLogin) {
                            if (isOwnerMemo) {
                              if (regCodeData.total <= 10) {
                                if (isMonthly) {
                                  createCheckout(monthlyPrice.devPriceID_smallBusinessInv);

                                  setStripeData({
                                    ammount: monthlyPrice.smallBusinessInv,
                                    id: monthlyPrice.smallBusinessInv_id,
                                    duration: "Monthly",
                                    devPriceID: monthlyPrice.devPriceID_smallBusinessInv,
                                  });
                                } else {
                                  createCheckout(anualPrice.devPriceID_smallBusinessInv);

                                  setStripeData({
                                    ammount: anualPrice.smallBusinessInv,
                                    id: anualPrice.smallBusinessInv_id,
                                    duration: "Yearly",
                                    devPriceID: anualPrice.devPriceID_smallBusinessInv,
                                  });
                                }
                              } else {
                                toast.error(language.up_to_ten_user);
                              }
                            } else {
                              toast.error(language.only_owner_Pay);
                            }
                          } else {
                            navigate("/creataccount", {
                              state: {
                                userType: "Investor",
                                discCode: "",
                                priceId: isMonthly
                                  ? monthlyPrice.devPriceID_smallBusinessInv
                                  : anualPrice.devPriceID_smallBusinessInv,
                              }
                            })
                          }
                        }}
                      >
                        {language.Sign_Up_Pay_Now}
                      </a>
                      <div className="checkPrice">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          width={34}
                          height={34}
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 12.75 11.25 15 15 9.75"
                            stroke="#297F74"
                            fill="none"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                            stroke="#297F74"
                          />
                        </svg>
                        <p id="card3--p4" style={{ color: "#297f74" }}>
                          {language.Additional_Features}
                        </p>
                      </div>
                      <p id="card3--p5" style={{ color: "#297f74" }}>
                        <span class="subrayar" style={{ color: "#297f74" }}>
                          {language.Bookkeeping_Accounting}
                        </span>{" "}
                        {language.Bookkeeping_value}
                      </p>
                      <p id="card3--p6" style={{ color: "#297f74" }}>
                        <span style={{ color: "#297f74" }} class="subrayar">
                          {language.Instant_Integrations}
                        </span>{" "}
                        {language.Instant_Integrations_value}
                      </p>
                      <p id="card3--p7" style={{ color: "#297f74" }}>
                        <span style={{ color: "#297f74" }} class="subrayar">
                          {language.Invoicing}
                        </span>{" "}
                        {language.Invoicing_value}
                      </p>
                    </div>
                  </div>

                  {/* Mid-Size inv */}
                  <div className="card businessCard">
                    <div className="card-content-price">
                      <p
                        className="p:first-child"
                        id="card4--p1"
                        style={{ color: "red", height: "10px" }}
                      >
                        {!isMonthly ? language.SAVE : " "}
                      </p>

                      <h3 id="card4--h3--1" style={{ color: "black" }}>
                        Mid-Size Fund
                      </h3>
                      <div className="user">
                        <p id="card4--p2">
                          {language.Includes} <span class="subrayar">20</span>{" "}
                          {language.users}
                        </p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          width={34}
                          height={34}
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                            stroke="#0D283D"
                          />
                        </svg>
                      </div>
                      <h3 id="card4--h3--2" style={{ color: "black" }}>
                        {priceData.midSizeInv + `${currencyMemo}`}
                      </h3>
                      <p id="cardP2--p3">
                        / {monthYearMemo} ({language.billed} {monthYearMemo})
                      </p>
                      <a
                        className="btn"
                        id="card4--abtn"
                        onClick={() => {
                          if (isLogin) {
                            if (isOwnerMemo) {
                              if (regCodeData.total <= 20) {
                                if (isMonthly) {
                                  createCheckout(monthlyPrice.devPriceID_midSizeInv);

                                  setStripeData({
                                    ammount: monthlyPrice.midSizeInv,
                                    id: monthlyPrice.midSizeBook_id,
                                    duration: "Monthly",
                                    devPriceID: monthlyPrice.devPriceID_midSizeInv,
                                  });
                                } else {
                                  createCheckout(anualPrice.devPriceID_midSizeInv);

                                  setStripeData({
                                    ammount: anualPrice.midSizeInv,
                                    id: anualPrice.midSizeInv_id,
                                    duration: "Yearly",
                                    devPriceID: anualPrice.devPriceID_midSizeInv,
                                  });
                                }
                              } else {
                                toast.error(language.up_to_twenty_user);
                              }
                            } else {
                              toast.error(language.only_owner_Pay);
                            }
                          } else {
                            navigate("/creataccount", {
                              state: {
                                userType: "Investor",
                                discCode: "",
                                priceId: isMonthly
                                  ? monthlyPrice.devPriceID_midSizeInv
                                  : anualPrice.devPriceID_midSizeInv,
                              }
                            })
                          }
                        }}
                      >
                        {language.Sign_Up_Pay_Now}
                      </a>
                      <div className="checkPrice">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          width={34}
                          height={34}
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 12.75 11.25 15 15 9.75"
                            stroke="#0D283D"
                            fill="none"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                            stroke="#0D283D"
                          />
                        </svg>
                        <p id="card4--p4">{language.Obstacles_Solved}</p>
                      </div>
                      <p id="card4--p5">
                        <span class="subrayar">{language.Money_Leaks}</span>{" "}
                        {language.are_identified_ele_client}
                      </p>
                      <p id="card4--p6">
                        <span class="subrayar">{language.Disconnects}</span>{" "}
                        {language.Disconnects_value}
                      </p>
                      <p id="card4--p7">
                        <span class="subrayar">
                          {language.Headaches_Stress}
                        </span>{" "}
                        {language.Headaches_Stress_value}
                      </p>
                    </div>
                  </div>

                </>
              )}

            </div>
          </div>

        </main>
      </section>
    </>
  );
};

export default Pricenew;
