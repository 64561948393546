import React from 'react'
import Blogbanner from './Blogbanner'
import Blogslider from './Blogslider'
import Blogimage from './Blogimage'
import Gototop from '../../Component/Gototop'
import BlogBox from './BlogBox'
function index() {
  return (
    <>
      <Blogbanner />
      <BlogBox/>
      {/* <Blogslider /> */}
      <Blogimage />
      {/* <Gototop /> */}
    </>
  )
}

export default index