import React, { useState } from 'react';
import { DateRange } from 'react-date-range';
import moment from 'moment';
import CustomLoaderLine from '../../Component/Loader/CustomLoaderLine';
import TotalDOcumentModal from './TotalDOcumentModal';
import { useLanguage } from '../../Providers/LanguageContext';

const NewDocsVault = () => {
    const { language, currentLanguage } = useLanguage()
    const [openTotalModal, setOpenTotalModal] = useState(false);
    const [singleTotalDoc, setSingleTotalDoc] = useState([]);
    const [isShowCalender, setIsShowCalender] = useState(false);
    const [date, setDate] = useState({ startDate: "", endDate: "" });

    const initState = {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
    };
    const [state, setState] = useState(initState);

    const toggleShowCalender = () => {
        setIsShowCalender(!isShowCalender);
    };

    const cards = [
        { typeName: "Customer Invoice", total: "1,145", pastDue: "189" },
        { typeName: "Customer Agreements", total: "5", signed: "0" },
        { typeName: "Customer Quotes", total: "22", open: "0" },
        { typeName: "Accounts Receivable", total: "109" },
        { typeName: "Supplier Invoices", total: "1,841", pastDue: "384" },
        { typeName: "Supplier Agreements", total: "84", signed: "0" },
        { typeName: "Supplier Purchase Orders", total: "58", open: "0" },
        { typeName: "Accounts Payable", total: "7" },
        { typeName: "Bank Statements", total: "21", missing: "3" },
        { typeName: "Financial Statements", total: "91" },
        { typeName: "Corporate Docs", total: "91" },
        { typeName: "Other", total: "1,099" },
    ];

    return (
        <>
            <section className='documentManagerslider_sec' onClick={() => setIsShowCalender(false)}>
                <div className='container'>
                    <div className='allDocument_Vault'>
                        {/* Static Document Item Cards */}
                        {cards.map((card, index) => (
                            <div key={index} className='documentVault_box'>
                                <div className='toparea'>
                                    <div className='box'>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Name"
                                        />
                                    </div>

                                    <div
                                        className='box'
                                        style={{ cursor: "pointer", position: "relative" }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            toggleShowCalender();
                                        }}
                                    >
                                        <span className="form-control">
                                            {(date?.startDate && date?.endDate)
                                                ? <span style={{ whiteSpace: "nowrap" }}>{date?.startDate + " - " + date?.endDate}</span>
                                                : "Date Range"}
                                        </span>
                                    </div>
                                </div>

                                <div className='bottomarea'>
                                    <p className='name'>{card.typeName}</p>
                                    <div className='d-flex align-items-center justify-content-around'>
                                        <div className='pointarea'>
                                            <p>Total</p>
                                            <span
                                                onClick={() => {
                                                    setOpenTotalModal(true);
                                                    setSingleTotalDoc([]); // Replace with static data if needed
                                                }}
                                                style={{ cursor: "pointer" }}
                                            >
                                                {false ? <CustomLoaderLine height="25" width="25" /> : card.total}
                                            </span>
                                        </div>

                                        {card.pastDue && <div className='pointarea'>
                                            <p>Past Due</p>
                                            <span>{card.pastDue}</span>
                                        </div>}

                                        {card.signed && <div className='pointarea'>
                                            <p>Signed</p>
                                            <span>{card.signed}</span>
                                        </div>}

                                        {card.open && <div className='pointarea'>
                                            <p>Open</p>
                                            <span>{card.open}</span>
                                        </div>}

                                        {card.missing && <div className='pointarea'>
                                            <p>Missing</p>
                                            <span>{card.missing}</span>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {/* range calendar */}
            {isShowCalender &&
                <div className='DocVaultCalender' onClick={(e) => e.stopPropagation()}>
                    <div className='DocVaultCalender_body'>
                        <button className='closebtn' onClick={toggleShowCalender}><i className="fa-solid fa-xmark"></i></button>
                        <DateRange
                            editableDateInputs={true}
                            onChange={item => {
                                setState(item.selection)
                                setDate({
                                    startDate: moment(item.selection?.startDate).format("YYYY-MM-DD"),
                                    endDate: moment(item?.selection?.endDate).format("YYYY-MM-DD")
                                });
                            }}
                            moveRangeOnFirstSelection={false}
                            ranges={[state]}
                        />

                        <div className='d-flex justify-content-between p-2'>
                            <button
                                className='btn btn-sm btn-primary'
                                onClick={() => {
                                    setState(initState);
                                    setDate({
                                        startDate: moment().format("YYYY-MM-DD"),
                                        endDate: moment().format("YYYY-MM-DD"),
                                    });
                                }}
                            >
                                Today
                            </button>

                            <button
                                className='btn btn-sm btn-primary'
                                onClick={() => {
                                    setDate({ startDate: "", endDate: "" });
                                    setState(initState);
                                    setIsShowCalender(false);
                                }}
                            >
                                Clear
                            </button>
                        </div>
                    </div>
                </div>
            }

            {/* total document modal */}
            <TotalDOcumentModal
                modalIsOpen={openTotalModal}
                closeModal={() => setOpenTotalModal(false)}
                singleTotalDoc={singleTotalDoc}
            />
        </>
    );
};

export default NewDocsVault;
