import logo from "./logo.svg";
import "./App.css";
import "./Custom.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Mainlayout from "./Layout/Mainlayout";
import Home from "./View/Home";
import Login from "./Authentication/Login";
import Passwordconformation from "./Authentication/Passwordconformation";
import Verification from "./Authentication/Verification";
import Blog from "../src/View/Blog/index";
import Nosotros from "./View/Nosotros/index";
import Restorepassword from "./Authentication/Restorepassword";
import Creataccount from "./Authentication/Creataccount";
import Createprofile from "./Authentication/Createprofile";
import Createprofile2 from "./Authentication/Createprofile2";
import Tabcomponent from "./Component/Tabcomponent";
import Cipbitheader from "./Layout/Cipbitheader";
import Cipbits from "./Component/Cipbits";
import SingleLayout from "./Layout/SingleLayout";
import spanish from "./language/spanish.json"
import english from "./language/eng.json"
import { useLanguage } from "./Providers/LanguageContext";
import Welcome from "./View/welcome";
import NewSalesRevenue from "./View/Home/income-manager/NewSalesRevenue";
import AddExpense from "./View/Home/expense-manager";
import toast, { Toaster } from 'react-hot-toast';
import PrivateRouter from "./privateRouter/PrivateRouter";
import Test from "./privateRouter/Test";
import Welcome2 from "./View/welcome2/Welcome2";
import NewIncome from "./View/income/NewIncome";
import CustomFormPage from "./View/income/NewIncome";
import CustomPageForm from "./View/income/NewIncome";


import Whitepapers from "./View/Whitepapers/Index";
import PaperLayout from "./Layout/PaperLayout";
import Management from "./View/Management/Index";
import Bill from "./View/Bill/Index"
import Track from "./View/Track/Index";
import Billcnt from "./Component/Billcnt";
import Managebtn from "./Component/Managebtn";
import News from "./View/News/Index"
import Managementtop from "./Component/Managementtop";
import Clasification from "./Component/Clasification";
import Dropbox from "./Component/Dropbox";
import Users from "./View/Users/Index";
import Userlist from "./Component/Userlist";
import Userform from "./Authentication/Userform";
import Helpform from "./Authentication/Helpform";
import Invite from "./View/Invite/Index"
import Configuration from "./Authentication/Configuration";
import Retos from "./View/Retos/Index";
import Eliminate from "./Component/Modal/Eliminate";
import Track2 from "./Authentication/Track2";
import TransactionDetails from "./View/income/TransactionDetails";
import TopbarComp from "./Component/TopbarComp";
import TransactionList from "./View/income/TransactionList";
import NewExpenseDetails from "./View/Home/expense-manager/NewExpenseDetails";
import BudgetTracker from "./View/Home/budget/BudgetTracker";
import DashboardManager from "./View/Home/dashboard/DashboardManager";
import EditExpenseDetails from "./View/Home/expense-manager/EditExpenseDetails";
import CreateInvoice from "./View/invoice/CreateInvoice";
import ViewInvoice from "./View/invoice/ViewInvoice";
import ViewInvoiceSummary from "./View/invoice/ViewInvoiceSummary";
import InvoiceSummaryDetails from "./View/invoice/InvoiceSummaryDetails";
import InvoicePrint from "./View/invoice/InvoicePrint";
import PublicRouter from "./privateRouter/PublicRoute";
import BudgetTracker2 from "./View/Home/budget-tracker/BudgetTracker";
import AudioManager from "./privateRouter/AudioManager";
import PrivatePayment from "./privateRouter/PrivatePayment";
import Payment from "./Component/payment/Payment";
import PaymentProtect from "./privateRouter/PaymentProtect";
import IsSubscribed from "./privateRouter/IsSubscribed";
import PaymentPublic from "./Component/payment/PaymentPublic";

import Agriculture from "./View/IndustrySolution/Agriculture";



import GeneralUse from "./View/IndustrySolution/GeneralUse";
import AccountingComp from "./View/IndustrySolution/AccountingComp";
import CapitalProviders from "./View/IndustrySolution/CapitalProviders";
import Construction from "./View/IndustrySolution/Construction";
import Education from "./View/IndustrySolution/Education";
import ElectronicsCommerce from "./View/IndustrySolution/ElectronicsCommerce";
import Energy from "./View/IndustrySolution/Energy";
import BankIndustry from "./View/IndustrySolution/BankIndustry";
import Government from "./View/IndustrySolution/Government";
import HealthCare from "./View/IndustrySolution/HealthCare";
import Hospitality from "./View/IndustrySolution/Hospitality";
import Legal from "./View/IndustrySolution/Legal";
import Manufacturing from "./View/IndustrySolution/Manufacturing";
import NoProfitOrg from "./View/IndustrySolution/NoProfitOrg";
import OilAndGas from "./View/IndustrySolution/OilAndGas";
import PersonalUse from "./View/IndustrySolution/PersonalUse";
import RealState from "./View/IndustrySolution/RealState";
import Retailer from "./View/IndustrySolution/Retailer";
import Technology from "./View/IndustrySolution/Technology";
import Transportation from "./View/IndustrySolution/Transportation";
import ViewReports from "./View/Reports/ViewReports";
import SendReports from "./View/Reports/SendReports";
import Price from "./View/Price/Price";
import ResourceManager from "./View/ResourceManager/ResourceManager";
import TermCondition from "./Authentication/TermCondition";
import Information from "./View/Invite/Information";
import UserTutorial from "./View/UserTutorial/UserTutorial";
import Gototop from "./Component/Gototop";
import HttpClient from "./utils/HttpClient";
import { useContext, useEffect, useMemo } from "react";
import { SpeechContext } from "./Providers/SpeechWrapper";
import Apis from "../src/View/APIScapitalProvider/Apis";
import IntelligencePlatform from "../src/View/IntellegenceBusiness/IntelligencePlatform";
import DigitalAudits from "../src/View/APIScapitalProvider/DigitalAudits";
import BusinessIntelligence from "../src/View/IntellegenceBusiness/BusinessIntelligence";
import Cip from "../src/View/APIScapitalProvider/Cip";
import ConsolidatedAnalyticsBusiness from "../src/View/ConsolidatedAnalyticsBusiness/ConsolidatedAnalyticsBusiness";
import OnlineSalesBusiness from "../src/View/ConsolidatedAnalyticsBusiness/OnlineSalesBusiness";
import ExistingSystem from "../src/View/ConsolidatedAnalyticsBusiness/ExistingSystem";
import BusinessPrice from "./View/Price/BusinessPrice";
import PersonalPrice from "./View/Price/PersonalPrice";
import PrivacyPolicy from "./Authentication/PrivacyPolicy";
import ConfirmMessage from "./View/InvestmentRegistry/ConfirmMessage";
import { useUserData } from "./Providers/UserWrapper";
import BusinessPriceRoute from "./privateRouter/BusinessPriceRoute";
import PersonalPriceRoute from "./privateRouter/PersonalPriceRoute";
import LoaderRotate from "./Component/LoaderRotate";
import ConfirmPassword from "./Authentication/ConfirmPassword";
import SharedPage from "./View/Invite/SharedPage";
import GoogleRegister from "./Authentication/GoogleRegister";
import Integration from "./View/IntegrationManager/Integration";
import InvoiceForm2 from "./View/invoice/invoiceForm2/InvoiceForm2";
import Stripegetway from "./Component/Stripe";
import PaymentSuccess from "./Component/Stripe/PaymentCompleate";
import ViewNormalInvoice from "./View/invoice/invoiceForm2/NormalInvoice/ViewNormalInvoice";
import Bookkeper from "./View/BookKeperManager/Bookkeper";
import BookKeperDocs from "./View/BookKeperManager/BookKeperDocs";
import BookkeperLink from "./View/BookKeperManager/BookkeperLink";
import BuildReport from "./View/Reports/BuildReports/BuildReport";
import ViewSatNormal from "./View/invoice/invoiceForm2/SatNormalInvoice/ViewSatNormal";
import ExpenseDateWise from "./View/Home/expense-manager/ExpenseDateWise";
import CustomerVendorDateWise from "./View/Home/expense-manager/CustomerVendorDateWise";
import ConnectCalender from "./View/TaskManager/ConnectCalender"
import EventsCalender from "./View/TaskManager/EventsCalender"
import Pricenew from "./View/Price/PriceNew/Pricenew";
import AcceptCOnnectReq from "./View/BookKeperManager/AcceptCOnnectReq";
import BookkeeperLogin from "./View/BookKeperManager/BookkeeperLogin";
import LinkManageAccount from "./View/BookKeperManager/NewBookkeeper/LinkManageAccount";
import BookkeeperWelcome from "./View/BookKeperManager/BookkeeperWelcome";
import CreateAccBookKeeper from "./Authentication/CreateAccBookKeeper";
import BookKeeperPrice from "./View/BookKeperManager/price/BookKeeperPrice";
import BookkeeperPriceRoute from "./privateRouter/BookkeeperPriceRoute";
import AcceptuPdateTransaction from "./View/BookKeperManager/AcceptuPdateTransaction";
import GoogleCalendarConnect from "./View/TaskManager/GoogleCalendarConnect";
import PaymentSuccessBook from "./Component/Stripe/PaymentSuccessBook";
import SubUserBook from "./View/BookKeperManager/SubUserBookkeeper/SubUserBook";
import LinkManageInvestment from "./View/InvestmentManager/LinkManageInvestment";
import AcceptInvestor from "./View/InvestmentManager/AcceptInvestor";
import InvestmentOptions from "./View/InvestmentManager/InvestmentOptions";
import RaiseCapital from "./View/InvestmentManager/UserInvestor/RaiseCapital";
import UserViewDocsInv from "./View/InvestmentManager/UserInvestor/UserViewDocsInv";
import ViewCapitalOption from "./View/InvestmentManager/UserInvestor/ViewCapitalOption";
import DocumentManager from "./View/DocumentManager/Index";
import ImportDocument from "./View/DocumentManager/ImportDocument";
import DocumentVault from "./View/DocumentManager/DocumentVault";
import CreateSendDocs from "./View/DocumentManager/CreateSendDocs";
import DeleteAccountPage from "./View/Deleteaccount/DeleteAccountPage";
import BuildBudgetForecast from "./View/Reports/BuildReports/BuildBudgetForecast";
import BuildAccountsPayableAging from "./View/Reports/BuildReports/BuildAccountsPayableAging";
import BuildPersonalFinancialStatement from "./View/Reports/BuildReports/BuildPersonalFinancialStatement";
import BuildBusinessFinancialStatement from "./View/Reports/BuildReports/BuildBusinessFinancialStatement";
import Build13WeekCashFlowStatement from "./View/Reports/BuildReports/Build13WeekCashFlowStatement";
import Build12MonthCashFlowStatement from "./View/Reports/BuildReports/Build12MonthCashFlowStatement";
import Producttour from "./View/ProductTour/Producttour";
// import ProductTourBusiness from "./View/ProductTour/ProductTourBusiness";

function App() {

  const { currentLanguage } = useLanguage()

  const { setShowGif, setGifToShow, gifPoints, setGifPoints,
    rewardsOriginal, setShowLevel, setShowSubLevel, setLevelName, setSubLevelName } = useContext(SpeechContext)

  const lastLogin = async () => {
    const res2 = await HttpClient.requestData('last-login', 'PUT', {});
  }

  const loginRewards = async () => {
    const res = await HttpClient.requestData('login-reward', 'GET')
    if (res && res.status) {
      setGifPoints(res.rewardsPoint);
      setTimeout(() => {
        setGifPoints(res.rewardsPoint);
      }, 3000)
    }
    else {
      console.log('errorr reward login')
    }
  }

  useEffect(async () => {
    lastLogin();
    loginRewards();
  }, [])


  return (
    <Router>
      <LoaderRotate />
      <Toaster />
      <Gototop />
      <Routes>

        <Route element={<AudioManager />} >
          <Route element={<Mainlayout />}>

            <Route element={<PrivateRouter />}>
              <Route path="/createprofile" element={<Createprofile />} />
            </Route>

            <Route element={<PublicRouter />}>
              <Route path="/creataccount" element={<Creataccount />} />
              {/* <Route path="/creataccount-bookkeeper" element={<CreateAccBookKeeper />} /> */}
              <Route path="/google-register" element={<GoogleRegister />} />
              <Route path="/login" element={<Login />} />
              
              {/* <Route path="/bookkeeper-login" element={<BookkeeperLogin />} /> */}
            </Route>

            <Route path="/accept-investor/:id" element={<AcceptInvestor />} />
            <Route path="/accept-bookkeeper/:id" element={<AcceptCOnnectReq />} />
            {/* <Route path="/register" element={<Register />} /> */}
            <Route path="/passwordconformation" element={<Passwordconformation />} />
            <Route path="/stripe-checkout" element={<Stripegetway />} />
            <Route path="/payment-success/:ref" element={<PaymentSuccess />} />
            <Route path="/payment-success_bookkeeper/:ref" element={<PaymentSuccessBook />} />
            <Route path="/verification" element={<Verification />} />
            <Route path="/" element={<Home />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/product-tour" element={<Producttour/>}/>
            {/* <Route path="/product_tourbusiness" element={<ProductTourBusiness/>}/> */}
            {/* <Route path="/price" element={<Price />} /> */}
            <Route path="/nosotros" element={<Nosotros />} />
            <Route path="/restorepassword" element={<Restorepassword />} />
            {/* <Route path="/createprofile2" element={<Createprofile2 />} /> */}
            <Route path="/tabcomponent" element={<Tabcomponent />} />

            {/* Industry solution */}
            <Route path="/accountComp" element={<AccountingComp />} />
            {/* <Route path="/agriculture" element={<Agriculture />} /> */}
            <Route path="/agriculture" element={<Agriculture />} />
            <Route path="/banckIndustry" element={<BankIndustry />} />
            <Route path="/capitalProviders" element={<CapitalProviders />} />
            <Route path="/construction" element={<Construction />} />
            <Route path="/education" element={<Education />} />
            <Route path="/electronicsCommerce" element={<ElectronicsCommerce />} />
            <Route path="/energy" element={<Energy />} />
            <Route path="/generalUse" element={<GeneralUse />} />
            <Route path="/government" element={<Government />} />
            <Route path="/healthCare" element={<HealthCare />} />
            <Route path="/hospitality" element={<Hospitality />} />
            <Route path="/legal" element={<Legal />} />
            <Route path="/manufacturing" element={<Manufacturing />} />
            <Route path="/noProfitOrg" element={<NoProfitOrg />} />
            <Route path="/oilAndGas" element={<OilAndGas />} />
            <Route path="/personalUse" element={<PersonalUse />} />
            <Route path="/realState" element={<RealState />} />
            <Route path="/retailer" element={<Retailer />} />
            <Route path="/technology" element={<Technology />} />
            <Route path="/transportation" element={<Transportation />} />

            {/* <Route path="/payment" element={<Payment />} /> */}
            {/* <Route path="/price" element={<Price />} /> */}
            <Route path="/price" element={<Pricenew />} />

            <Route element={<BusinessPriceRoute />}>
              <Route path="/busines-price" element={<Pricenew />} />
            </Route>

            <Route element={<PersonalPriceRoute />}>
              <Route path="/personal-price" element={<Pricenew />} />
            </Route>
            <Route path="/bookkeeper-price" element={<BookKeeperPrice />} />
            <Route path="/confirm_investment/:id" element={<ConfirmMessage />} />
            <Route path="/refferenceCode/:reff" element={<SharedPage />} />

            <Route path="/deleteaccount" element={<DeleteAccountPage />} />

          </Route>


          <Route element={<SingleLayout />}>
            <Route element={<PrivateRouter />}>
              <Route path="/newsalesrevenue" element={<NewSalesRevenue />} />
              <Route path="/newexpense" element={<Bill />} />
              <Route path="/manager" element={<IsSubscribed><Welcome2 /></IsSubscribed>} />
              <Route element={<BookkeeperPriceRoute />}>
                <Route path="/bookkeeper-manager" element={<BookkeeperWelcome />} />
              </Route>
              <Route path="/cipbits" element={<Cipbits />} />
              <Route path="/postnewincome" element={<CustomPageForm />} />
              <Route path="/editexistingrevenue" element={<TransactionDetails />} />
              <Route path="/transaction-details" element={<TransactionList />} />
              <Route path="/viewexpensedetails" element={<NewExpenseDetails />} />
              <Route path="/budget-tracker" element={<Track />} />
              <Route path="/project-budget-setup" element={<BudgetTracker2 />} />
              <Route path="/dashboard-manager" element={<DashboardManager />} />
              <Route path="/project-performance" element={<DashboardManager />} />
              <Route path="/news" element={<News />} />
              <Route path="/edit-expense-details" element={<EditExpenseDetails />} />

              {/* <Route path="/expense-datewise" element={<ExpenseDateWise />} /> */}
              {/* <Route path="/customer-vendor-datewise" element={<CustomerVendorDateWise />} /> */}

              <Route path="/create-invoice" element={<CreateInvoice />} />
              <Route path="/view-invoice" element={<ViewInvoice />} />
              <Route path="/invoice-summary" element={<ViewInvoiceSummary />} />
              <Route path="/invoice-details-summary" element={<InvoiceSummaryDetails />} />
              <Route path="/achievements" element={<Retos />} />
              <Route path="/configuration" element={<Configuration />} />
              <Route path="/contact" element={<Helpform />} />
              <Route path="/users" element={<Users />} />
              <Route path="/userform" element={<Userform />} />
              <Route path="/view_reports" element={<ViewReports />} />
              <Route path="/send_reports" element={<SendReports />} />
              <Route path="/resource" element={<ResourceManager />} />
              <Route path="/integration" element={<Integration />} />
              <Route path="/bookkeper" element={<Bookkeper />} />
              <Route path="/bookkeper-subuser" element={<SubUserBook />} />
              <Route path="/bookKeperDocs" element={<BookKeperDocs />} />
              <Route path="/bookkeperLink" element={<BookkeperLink />} />
              <Route path="/buildReport" element={<BuildReport />} />
              <Route path="/connectCalender" element={<ConnectCalender />} />
              <Route path="/eventsCalender" element={<EventsCalender />} />

              {/***demo repo shiltu Start***/}
              <Route path="/BuildBudgetForecast" element={<BuildBudgetForecast />} />
              <Route path="/BuildAccountsPayableAging" element={<BuildAccountsPayableAging />} />
              <Route path="/BuildPersonalFinancialStatement" element={<BuildPersonalFinancialStatement />} />

              <Route path="/BuildBusinessFinancialStatement" element={<BuildBusinessFinancialStatement />} />
              <Route path="/Build13WeekCashFlowStatement" element={<Build13WeekCashFlowStatement />} />
              <Route path="/Build12MonthCashFlowStatement" element={<Build12MonthCashFlowStatement />} />
              {/***demo repo shiltu End***/}

              {/* <Route path="/register" element={<Register />} /> */}
              <Route path="/passwordconformation" element={<Passwordconformation />} />
              <Route path="/verification" element={<Verification />} />
              <Route path="/" element={<Home />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/nosotros" element={<Nosotros />} />
              <Route path="/restorepassword" element={<Restorepassword />} />
              <Route path="/createprofile2" element={<Createprofile2 />} />
              <Route path="/tabcomponent" element={<Tabcomponent />} />
              <Route path="/view-normal-invoice/:id" element={<ViewNormalInvoice />} />
              <Route path="/view-SatNormal-invoice/:id" element={<ViewSatNormal />} />

              <Route path="/link-manage-account" element={<LinkManageAccount />} />

              {/* investment */}
              <Route path="/link-manage-investment" element={<LinkManageInvestment />} />
              <Route path="/investment-options" element={<InvestmentOptions />} />
              <Route path="/raise-capital" element={<RaiseCapital />} />
              <Route path="/view-investor-document" element={<UserViewDocsInv />} />
              <Route path="/view-capital-option" element={<ViewCapitalOption />} />

              {/* document Manager */}
              <Route path="/document-manager" element={<DocumentManager />} />
              {/* <Route path="/import-document" element={<ImportDocument />} />
              <Route path="/document-vault" element={<DocumentVault />} />
              <Route path="/create-send-docs" element={<CreateSendDocs />} /> */}

            </Route>
          </Route>

          <Route path="/management" element={<Management />} />
          <Route path="/bill" element={<Bill />} />
          <Route path="/track" element={<Track />} />
          <Route path="/billcnt" element={<Billcnt />} />
          <Route path="/managebtn" element={<Managebtn />} />
          <Route path="/managementtop" element={<Managementtop />} />
          <Route path="/clasification" element={<Clasification />} />
          <Route path="/dropbox" element={<Dropbox />} />

          <Route path="/Userlist" element={<Userlist />} />
          <Route path="/invite" element={<Invite />} />
          <Route path="/information" element={<Information />} />
          <Route path="/configuration" element={<Configuration />} />
          <Route path="/eliminate" element={<Eliminate />} />
          <Route path="/track2" element={<Track2 />} />
          {/* </Route> */}
          {/* </Route> */}

          <Route path="/invoice-print" element={<InvoicePrint />} />
          {/* <Route element={<PaperLayout />}>
            <Route path="/whitepapers" element={<Whitepapers />} />
          </Route> */}

          <Route path="/TermsService" element={<TermCondition />} />
          <Route path="/Privacy" element={<PrivacyPolicy />} />

          {/* <Route path="/apis" element={<Apis />} />
          <Route path="/platform" element={<IntelligencePlatform />} />
          <Route path="/digitalaudit" element={<DigitalAudits />} />
          <Route path="/business" element={<BusinessIntelligence />} />
          <Route path="/apis" element={<Apis />} />
          <Route path="/blog-cipbit" element={<Cip />} />
          <Route path="/Consolited-analytics" element={<ConsolidatedAnalyticsBusiness />} />
          <Route path="/Consolited-onlinesales" element={<OnlineSalesBusiness />} />
          <Route path="/existing-system" element={<ExistingSystem />} /> */}

        </Route>
        <Route element={<PrivateRouter />}>
          <Route path="/accept-update-transaction/:id" element={<AcceptuPdateTransaction />} />
        </Route>
        <Route path="/confirm-password/:id" element={<ConfirmPassword />} />
        <Route path="/google-calendar" element={<GoogleCalendarConnect />} />

      </Routes>
    </Router>
  );
}

export default App;