import React, { useCallback, useEffect, useState } from "react";
import "./BuildReports.css";
import { data } from "jquery";
import toast from "react-hot-toast";
import { useLanguage } from "../../../Providers/LanguageContext";
import HttpClient from "../../../utils/HttpClient";
import CustomLoaderLine from "../../../Component/Loader/CustomLoaderLine";
import { useUserData } from "../../../Providers/UserWrapper";
import moment from "moment";

function BuildInvoice({ setIncStCsv, setPdfCreate, setPdfLoader, setEmailSendFunc }) {
  const { language, currentLanguage } = useLanguage();
  const { profileData, setIsLoading } = useUserData();

  // console.log("profileDataasdd", profileData)

  const initIncome = {
    title: "",
    period: "",
    periodPercent: "",
    cumulative: "",
    cumulativePercent: ""
  }

  const [incomeData, setIncomeData] = useState([]);
  const [expenseData, setExpenseData] = useState([]);

  useEffect(() => {
    setIncomeData([
      {
        title: language.sales?.toUpperCase(),
        period: "",
        periodPercent: "",
        cumulative: "",
        cumulativePercent: ""
      },
      {
        title: language.other_income,
        period: "",
        periodPercent: "",
        cumulative: "",
        cumulativePercent: ""
      },
      {
        title: language.finance_income,
        period: "",
        periodPercent: "",
        cumulative: "",
        cumulativePercent: ""
      }
    ])

    setExpenseData([
      {
        title: "DEV.",
        period: "",
        periodPercent: "",
        cumulative: "",
        cumulativePercent: ""
      },
      {
        title: language.cost_of_sales,
        period: "",
        periodPercent: "",
        cumulative: "",
        cumulativePercent: ""
      }
    ])

  }, [currentLanguage])


  const [totalIncome, setTotalIncome] = useState(initIncome);
  const [totalExpense, setTotalExpense] = useState(initIncome);
  const [totalFinal, setTotalFinal] = useState(initIncome);

  const [isSave, setIsSave] = useState(true);
  const [loading, setLoading] = useState(false);
  const [deleteRowInc, setDeleteRowInc] = useState(false);
  const [deleteRowExp, setDeleteRowExp] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);


  // handleChange income
  const handleChange = (e, ind) => {
    const { name, value } = e.target

    // stores the input value in changedArr
    const changedArr = incomeData?.map((item, i) => {
      if (i === ind) return ({ ...item, [name]: value });
      else return item
    });

    clculateAllDataIncome(changedArr);
  }

  // sum and percentage calculation function for income
  const clculateAllDataIncome = (changedArr) => {
    // getting the total values for period and cumulative
    const sumPeriod = sumFromArrayOfObject(changedArr, "period");
    const sumCumulative = sumFromArrayOfObject(changedArr, "cumulative");
    const totalPeriodPerInc = sumFromArrayOfObject(incomeData, "periodPercent");
    const totalCumuPerInc = sumFromArrayOfObject(incomeData, "cumulativePercent");
    setTotalIncome(prev => ({ ...prev, period: sumPeriod, periodPercent: 100 }));
    setTotalIncome(prev => ({ ...prev, cumulative: sumCumulative, cumulativePercent: 100 }));

    //  stores the percentage calculate value in percentArr
    const percentArr = changedArr?.map((item) => {
      return ({
        ...item,
        periodPercent: perCentageCalculate(item?.period, sumPeriod),
        cumulativePercent: perCentageCalculate(item?.cumulative, sumCumulative)
      })
    })

    // setIncomeData(changedArr);
    setIncomeData(percentArr);

  }

  // handleChange expense
  const handleChangeExpense = (e, ind) => {
    const { name, value } = e.target

    // stores the input value in changedArr
    const changedArr = expenseData?.map((item, i) => {
      if (i === ind) return ({ ...item, [name]: value });
      else return item
    });

    calculateAllDataExp(changedArr);
  }

  // sum and percentage calculation function for expense
  const calculateAllDataExp = (changedArr) => {
    // getting the total values for period and cumulative
    const sumPeriod = sumFromArrayOfObject(changedArr, "period");
    const sumCumulative = sumFromArrayOfObject(changedArr, "cumulative");
    const totalPeriodPerExp = sumFromArrayOfObject(expenseData, "periodPercent");
    const totalCumuPerExp = sumFromArrayOfObject(expenseData, "cumulativePercent");

    setTotalExpense(prev => ({ ...prev, period: sumPeriod, periodPercent: 100 }));
    setTotalExpense(prev => ({ ...prev, cumulative: sumCumulative, cumulativePercent: 100 }));

    //  stores the percentage calculate value in percentArr
    const percentArr = changedArr?.map((item) => {
      return ({
        ...item,
        periodPercent: perCentageCalculate(item?.period, sumPeriod),
        cumulativePercent: perCentageCalculate(item?.cumulative, sumCumulative)
      })
    })

    // setExpenseData(changedArr);
    setExpenseData(percentArr);
  }


  // validation of income data
  const validateIncome = () => {
    let isVal = false
    for (let x of incomeData) {
      isVal = Object.values(x).every((item) => item !== "");
      if (!isVal) {
        break
      }
    }
    return isVal;
  }

  // validation of expense data
  const validateExpense = () => {
    let isVal = false
    for (let x of expenseData) {
      isVal = Object.values(x).every((item) => item !== "");
      if (!isVal) {
        break
      }
    }
    return isVal;
  }


  // returns the sum of a key from array of object
  const sumFromArrayOfObject = (arr, key) => {
    const sum = arr.reduce((acc, cur) => acc + Number(cur[key]), 0);
    return sum ? sum : "";
  }

  // returns the parcentage of a number
  const perCentageCalculate = (num, total) => {
    const percent = Number(num) / Number(total) * 100;
    return Number.isInteger(percent) ? percent : percent?.toFixed(2);
  }

  // click on save
  const handleSave = async () => {
    // if (!validateIncome() || !validateExpense()) {
    //   toast.error(language.please_enter_all);
    //   return
    // }

    const incArr = incomeData.map(item => item?.title);
    const expArr = expenseData.map(item => item?.title);

    if (isSave) {
      const sendData = {
        "type": "income_statements",
        "Expenses": expArr,
        "Income": incArr,
      }
      // console.log("savedd", incomeData, expenseData, sendData);
      setSaveLoading(true);
      const res = await HttpClient.requestData("add-record-reportbuilder", "POST", sendData);
      // console.log("resiccc", res)
      if (res && res?.status) {
        toast.success("Report Saved Successfully");
        setIsSave(!isSave);
        // getInvoiceData();
        setSaveLoading(false);
      } else {
        toast.error(res?.message);
        setSaveLoading(false);
        return;
      }
    } else {
      setIsSave(!isSave);
    }


    // set income total
    const totalPeriodInc = sumFromArrayOfObject(incomeData, "period");
    const totalPeriodPerInc = sumFromArrayOfObject(incomeData, "periodPercent");
    const totalCumuInc = sumFromArrayOfObject(incomeData, "cumulative");
    const totalCumuPerInc = sumFromArrayOfObject(incomeData, "cumulativePercent");

    // set Expense total
    const totalPeriodExp = sumFromArrayOfObject(expenseData, "period");
    const totalPeriodPerExp = sumFromArrayOfObject(expenseData, "periodPercent");
    const totalCumuExp = sumFromArrayOfObject(expenseData, "cumulative");
    const totalCumuPerExp = sumFromArrayOfObject(expenseData, "cumulativePercent");

    //set final total
    setTotalFinal(prev => ({ ...prev, period: (totalPeriodInc - totalPeriodExp)?.toFixed(2) }));
    setTotalFinal(prev => ({ ...prev, periodPercent: (totalPeriodPerInc - totalPeriodPerExp)?.toFixed(2) }));
    setTotalFinal(prev => ({ ...prev, cumulative: (totalCumuInc - totalCumuExp)?.toFixed(2) }));
    setTotalFinal(prev => ({ ...prev, cumulativePercent: (totalCumuPerInc - totalCumuPerExp)?.toFixed(2) }));

    if (!isSave) {
      setTotalFinal(initIncome);
    }
  }

  // build report onclick
  const handleBuild = async () => {
    // if (!validateIncome() || !validateExpense()) {
    //   toast.error(language.please_enter_all);
    //   return
    // }

    if (isSave) {
      toast.error("Please Save First");
      return
    }
    const sendData = {
      Income: incomeData?.map((item, i) => ({
        "title": item?.title,
        "period": Number(item?.period),
        "periodPer": Number(item?.periodPercent),
        "cumulative": Number(item?.cumulative),
        "cumulativePer": Number(item?.cumulativePercent)
      })),
      Expenses: expenseData?.map((item, i) => ({
        "title": item?.title,
        "period": Number(item?.period),
        "periodPer": Number(item?.periodPercent),
        "cumulative": Number(item?.cumulative),
        "cumulativePer": Number(item?.cumulativePercent)
      })),
      ProfitOrLoss: {
        "period": totalFinal?.period,
        "periodPer": totalFinal?.periodPercent,
        "cumulative": totalFinal?.cumulative,
        "cumulativePer": totalFinal?.cumulativePercent,
      }
    }
    setPdfLoader(true);
    setLoading(true);
    // const res = await HttpClient.requestData("report-builder/profit-loss", "POST", sendData);
    const res2 = await HttpClient.requestData("reportbuilder-add-profitloss", "POST", sendData);
    // console.log("ressBuild", res2);

    if (res2 && res2?.status) {
      // window.open(res?.path, "_blank");
      toast.success(language.report_build_succ);
      setLoading(false);
      setPdfLoader(false);
    }
    else {
      setLoading(false);
      setPdfLoader(false);
    }
  }

  // getting saved invoice data
  const getInvoiceData = async () => {
    const sendData = {
      "type": "income_statements"
    }
    const res = await HttpClient.requestData("view-record-reportbuilder", "POST", sendData);
    // console.log("resInvvget", res);
    if (res && res?.status) {
      const invArr = res?.data?.Income?.map(item => ({ ...initIncome, title: item }))
      // console.log("invArrxx", invArr)
      if (invArr) {
        setIncomeData([...invArr]);
      }
      const expArr = res?.data?.Expenses?.map(item => ({ ...initIncome, title: item }))
      if (expArr) {
        setExpenseData([...expArr]);
      }
    }
  }

  // send mail
  const sendProfitLossMail = async (emailData, oncloseSendModal) => {
    const data = {
      "email": emailData?.email,
      "language": currentLanguage === "eng" ? "EN" : "ES",
      Income: incomeData?.map((item, i) => ({
        "title": item?.title,
        "period": Number(item?.period),
        "periodPer": Number(item?.periodPercent),
        "cumulative": Number(item?.cumulative),
        "cumulativePer": Number(item?.cumulativePercent)
      })),
      Expenses: expenseData?.map((item, i) => ({
        "title": item?.title,
        "period": Number(item?.period),
        "periodPer": Number(item?.periodPercent),
        "cumulative": Number(item?.cumulative),
        "cumulativePer": Number(item?.cumulativePercent)
      })),
      ProfitOrLoss: {
        "period": totalFinal?.period,
        "periodPer": totalFinal?.periodPercent,
        "cumulative": totalFinal?.cumulative,
        "cumulativePer": totalFinal?.cumulativePercent,
      }
    }

    oncloseSendModal()
    setIsLoading(true);
    const res = await HttpClient.requestData("reportbuilder/pdf/profitloss", "POST", data)
    if (res && res?.status) {
      toast?.success(language.mail_sent_success);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }

  // dowmload pdf
  const handlePdfDownload = async () => {
    // if (isSave) {
    //   toast.error("Please Save First");
    //   return
    // }

    setPdfLoader(true);
    const res = await HttpClient.requestData("reportbuilder/download/pdf/profitloss", "GET", {})
    setPdfLoader(false);
    if (res && res?.status) {
      window.open(res?.data, "_blank")
    } else {
      toast.error(res?.message || "Something Wrong!")
    }
  }

  useEffect(() => {
    getInvoiceData()
    setPdfCreate(() => handlePdfDownload)
  }, [])


  // generate array for excel and csv file
  useEffect(() => {
    if (!isSave) {
      const arrInc = incomeData?.map((item) => {
        return Object.values(item)
      })

      const arrTotalInc = [language?.total_income, ...Object?.values(totalIncome)?.slice(1)]

      const arrExp = expenseData?.map((item) => {
        return Object.values(item)
      })

      const arrTotalExp = [language.total_expense, ...Object?.values(totalExpense)?.slice(1)];

      const arrTotalFinal = [language.total_expense, ...Object?.values(totalExpense)?.slice(1)];

      const finalData = [
        [],
        [language.income_statement_profit_los, language.period_ending, language.report_date],
        [profileData?.companyName, "", moment().format("DD-MM-YYYY")],
        [language.report_generated_by + ":", profileData?.name + " " + profileData?.surName],
        [],
        [],
        [language.income, language.period, "%", language.cunulative, "%"],
        ...arrInc,
        arrTotalInc,
        [""],
        [language.expenses],
        ...arrExp,
        arrTotalExp,
        [""],
        [language.profit + "(or " + language.loss + ")"],
        arrTotalFinal
      ];


      setIncStCsv(finalData)
      // console.log("finalData", finalData)

      // setPdfCreate(() => handleBuild)

    }

    // send
    setEmailSendFunc(() => sendProfitLossMail)

  }, [isSave])


  // >>>>Start Showing INcome Statement Report Data<<<<
  const getInvoiceStatement = async () => {
    const res = await HttpClient.requestData("reportbuilder-view-profitloss", "GET")
    console.log("ressProff", res)
    if (res && res?.status) {
      // setBuildRepData(res?.data)
      const incdata = res?.data && res?.data?.Income?.map((item) => ({
        title: item?.title,
        period: item?.period,
        periodPercent: item?.periodPer,
        cumulative: item?.cumulative,
        cumulativePercent: item?.cumulativePer
      }))
      setIncomeData(incdata);

      const expData = res?.data && res?.data?.Expenses?.map((item) => ({
        title: item?.title,
        period: item?.period,
        periodPercent: item?.periodPer,
        cumulative: item?.cumulative,
        cumulativePercent: item?.cumulativePer
      }))
      setExpenseData(expData);

      const totInc = {
        // title: item?.title,
        period: res?.data?.Total_Income?.period,
        periodPercent: res?.data?.Total_Income?.periodPer,
        cumulative: res?.data?.Total_Income?.cumulative,
        cumulativePercent: res?.data?.Total_Income?.cumulativePer
      }
      setTotalIncome(totInc);

      const totExp = {
        // title: item?.title,
        period: res?.data?.Total_Expenses?.period,
        periodPercent: res?.data?.Total_Expenses?.periodPer,
        cumulative: res?.data?.Total_Expenses?.cumulative,
        cumulativePercent: res?.data?.Total_Expenses?.cumulativePer
      }
      setTotalExpense(totExp);

    }
  }

  useEffect(() => {
    getInvoiceStatement();
  }, [])
  // >>>>End Showing INcome Statement Report Data<<<<

  return (
    <>
      <div className="buildIncmStTableDiv">
        <table className="buildIncmStTable">

          {/* ******************Income****************** */}
          <tr>
            <th className="buildIncmStTh buildIncmStThFirst">

              {/* plus button */}
              {
                isSave &&
                <button
                  className="buildAddBtn"
                  onClick={() => {
                    // if (validateIncome()) {
                    setIncomeData(prev => ([...prev, initIncome]))
                    // } else {
                    //   toast.error(language.please_enter_all);
                    // }
                  }}
                >
                  <i class="fa-solid fa-plus"></i>
                </button>
              }

              <span>{language.income}</span>

              {isSave &&
                <button
                  className="delBuildRowBtn"
                  onClick={() => setDeleteRowInc(prev => !prev)}

                >
                  {deleteRowInc ? language.remove_delete : language.delete_row}
                </button>
              }
            </th>
            <th className="buildIncmStTh pr-2">
              <span>{language.period}</span>
            </th>
            <th className="buildIncmStTh pr-2 buildIncmSttdPer">
              <span>%</span>
            </th>
            <th className="buildIncmStTh pr-2">
              <span>{language.cunulative}</span>
            </th>
            <th className="buildIncmStTh buildIncmSttdPer">
              <span>%</span>
            </th>
          </tr>

          {
            incomeData?.map((item, i, arr) => {
              return (
                <tr>
                  <td className="buildIncmStTd">
                    {/* minus button */}
                    {deleteRowInc && isSave && arr.length != 1 &&
                      <button
                        className="buildRemoveBtn"
                        onClick={() => {
                          const fltData = incomeData?.filter((ele, ind) => ind !== i)
                          clculateAllDataIncome(fltData);
                          // setIncomeData(prev => (prev.filter((ele, ind) => ind !== i)))
                        }}
                      >
                        <i class="fa-solid fa-minus"></i>
                      </button>
                    }

                    {
                      isSave
                        ?
                        <input
                          type="text"
                          className="userBuildInp"
                          name="title"
                          value={item.title}
                          onChange={(e) => handleChange(e, i)}
                        />
                        :
                        <span>{item.title?.toUpperCase()}</span>
                    }
                  </td>
                  <td className="buildIncmStTd pr-2">
                    <input
                      type="number"
                      name="period"
                      value={item.period}
                      onChange={(e) => handleChange(e, i)}
                      readOnly={isSave ? false : true}
                    />
                  </td>
                  <td className="buildIncmStTd buildIncmSttdPer pr-2">
                    <input
                      type="number"
                      name="periodPercent"
                      value={item.periodPercent}
                      onChange={(e) => handleChange(e, i)}
                      // readOnly={isSave ? false : true}
                      readOnly
                    />
                  </td>
                  <td className="buildIncmStTd pr-2">
                    <input
                      type="number"
                      name="cumulative"
                      value={item.cumulative}
                      onChange={(e) => handleChange(e, i)}
                      readOnly={isSave ? false : true}
                    />
                  </td>
                  <td className="buildIncmStTd buildIncmSttdPer">
                    <input
                      type="number"
                      name="cumulativePercent"
                      value={item.cumulativePercent}
                      onChange={(e) => handleChange(e, i)}
                      // readOnly={isSave ? false : true}
                      readOnly

                    />
                  </td>
                </tr>
              )
            })
          }
          {/* ******************Income end****************** */}


          {/*divide line */}
          <tr>
            <td></td>
            <td colSpan="4">
              <div className="buildTotDivider"></div>
            </td>
          </tr>

          {/* Total Income */}
          <tr>
            <td className="buildIncmStTd">
              <span className="buildTotSpan">{language?.total_income}</span>
            </td>
            <td className="buildIncmStTd pr-2">
              <input
                type="number"
                readOnly
                value={totalIncome.period}
              />
            </td>
            <td className="buildIncmStTd buildIncmSttdPer pr-2">
              <input
                type="number"
                value={totalIncome.periodPercent}
                readOnly
              />
            </td>
            <td className="buildIncmStTd pr-2">
              <input
                type="number"
                readOnly
                value={totalIncome.cumulative}
              />
            </td>
            <td className="buildIncmStTd buildIncmSttdPer">
              <input
                type="number"
                value={totalIncome.cumulativePercent}
                readOnly
              />
            </td>
          </tr>


          {/* ******************Expenses************************* */}
          <tr>
            <th className="buildIncmStTh">

              {/* plus button */}
              {
                isSave &&
                <button
                  className="buildAddBtn"
                  onClick={() => {
                    // if (validateExpense()) {
                    setExpenseData(prev => ([...prev, initIncome]));
                    // } else {
                    //   toast.error(language.please_enter_all);
                    // }
                  }}
                >
                  <i class="fa-solid fa-plus"></i>
                </button>
              }
              <span>{language.expenses}</span>

              {isSave &&
                <button
                  className="delBuildRowBtn"
                  onClick={() => setDeleteRowExp(prev => !prev)}

                >
                  {deleteRowExp ? language.remove_delete : language.delete_row}
                </button>
              }
            </th>
            <th colSpan="4"></th>
          </tr>

          {
            expenseData.map((item, i, arr) => {
              return (
                <tr key={i}>
                  <td className="buildIncmStTd">

                    {/* delete icon  */}
                    {
                      deleteRowExp && isSave && arr.length != 1 &&
                      <button
                        className="buildRemoveBtn"
                        onClick={() => {
                          const fltData = expenseData?.filter((ele, ind) => ind !== i);
                          calculateAllDataExp(fltData);
                          // setExpenseData(prev => (prev.filter((ele, ind) => ind !== i)))
                        }}
                      >
                        <i class="fa-solid fa-minus"></i>
                      </button>
                    }
                    {
                      isSave
                        ?
                        <input
                          type="text"
                          className="userBuildInp"
                          name="title"
                          value={item.title}
                          onChange={(e) => handleChangeExpense(e, i)}
                        />
                        :
                        <span>{item.title?.toUpperCase()}</span>
                    }
                  </td>
                  <td className="buildIncmStTd pr-2">
                    <input
                      type="number"
                      name="period"
                      value={item.period}
                      onChange={(e) => handleChangeExpense(e, i)}
                      readOnly={isSave ? false : true}
                    />
                  </td>
                  <td className="buildIncmStTd buildIncmSttdPer pr-2">
                    <input
                      type="number"
                      name="periodPercent"
                      value={item.periodPercent}
                      onChange={(e) => handleChangeExpense(e, i)}
                      // readOnly={isSave ? false : true}
                      readOnly
                    />
                  </td>
                  <td className="buildIncmStTd pr-2">
                    <input
                      type="number"
                      name="cumulative"
                      value={item.cumulative}
                      onChange={(e) => handleChangeExpense(e, i)}
                      readOnly={isSave ? false : true}
                    />
                  </td>
                  <td className="buildIncmStTd buildIncmSttdPer">
                    <input
                      type="number"
                      name="cumulativePercent"
                      value={item.cumulativePercent}
                      onChange={(e) => handleChangeExpense(e, i)}
                      // readOnly={isSave ? false : true}
                      readOnly
                    />
                  </td>
                </tr>
              )
            })
          }
          {/* ******************Expenses  End******************** */}


          {/*  */}
          <tr>
            <td></td>
            <td colSpan="4">
              <div className="buildTotDivider"></div>
            </td>
          </tr>

          {/* Total Expenses */}
          <tr>
            <td className="buildIncmStTd">
              <span className="buildTotSpan">{language.total_expense}</span>
            </td>
            <td className="buildIncmStTd pr-2">
              <input
                type="number"
                value={totalExpense.period}
                readOnly
              />
            </td>
            <td className="buildIncmStTd buildIncmSttdPer pr-2">
              <input
                type="number"
                value={totalExpense.periodPercent}
                readOnly
              />
            </td>
            <td className="buildIncmStTd pr-2">
              <input
                type="number"
                value={totalExpense.cumulative}
                readOnly
              />
            </td>
            <td className="buildIncmStTd buildIncmSttdPer">
              <input
                type="number"
                value={totalExpense.cumulativePercent}
                readOnly
              />
            </td>
          </tr>

          {/* final total */}
          <tr>
            <td className="buildIncmStTd">
              <span className="buildProSpan">{language.profit} (or {language.loss})</span>
            </td>
            <td className="buildIncmStTd pr-2">
              <input
                type="number"
                // value={totalFinal.period}
                value={Number(totalIncome?.period) - Number(totalExpense?.period)}
                readOnly
              />
            </td>
            <td className="buildIncmStTd buildIncmSttdPer pr-2">
              <input
                type="number"
                // value={totalFinal.periodPercent}
                value={0}
                readOnly
              />
            </td>
            <td className="buildIncmStTd pr-2">
              <input
                type="number"
                // value={totalFinal.cumulative}
                value={Number(totalIncome?.cumulative) - Number(totalExpense?.cumulative)}
                readOnly
              />
            </td>
            <td className="buildIncmStTd buildIncmSttdPer">
              <input
                type="number"
                // value={totalFinal.cumulativePercent}
                value={0}
                readOnly
              />
            </td>
          </tr>

        </table>

        {/* button group */}
        <div className="d-flex justify-content-center mt-3">
          <button
            className="btn-report-save"
            onClick={handleSave}
          >
            {isSave ?
              saveLoading ? <CustomLoaderLine height="10" width="40" /> : language.save?.toUpperCase()
              :
              language.edit?.toUpperCase()
            }
          </button>

          <button
            className="btn-report-build"
            onClick={handleBuild}
          >
            {loading ? <CustomLoaderLine height="10" width="100" /> : language.build_report?.toUpperCase()}
          </button>
        </div>

      </div >
    </>
  );
}

export default BuildInvoice;
