import React, { useEffect } from 'react'
import { useState } from 'react';
import DownloadTemplateModal from './DownloadTemplateModal';
import HttpClient from '../../utils/HttpClient';
import toast from 'react-hot-toast';
import { useLanguage } from '../../Providers/LanguageContext';
import { useUserData } from '../../Providers/UserWrapper';

const ImportDocument = ({ getAllDocument }) => {

    const { language, currentLanguage } = useLanguage()
    const { setIsLoading } = useUserData();

    const [downloadPopup, setDownloadPopup] = useState(false);
    // const [isLoading, setIsLoading] = useState(false);

    const initValue = {
        "typeId": "",
        "file": "",
        "documentName": ""
    }
    const [formValue, setFormValue] = useState(initValue);
    const [typeData, setTypeData] = useState([]);

    console.log("typeDatadcc", formValue)

    // handleChange
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValue(prev => ({ ...prev, [name]: value }))
    }

    const toggleDownloadPopup = (e) => {
        e.preventDefault()
        setDownloadPopup(!downloadPopup);
    };

    // handle change file
    const handlePdfUpload = async (e) => {
        const file = e.target.files[0]
        if (file) {
            const data = new FormData();
            data.append("document", file)
            // console.log('imgData',data)
            setIsLoading(true)
            const res = await HttpClient.fileUplode("upload/selfservice-document", "POST", data);
            console.log('imgres', res)
            setIsLoading(false)
            if (res && res.status) {
                setFormValue(prev => ({ ...prev, documentName: res?.originalname, file: res?.url }))
                toast.success("Uploaded SuccessFully!")
            } else {

            }
        }
    }

    // get alltype
    const getAllType = async () => {
        const data = {
            "language": "EN" //"ES"
        }
        const res = await HttpClient.requestData("view/importdocument", "GET", {})
        console.log("resdff", res)
        if (res && res?.status) {
            setTypeData(res?.data)
        }
    }

    // validation
    const validate = () => {
        if (!formValue.typeId) {
            toast.error("Please Select a Type")
            return true
        }

        if (!formValue.file) {
            toast.error("Please Choose a File")
            return true
        }

        return false
    }

    // handle import
    const handleImport = async (e) => {
        e.preventDefault()
        if (validate()) {
            return
        }
        setIsLoading(true)
        const res = await HttpClient.requestData("add/selfservice-document", "POST", formValue)
        setIsLoading(false)
        if (res && res?.status) {
            setFormValue(initValue)
            toast.success("Document Imported Successfully!")
            getAllDocument();
        } else {
            res.error(res.message || "Something Wrong")
        }
    }

    useEffect(() => {
        getAllType()
    }, [])


    return (
        <>
            <section className='documentManagerslider_sec'>
                <div className='container'>
                    <div className='headbar'>
                        <h4>Document Manager – Import Documents</h4>
                    </div>

                    <div className='subheadbar'>
                        <h6>Self-Service Document Management</h6>
                    </div>

                    <form>

                        {/* type */}
                        <div className="form-group">
                            <label htmlFor="">Type</label>
                            <select
                                className="form-control"
                                id=""
                                name='typeId'
                                value={formValue.typeId}
                                onChange={e => handleChange(e)}
                            >
                                <option disabled selected value={""}>-Select-</option>
                                {
                                    typeData?.map((item, i) =>
                                        <option key={i} value={item?._id}>
                                            {currentLanguage === "eng" ? item?.name_EN : item?.name_ES}
                                        </option>
                                    )
                                }
                            </select>
                        </div>

                        {/* file */}
                        <div className="form-group">
                            <label htmlFor="Fileinput" className="">
                                File
                            </label>

                            <input
                                type="file"
                                name="Fileinput"
                                id="Fileinput"
                                className="border form-control"
                                onChange={(e) => handlePdfUpload(e)}
                            />
                        </div>

                        {/* button group */}
                        <div className='allbtn_wrap'>
                            <button
                                className='importdocument_btn'
                                onClick={(e) => handleImport(e)}
                            >
                                Import Document
                            </button>
                            <button
                                className='downloadtemplate_btn'
                                onClick={toggleDownloadPopup}
                            >
                                Download Template
                            </button>
                        </div>

                    </form>
                </div>
                {downloadPopup &&
                    <DownloadTemplateModal
                        handleClose={toggleDownloadPopup}
                        modalIsOpen={downloadPopup}
                    />
                }
            </section>

        </>
    )
}

export default ImportDocument
