import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Eliminate from '../Component/Modal/Eliminate'
import { useLanguage } from '../Providers/LanguageContext';
import { UserContext } from '../Providers/UserWrapper';
import { AiOutlineCopy } from "react-icons/ai"
import { toast } from 'react-hot-toast';
import { SpeechContext } from '../Providers/SpeechWrapper';
import config_en1 from "../extras/x_share_account_code_audio_a.mp3";
import config_es1 from "../extras/x_share_account_code_audio_a_es.mp3";
import HttpClient from '../utils/HttpClient';

function Configaration() {
  const navigate = useNavigate();
  const { language, currentLanguage } = useLanguage()
  const { currAudio, setCurrAudio, playing } = useContext(SpeechContext);

  const [openModal, setOpenModal] = useState(false);
  const { profileData, getProfile } = useContext(UserContext);

  const [accCode, setAccCode] = useState("")
  const [values, setValues] = useState({
    name: "",
    surName: "",
    phoneNumber: "",
    country: "",
    cp: "",
    city: "",
    state: ""
  })


  useEffect(() => {
    if (profileData) {
      if (profileData.name) {
        setValues(prev => ({ ...prev, name: profileData.name }))
      }
      if (profileData.surName) {
        setValues(prev => ({ ...prev, surName: profileData.surName }))
      }
      if (profileData.phoneNumber) {
        setValues(prev => ({ ...prev, phoneNumber: profileData.phoneNumber }))
      }
      if (profileData.country) {
        setValues(prev => ({ ...prev, country: profileData.country }))
      }
      if (profileData.cp) {
        setValues(prev => ({ ...prev, cp: profileData.cp }))
      }
      if (profileData.city) {
        setValues(prev => ({ ...prev, city: profileData.city }))
      }
      if (profileData.state) {
        setValues(prev => ({ ...prev, state: profileData.state }))
      }
      if (profileData.accountRegCode) {
        setAccCode(profileData.accountRegCode)
      }
    }
  }, [profileData])

  if (profileData && !profileData.name) {
    navigate("/configuration");
  }


  useEffect(() => {
    if (playing) {
      if (currAudio) {
        if (!currAudio.paused) {
          currAudio.pause()
        }
      }
      if (currentLanguage === "eng") {
        setCurrAudio(new Audio(config_en1))
      } else {
        setCurrAudio(new Audio(config_es1))
      }
    } else {
      if (currAudio) {
        if (!currAudio.paused) {
          currAudio.pause()
          setCurrAudio(null)
        }
      }
    }

    return () => {
      if (currAudio && !currAudio.paused) {
        currAudio.pause()
      }
    }
  }, [playing, currentLanguage])


  // useEffect(() => {
  //   if (currAudio) {
  //     if (!currAudio.paused) {
  //       currAudio.pause()
  //     }
  //     currAudio.play()
  //   }
  //   return () => {
  //     if (currAudio && !currAudio.paused) {
  //       currAudio.pause()
  //     }
  //   }
  // }, [currAudio])

  const handleChange = (e) => {
    setValues(prev => ({ ...prev, [e.target.name]: e.target.value }));
  }

  const handleUpdate = async () => {
    const vals = Object.values(values);
    let isErr = false;
    for (let val of vals) {
      if (!val) {
        isErr = true
      }
    }
    if (isErr) {
      toast.error(language.please_enter)
      return
    }
    const res = await HttpClient.requestData("update", "PUT", values);
    if (res && res.success) {
      toast.success(language.profile_update_success)
      getProfile()
    } else {
      toast.error(language.profile_update_error)
    }

  }

  return (

    <>

      <div id='config-head'>
        <div className='container'>
          <div className='config-heading'>
            <h4>{language.profile_settings}</h4>
          </div>
        </div>
      </div>

      <div id='config-form'>
        <div className='container'>
          <div className='inner-container'>
            <form>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id=""
                  aria-describedby=""
                  value={values.name}
                  onChange={handleChange}
                  name="name"
                  placeholder={language.name}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id=""
                  aria-describedby=""
                  name='surName'
                  onChange={handleChange}
                  value={values.surName}
                  placeholder={language.surname}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id=""
                  aria-describedby=""
                  name='phoneNumber'
                  onChange={handleChange}
                  value={values.phoneNumber}
                  placeholder={language.phone_number}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id=""
                  aria-describedby=""
                  name='country'
                  onChange={handleChange}
                  value={values.country}
                  placeholder={language.country}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id=""
                  aria-describedby=""
                  name='cp'
                  onChange={handleChange}
                  value={values.cp}
                  placeholder="pin"
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id=""
                  aria-describedby=""
                  name='city'
                  onChange={handleChange}
                  value={values.city}
                  placeholder={language.city}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id=""
                  aria-describedby=""
                  name='state'
                  onChange={handleChange}
                  value={values.state}
                  placeholder={language.state}
                />
              </div>
              <div className="form-group" style={{ position: "relative" }}>
                <label>{language.account_code}</label>
                <input
                  type="text"
                  className="form-control"
                  id=""
                  aria-describedby=""
                  value={accCode}
                  disabled
                  placeholder={language.account_code}
                />
                <span
                  style={{ position: "absolute", right: 10, bottom: 12 }}
                  onClick={() => {
                    navigator?.clipboard?.writeText(accCode)
                    toast.success(language.copy_clipboard)
                  }}
                >
                  <AiOutlineCopy size={25} />
                </span>
              </div>
            </form>
            <div className='d-flex justify-content-between align-items-center'>
              <button className='customBtn4' onClick={handleUpdate}>{language.update}</button>

              <div className='config-btn' style={{ cursor: 'pointer' }}>
                <a className='eliminate-btn' onClick={() => { setOpenModal(true) }}>{language.eliminate}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {openModal && <Eliminate closeModal={setOpenModal} />}
    </>
  )
}

export default Configaration