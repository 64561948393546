import React, { useEffect, useState } from "react";
import "./BuildReports.css";
import toast from "react-hot-toast";
import { useLanguage } from "../../../Providers/LanguageContext";
import HttpClient from "../../../utils/HttpClient";
import CustomLoaderLine from "../../../Component/Loader/CustomLoaderLine";
import moment from "moment";
import { useUserData } from "../../../Providers/UserWrapper";

function BuildBalance({ setBalanceSheetCsv, setPdfCreate, setPdfLoader, setEmailSendFunc }) {
  const { language, currentLanguage } = useLanguage();
  const { profileData, setIsLoading } = useUserData();

  const initAsset = {
    title: "",
    ammount: ""
  }
  const [assetData, setAssetData] = useState([]);
  useEffect(() => {
    setAssetData([
      {
        title: language.cash_on_hand,
        ammount: ""
      },
      {
        title: language.cash_in_bank,
        ammount: ""
      },
      {
        title: language.total_cash_hand,
        ammount: ""
      },
      {
        title: language.total_current_asset,
        ammount: ""
      },
      {
        title: language.client_ar_1,
        ammount: ""
      },
      {
        title: language.client_ar_2,
        ammount: ""
      },
      {
        title: language.total_acc_recievable,
        ammount: ""
      },
      {
        title: language.total_fixet_asset,
        ammount: ""
      },
      {
        title: language.total_other_asset,
        ammount: ""
      },

    ])
  }, [currentLanguage]);

  const [liabilityData, setLiabilityData] = useState([]);
  useEffect(() => {
    setLiabilityData([
      {
        title: language.total_current_liability,
        ammount: ""
      },
      {
        title: language.supplier_ap_1,
        ammount: ""
      },
      {
        title: language.supplier_ap_2,
        ammount: ""
      },
      {
        title: language.total_acc_payable,
        ammount: ""
      },
      {
        title: language.total_long_term_lia,
        ammount: ""
      },
      {
        title: language.total_shareholder_equitiy,
        ammount: ""
      },
      {
        title: language.total_liability_Share_equity,
        ammount: ""
      },
      {
        title: language.total_profit_loss_year,
        ammount: ""
      }
    ])
  }, [currentLanguage])

  // console.log("assetDataaa", assetData)

  const [totalData, setTotalData] = useState({ asset: "", liability: "" })

  const [isSave, setIsSave] = useState(true);
  const [loading, setLoading] = useState(false);
  const [deleteRowAsset, setDeleteRowAsset] = useState(false);
  const [deleteRowLia, setDeleteRowLia] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);


  // asset handlechange
  const handleChangeAsset = (e, ind) => {
    const { name, value } = e.target;
    const data = assetData.map((item, i) => {
      if (i === ind) {
        return { ...item, [name]: value }
      } else {
        return item
      }
    })
    setAssetData(data);
    clculateAllDataIncome(data)
  }

  // sum calculation function for asset
  const clculateAllDataIncome = (changedArr) => {
    const sumAsset = sumFromArrayOfObject(changedArr, "ammount");
    setTotalData(prev => ({ ...prev, asset: sumAsset }));
  }

  // handlechange liability
  const handleChangeLiability = (e, ind) => {
    const { name, value } = e.target;
    const data = liabilityData.map((item, i) => {
      if (i === ind) {
        return { ...item, [name]: value }
      } else {
        return item
      }
    })
    setLiabilityData(data);
    clculateAllDataLia(data);
  }

  // sum calculation function for asset
  const clculateAllDataLia = (changedArr) => {
    const sumLia = sumFromArrayOfObject(changedArr, "ammount");
    setTotalData(prev => ({ ...prev, liability: sumLia }));
  }


  // validation of income data
  const validateAsset = () => {
    let isVal = false
    for (let x of assetData) {
      isVal = Object.values(x).every((item) => item !== "");
      if (!isVal) {
        break;
      }
    }
    return isVal;
  }

  // validation of income data
  const validateLiability = () => {
    let isVal = false
    for (let x of liabilityData) {
      isVal = Object.values(x).every((item) => item !== "");
      if (!isVal) {
        break;
      }
    }
    return isVal;
  }

  // returns the sum of a key from array of object
  const sumFromArrayOfObject = (arr, key) => {
    const sum = arr.reduce((acc, cur) => acc + Number(cur[key]), 0);
    return sum ? sum : "";
  }


  // click on save
  const handleSave = async () => {

    // if (!validateAsset() || !validateLiability()) {
    //   toast.error(language.please_enter_all);
    //   return;
    // }

    const assetArr = assetData.map(item => item?.title);
    const liaArr = liabilityData.map(item => item?.title);
    if (isSave) {
      const sendData = {
        "type": "Balance_Sheet",
        "Assets": assetArr,
        "Liabilities": liaArr,
      }
      // console.log("assetarrlia", assetData, liabilityData, sendData);
      setSaveLoading(true);
      const res = await HttpClient.requestData("add-record-reportbuilder", "POST", sendData);
      // console.log("resiccc", res)
      if (res && res?.status) {
        toast.success("Report Saved Successfully");
        setIsSave(!isSave);
        // getInvoiceData();
        setSaveLoading(false);
      } else {
        toast.error(res?.message);
        setSaveLoading(false);
        return;
      }
    } else {
      setIsSave(!isSave);
    }

  }


  // click on build report
  const handleBuildRepport = async () => {
    // if (!validateAsset() || !validateLiability()) {
    //   toast.error(language.please_enter_all);
    //   return
    // }

    if (isSave) {
      toast.error("Please Save First");
      return
    }
    const sendData = {
      "assets": assetData?.map((item) => ({
        "title": item?.title,
        "price": Number(item?.ammount)
      })),
      "liabilities": liabilityData?.map((item) => ({
        "title": item?.title,
        "price": Number(item?.ammount)
      }))
    }
    setLoading(true);
    setPdfLoader(true);
    // const res = await HttpClient.requestData("report-builder/balance-sheet", "POST", sendData);
    const res = await HttpClient.requestData("reportbuilder-add-balancesheet", "POST", sendData);
    // console.log("resbbb", res)
    if (res && res?.status) {
      // window.open(res?.path, "_blank");
      setLoading(false);
      setPdfLoader(false);
      toast.success(language.report_build_succ);
    }
    else {
      setLoading(false);
      setPdfLoader(false);
    }
  }

  // getting saved invoice data
  const getInvoiceData = async () => {
    const sendData = {
      "type": "Balance_Sheet"
    }
    const res = await HttpClient.requestData("view-record-reportbuilder", "POST", sendData);
    console.log("resInvvgetbuild", res);
    if (res && res?.status) {
      const assetArr = res?.data?.Assets?.map(item => ({ ...initAsset, title: item }))
      if (assetArr) {
        setAssetData([...assetArr]);
      }
      const liaArr = res?.data?.Liabilities?.map(item => ({ ...initAsset, title: item }))
      if (liaArr) {
        setLiabilityData([...liaArr]);
      }
    }
  }



  // send mail
  const sendBalanceMail = async (emailData, oncloseSendModal) => {
    const data = {
      "email": emailData?.email,
      "language": currentLanguage === "eng" ? "EN" : "ES",
      "assets": assetData?.map((item) => ({
        "title": item?.title,
        "price": Number(item?.ammount)
      })),
      "liabilities": liabilityData?.map((item) => ({
        "title": item?.title,
        "price": Number(item?.ammount)
      }))
    }

    oncloseSendModal();
    setIsLoading(true);
    const res = await HttpClient.requestData("reportbuilder/pdf/balancesheet", "POST", data)
    if (res && res?.status) {
      toast?.success(language.mail_sent_success);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }

  // dowmload pdf
  const handlePdfDownload = async () => {
    // if (isSave) {
    //   toast.error("Please Save First");
    //   return
    // }

    setPdfLoader(true);
    const res = await HttpClient.requestData("reportbuilder/download/pdf/balancesheet", "GET", {})
    setPdfLoader(false);
    if (res && res?.status) {
      window.open(res?.data, "_blank")
    } else {
      toast.error(res?.message || "Something Wrong!")
    }
  }

  // generate array for excel and csv file
  useEffect(() => {
    if (!isSave) {
      const arrAsset = assetData?.map((item) => {
        return Object.values(item)
      })

      const arrLiability = liabilityData?.map((item) => {
        return Object.values(item)
      })

      const finalData = [
        [],
        [language.balance_sheet, language.period_ending, language.report_date],
        [profileData?.companyName, "----", moment().format("DD-MM-YYYY")],
        [language.report_generated_by + ":", profileData?.name + " " + profileData?.surName],
        [],
        [],
        ["", language.assets],
        ...arrAsset,
        [language.total_assets, totalData?.asset],
        [],
        [],
        ["", language.liabilities],
        ...arrLiability,
        [language.total_liabilities, totalData?.liability]
      ];

      setBalanceSheetCsv(finalData);
      // console.log("finalData", finalData)

      // this saves the pdf download function in a state
      // setPdfCreate(() => handleBuildRepport);

    }


    // send
    setEmailSendFunc(() => sendBalanceMail)
  }, [isSave])

  useEffect(() => {
    getInvoiceData();
    setPdfCreate(() => handlePdfDownload);

  }, []);

  const getInvoiceStatement = async () => {
    const res = await HttpClient.requestData("reportbuilder-view-balancesheet", "GET")
    // console.log("ressProffbb", res)
    if (res && res?.status) {
      // setBuildRepData(res?.data)
      const asst = res?.data?.assets?.map((item) => ({
        title: item?.title,
        ammount: item?.price
      }))

      setAssetData(asst);
      const liaData = res?.data?.liabilities?.map((item) => ({
        title: item?.title,
        ammount: item?.price
      }))
      setLiabilityData(liaData);
      const totalDD = {
        asset: res?.data?.Total_assets,
        liability: res?.data?.Total_liabilities
      }
      setTotalData(totalDD)
    }
  }

  useEffect(() => {
    getInvoiceStatement();
  }, [])
  return (
    <div className="container mt-4">
      <div className="row">

        {/* Assets */}
        <div className="col-lg-6">
          <div className="buldBalTabInnerDiv">
            <table className="buildBalTable">
              <thead>
                <tr>
                  <th colSpan="2" className="buildBalLeftThInp">

                    {/* add button */}
                    {
                      isSave &&
                      <button
                        className="buildAddBtn"
                        onClick={() => {
                          // if (validateAsset()) {
                          setAssetData(prev => ([...prev, initAsset]));
                          // } else {
                          //   toast.error(language.please_enter_all);
                          // }
                        }}
                      >
                        <i class="fa-solid fa-plus"></i>
                      </button>
                    }
                    <span className="buildBalanceHead">{language.assets}</span>

                    {/* delete row button */}
                    {isSave &&
                      <button
                        className="delBuildRowBtn"
                        onClick={() => setDeleteRowAsset(prev => !prev)}
                      >
                        {deleteRowAsset ? language.remove_delete : language.delete_row}
                      </button>
                    }
                  </th>
                </tr>
              </thead>
              <tbody className="mt-2">
                {
                  assetData.map((item, i, arr) => {
                    return (
                      <tr key={i}>
                        <td className="buildBalLeftTdInp">

                          {/* delete button */}
                          {
                            deleteRowAsset && isSave &&
                            arr.length !== 1 &&
                            <button
                              className="buildRemoveBtn"
                              onClick={() => {
                                const flltData = assetData?.filter((ele, ind) => i !== ind);
                                clculateAllDataIncome(flltData);
                                setAssetData(prev => prev.filter((ele, ind) => i !== ind));
                              }}
                            >
                              <i class="fa-solid fa-minus"></i>
                            </button>
                          }
                          {
                            isSave
                              ?
                              <input
                                type="text"
                                name="title"
                                value={item.title}
                                onChange={(e) => handleChangeAsset(e, i)}
                              />
                              :
                              <span className="buildBalLeftTdSpan">{item.title?.toUpperCase()}</span>
                          }

                        </td>
                        <td className="buildBalRightTdInp">
                          <input
                            type="number"
                            name="ammount"
                            value={item.ammount}
                            onChange={(e) => handleChangeAsset(e, i)}
                            readOnly={isSave ? false : true}
                          />
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
              <tfoot>
                <tr>
                  <td className="buildBalLeftTdFoot">
                    <span>{language.total_assets}</span>
                  </td>
                  <td className="buildBalRightTdFoot">
                    <input
                      type="number"
                      value={totalData.asset}
                      readOnly
                    />
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>

        {/* Liabilities */}
        <div className="col-lg-6 mt-4 mt-lg-0">
          <div className="buldBalTabInnerDiv">
            <table className="buildBalTable">
              <thead>
                <tr>
                  <th colSpan="2" className="buildBalLeftThInp">

                    {/* add button */}
                    {
                      isSave &&
                      <button
                        className="buildAddBtn"
                        onClick={() => {
                          // if (validateLiability()) {
                          setLiabilityData(prev => ([...prev, initAsset]));
                          // } else {
                          //   toast.error(language.please_enter_all);
                          // }
                        }}
                      >
                        <i class="fa-solid fa-plus"></i>
                      </button>
                    }

                    <span className="buildBalanceHead">{language.liabilities}</span>
                    {isSave &&
                      <button
                        className="delBuildRowBtn"
                        onClick={() => setDeleteRowLia(prev => !prev)}
                      >
                        {deleteRowLia ? language.remove_delete : language.delete_row}

                      </button>
                    }
                  </th>
                </tr>
              </thead>

              <tbody className="mt-2">
                {
                  liabilityData.map((item, i, arr) => {
                    return (
                      <tr key={i}>
                        <td className="buildBalLeftTdInp">

                          {/* delete button */}
                          {
                            deleteRowLia && isSave &&
                            arr.length !== 1 &&
                            <button
                              className="buildRemoveBtn"
                              onClick={() => {
                                const fltData = liabilityData?.filter((ele, ind) => i !== ind);
                                clculateAllDataLia(fltData);
                                setLiabilityData(prev => prev.filter((ele, ind) => i !== ind));
                              }}
                            >
                              <i class="fa-solid fa-minus"></i>
                            </button>
                          }
                          {
                            isSave
                              ?
                              <input
                                type="text"
                                name="title"
                                value={item.title}
                                onChange={(e) => handleChangeLiability(e, i)}
                              />
                              :
                              <span className="buildBalLeftTdSpan">{item.title?.toUpperCase()}</span>
                          }
                        </td>
                        <td className="buildBalRightTdInp">
                          <input
                            type="number"
                            name="ammount"
                            value={item.ammount}
                            onChange={(e) => handleChangeLiability(e, i)}
                            readOnly={isSave ? false : true}
                          />
                        </td>
                      </tr>
                    )
                  })
                }

                {/* <tr>
                  <td className="buildBalLeftTdInp">
                    <button className="buildRemoveBtn">
                      <i class="fa-solid fa-minus"></i>
                    </button>
                    <span className="buildBalLeftTdSpan">
                      Total Current Liabilities
                    </span>
                  </td>
                  <td className="buildBalRightTdInp">
                    <input type="number" />
                  </td>
                </tr>
                <tr>
                  <td className="buildBalLeftTdInp">
                    <button className="buildRemoveBtn">
                      <i class="fa-solid fa-minus"></i>
                    </button>
                    <span className="buildBalLeftTdSpan">Supplier AP 1</span>
                  </td>
                  <td className="buildBalRightTdInp">
                    <input type="number" />
                  </td>
                </tr>
                <tr>
                  <td className="buildBalLeftTdInp">
                    <button className="buildRemoveBtn">
                      <i class="fa-solid fa-minus"></i>
                    </button>
                    <span className="buildBalLeftTdSpan">Supplier AP 2</span>
                  </td>
                  <td className="buildBalRightTdInp">
                    <input type="number" />
                  </td>
                </tr>
                <tr>
                  <td className="buildBalLeftTdInp">
                    <button className="buildRemoveBtn">
                      <i class="fa-solid fa-minus"></i>
                    </button>
                    <span className="buildBalLeftTdSpan">
                      Total Accounts Payable
                    </span>
                  </td>
                  <td className="buildBalRightTdInp">
                    <input type="number" readOnly />
                  </td>
                </tr>
                <tr>
                  <td className="buildBalLeftTdInp">
                    <button className="buildRemoveBtn">
                      <i class="fa-solid fa-minus"></i>
                    </button>
                    <span className="buildBalLeftTdSpan">
                      Total Long-Term Liabilities
                    </span>
                  </td>
                  <td className="buildBalRightTdInp">
                    <input type="number" />
                  </td>
                </tr>
                <tr>
                  <td className="buildBalLeftTdInp">
                    <button className="buildRemoveBtn">
                      <i class="fa-solid fa-minus"></i>
                    </button>
                    <span className="buildBalLeftTdSpan">
                      Total Shareholder Equity
                    </span>
                  </td>
                  <td className="buildBalRightTdInp">
                    <input type="number" />
                  </td>
                </tr>
                <tr>
                  <td className="buildBalLeftTdInp">
                    <button className="buildRemoveBtn">
                      <i class="fa-solid fa-minus"></i>
                    </button>
                    <span className="buildBalLeftTdSpan">
                      Total Liabilities & Shareholder Equity
                    </span>
                  </td>
                  <td className="buildBalRightTdInp">
                    <input type="number" />
                  </td>
                </tr>
                <tr>
                  <td className="buildBalLeftTdInp">
                    <button className="buildRemoveBtn">
                      <i class="fa-solid fa-minus"></i>
                    </button>
                    <span className="buildBalLeftTdSpan">
                      Total Profit (Loss) for Year
                    </span>
                  </td>
                  <td className="buildBalRightTdInp">
                    <input type="number" />
                  </td>
                </tr> */}
              </tbody>

              {/* total liabilities */}
              <tfoot>
                <tr>
                  <td className="buildBalLeftTdFoot">
                    <span>{language.total_liabilities}</span>
                  </td>
                  <td className="buildBalRightTdFoot">
                    <input
                      type="number"
                      value={totalData.liability}
                      readOnly
                    />

                  </td>
                </tr>
              </tfoot>

            </table>
          </div>
        </div>

        {/* button group */}
        <div className="d-flex justify-content-center mt-3">
          <button
            className="btn-report-save"
            onClick={handleSave}
          >
            {isSave ?
              saveLoading ? <CustomLoaderLine height="10" width="40" /> : language.save?.toUpperCase()
              :
              language.edit?.toUpperCase()
            }
          </button>
          <button
            className="btn-report-build"
            onClick={() => handleBuildRepport()}
          >
            {loading ? <CustomLoaderLine height="10" width="100" /> : language.build_report?.toUpperCase()}          </button>
        </div>

      </div>
    </div >
  );
}

export default BuildBalance;
