import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import Loginbg from "../../src/images/login_bg.png";
import { useLanguage } from "../Providers/LanguageContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import HttpClient from "../utils/HttpClient";
import { auth } from "../FireBase/Firebase";
import toast from 'react-hot-toast';
import { UserContext } from "../Providers/UserWrapper";
import { AiFillApple, AiFillEye, AiFillEyeInvisible, AiOutlineApple } from "react-icons/ai"
import { FcGoogle } from "react-icons/fc";
import { getAuth, signInWithPopup, GoogleAuthProvider, OAuthProvider } from "firebase/auth";

function Login() {
  const location = useLocation();

  // Parse query parameters
  const searchParams = new URLSearchParams(location.search);
  const redirectFrom = searchParams.get('redirectFrom');
  const paramId = searchParams.get('param_id');

  const { setUserData, setGoogleEmail } = useContext(UserContext)
  const myref = useRef(0)

  const { language } = useLanguage();

  const [formValue, setFormValue] = useState({ email: '', pass: '', userType: '' });
  const [formError, setFormError] = useState({})
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);

  const handleChange = (e) => {
    let { name, value } = e.target;
    setFormValue((prev) => {
      return (
        {
          ...prev,
          [name]: value
        }
      )
    })
  }

  const validate = (value) => {
    let error = {};
    // const regex=/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!value.email) {
      error.email = language.email_validate_blank
    }
    // else if(!regex.test(value.email)){
    //   error.email='Please enter valid email';
    // }

    if (!value.pass) {
      error.pass = language.password_validate_blank
    }
    else if (!value.pass) {
      error.pass = language.password_validate_min
    }

    if (!value.userType) {
      error.userType = language.busines_password;
      // Swal.fire({
      //   // title: language.busines_password,
      //   // buttonsStyling:
      // });
      // toast.error(`Please select ${language.business} or ${language.personal}`)
    }

    return error;
  }

  // sign up
  const handleSignUp = (e) => {
    e.preventDefault();
    if (formValue.userType) {
      navigate('/creataccount', { state: { userType: formValue.userType } })
    }
    else {
      // Swal.fire(`Please select ${language.business} or ${language.personal} `)
      toast.error(`Please select ${language.business} or ${language.personal} or Bookkeeper or Investor`)
    }
  }

  // login 
  const handleLogin = async (e) => {
    e.preventDefault();
    setFormError(validate(formValue));

    if (!formValue.userType) {
      toast.error(`Please select ${language.business} or ${language.personal}`);
      return
    }

    let loginData = {
      userType: formValue.userType,
      email: formValue.email,
      password: formValue.pass
    }

    if (!Object.keys(validate(formValue)).length) {
      const result = await HttpClient.requestData('login', 'POST', loginData)

      if (result && result.status) {
        setFormValue({
          email: '',
          pass: '',
          userType: ''
        })

        localStorage.setItem("userData", JSON.stringify(result.data));
        setUserData(result.data)

        toast.success(language.loggedin_success);
        // if (isBookKeeperMemo) {
        //   navigate('/bookkeeper-manager', { state: formValue.userType });
        // }
        // else {
          redirectToPage(formValue.userType)
        
        // }
      }
      else {
        if (result?.message?.trim() === 'Wrong email id.') {
          toast.error(language.wrong_email_id)
        }
        else if (result?.message.trim() === 'Wrong password.') {
          toast.error(language.wrong_password)
        } else if (result?.message.trim() === "Sorry your account is disabled") {
          toast.error(language.account_is_disabled)
        } else {
          toast.error(result?.message)
        }
      }
    }
  }

  const redirectToPage = (state) => {
    console.log("redirectFrom",redirectFrom)
    if(redirectFrom === 'accept_bookkeeper'){
      navigate(`/accept-bookkeeper/${paramId}`)
    }else if(redirectFrom === 'accept_investor') {
      navigate(`/accept-investor/${paramId}`)
    }else{
      navigate('/manager', { state: state });
    }
  }

  const passsVisibility = () => {
    if (visible) {
      setVisible(false)
    }
    else {
      setVisible(true)
    }
  }

  // google login
  const googleLogin = async (email, social) => {
    const sendData = {
      email: email,
      id: "",
      userType: formValue.userType,
      social: social
    }
    setGoogleEmail(email)
    const res = await HttpClient.requestData("google-login", "POST", sendData);
    // console.log("glogin", res)
    if (res && res?.status) {
      localStorage.setItem("userData", JSON.stringify({ token: res.data.token }));
      toast.success(language.loggedin_success);
      setUserData(res.data)
      redirectToPage(formValue.userType)
      // navigate('/manager', { state: formValue.userType });
    } else {
      navigate('/google-register', { state: formValue.userType });
      toast.error(language.email_not_exist_register);
    }
  }

  // google login function click
  const handleGoogleLogin = () => {

    if (!formValue.userType) {
      toast.error(`Please select ${language.business} or ${language.personal}`);
      return
    }

    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        // IdP data available using getAdditionalUserInfo(result)
        // ...

        // console.log("apple", token, user);
        googleLogin(user?.email, 'google');

      }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  }

  // apple login function
  const handleAppleleLogin = () => {

    if (!formValue.userType) {
      toast.error(`Please select ${language.business} or ${language.personal}`);
      return
    }

    const provider = new OAuthProvider('apple.com');
    // const auth = getAuth();
    signInWithPopup(auth, provider)
      .then((result) => {
        // The signed-in user info.
        const user = result.user;

        // Apple credential
        const credential = OAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        const idToken = credential.idToken;
        console.log('result apple', credential)
        googleLogin(user?.email, 'apple')
        // IdP data available using getAdditionalUserInfo(result)
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        console.log("errorapple", error)
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The credential that was used.
        const credential = OAuthProvider.credentialFromError(error);

        // ...
      });
  }

  // usertype is bookkeeper or not
  const isBookKeeperMemo = useMemo(() => {
    if (formValue.userType === "Bookkeeper") return true
    else return false
  }, [formValue.userType])

  // usertype is bookkeeper or not
  const isInvestorMemo = useMemo(() => {
    if (formValue.userType === "Investor") return true
    else return false
  }, [formValue.userType])



  useEffect(() => {
    setFormValue((prev) => {
      return (
        { email: '', pass: '', userType: '' }
      )
    });
  }, [language]);

  // useEffect(() => {
  //   // window.scrollTo(0, 0)
  //   // myref.current.scrollTo(0, 0);
  //   setTimeout(() => {
  //     const element = document.getElementById("content");
  //     console.log('element', element)
  //     element.scrollIntoView();
  //     console.log('scrolzl')
  //   }, 1000);
  // }, [])

  // console.log('formValue', formValue);
  // console.log('formError', formError);

  // const handleToat=()=>{
  // }

  return (
    <div style={{ cursor: 'default' }} ref={myref} id="content">
      {/* <button onClick={handleToat}>click</button> */}
      <section
        id="login_banner_area"
        style={{ backgroundImage: `url('${Loginbg}')` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
              <div className="banner-top-heading">
                <h4>{language.financial_visibility}</h4>
              </div>
              <div className="banner-second-heading">
                <h2>{language.loginPageHeading.toUpperCase()}</h2>
              </div>

              {/* select buttons  */}
              <div className="banner-btn">

                {/* business btn */}
                <button className="negocius-btn"
                  style={{
                    backgroundColor: `${formValue.userType === 'Business' ? '#102a3d' : 'transparent'}`,
                    color: `${formValue.userType === 'Business' ? '#fff' : '#3c3c3b'}`
                  }}
                  onClick={() => {
                    setFormValue((prev) => {
                      return {
                        ...prev,
                        userType: 'Business'
                      }
                    })
                  }}
                >
                  {language.business}
                </button>

                {/* personal btn*/}
                <button className="negocius-btn"
                  style={{
                    backgroundColor: `${formValue.userType === 'Personal' ? '#102a3d' : 'transparent'}`,
                    color: `${formValue.userType === 'Personal' ? '#fff' : '#3c3c3b'}`
                  }}

                  onClick={() => {
                    setFormValue((prev) => {
                      return {
                        ...prev,
                        userType: 'Personal'
                      }
                    })
                  }}
                >
                  {language.personal}
                </button>

                {/* bookkeeper btn */}
                <button className="negocius-btn"
                  style={{
                    backgroundColor: `${isBookKeeperMemo ? '#102a3d' : 'transparent'}`,
                    color: `${isBookKeeperMemo ? '#fff' : '#3c3c3b'}`
                  }}

                  onClick={() => {
                    setFormValue((prev) => {
                      return {
                        ...prev,
                        userType: 'Bookkeeper'
                      }
                    })
                  }}
                >
                  Bookkeeper
                </button>

                {/* Investor btn */}
                <button className="negocius-btn"
                  style={{
                    backgroundColor: `${isInvestorMemo ? '#102a3d' : 'transparent'}`,
                    color: `${isInvestorMemo ? '#fff' : '#3c3c3b'}`
                  }}

                  onClick={() => {
                    setFormValue((prev) => {
                      return {
                        ...prev,
                        userType: 'Investor'
                      }
                    })
                  }}
                >
                  Investor
                </button>

              </div>

            </div>
          </div>
        </div>
      </section>


      {/* form part */}
      <section>
        <div id="login-form">
          <div className="container">
            <form>
              {/* email */}
              <div className="form-group extra-input">
                <label htmlFor="exampleInputEmail1">
                  {language.welcome_back}
                </label>
                <input
                  type="email"
                  className="form-control"
                  id=""
                  aria-describedby=""
                  placeholder={language.email_placeholder}
                  name='email'
                  value={formValue.email}
                  onChange={handleChange}
                />
                <span className="text-danger">{formError.email}</span>
              </div>

              {/* password */}
              <div className="form-field">
                <div className="form-group">
                  <input
                    type={visible ? 'text' : 'password'}
                    className="form-control"
                    id=""
                    placeholder={language.password_placeholder}
                    name='pass'
                    value={formValue.pass}
                    onChange={handleChange}
                  />

                  <div onClick={passsVisibility} className="lock-icon">
                    {
                      visible ? (<AiFillEyeInvisible size={25} />) :
                        (<AiFillEye size={25} />)
                    }

                  </div>
                </div>
              </div>
              <span className="text-danger">{formError.pass}</span>
              <p>
                {language.forget_password_text}
                <Link to="/restorepassword">{language.click_here}</Link>
              </p>

              {/* login btn */}
              <div className="form-btn">
                <button
                  type="submit" className="login-btn"
                  onClick={(e) => {
                    handleLogin(e);
                  }}
                >
                  {language.loginButton}
                </button>
              </div>

            </form>
          </div>


          {/* {
            !isBookKeeperMemo && */}
          <div>
            {/* signup btn */}
            <div id="resgister-section" className="mt-5 mr-1">
              <div className="container">
                <div className="register_content">
                  <div>
                    <p className="register-me">{language.need_account}</p>
                    <button className="login-btn"
                      onClick={handleSignUp}
                    >
                      {language.homeBannerBtn1}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* login with google */}
            <div className="d-flex justify-content-center mt-5">
              <button
                className="btn"
                style={{ background: "linear-gradient(to bottom, #18465e 1%, #14384d 51%, #0f273a 100%)", color: "#fff" }}
                onClick={handleGoogleLogin}
              >
                <FcGoogle /> Continue with Google
              </button>
            </div>

            {/* login with Apple */}
            <div className="d-flex justify-content-center mt-2">
              <button
                className="btn"
                style={{ background: "linear-gradient(to bottom, #18465e 1%, #14384d 51%, #0f273a 100%)", color: "#fff" }}
                onClick={handleAppleleLogin}
              >
                <span style={{ color: "#A2AAAD" }}><AiFillApple /></span> Continue with Apple
              </button>
            </div>
          </div>
          {/* } */}

        </div>
      </section>



      {/* <div>
        <div className="d-flex justify-content-center mt-5 mb-5">
          <button
            className="btn"
            style={{ background: "linear-gradient(to bottom, #18465e 1%, #14384d 51%, #0f273a 100%)", color: "#fff" }}
            onClick={() => navigate('/bookkeeper-login')}
          >
            BookKeper Login
          </button>
        </div>
      </div> */}

      {/* <div id="resgister-section">
        <div className="container">
          <div className="register_content">
            <div>
              <Link to="/creataccount" className="register-me">
                {language.need_account}
              </Link>
              <button className="btn btn-primary">{language.signup}</button>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div id="social-section">
        <div className="container">
          <div className="social-icon">
            <div>
              <p>{language.login_with}</p>
              <ul className="p-0">
                <li>
                  <a
                  // onClick={handleGoogleLogin}
                  >
                    <i className="fa-brands fa-google-plus-g"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fa-brands fa-facebook-f"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default Login;
