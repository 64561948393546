import React, { useState } from 'react'
import Managebtn from '../../Component/Managebtn'
import InvoiceForm from './InvoiceForm'
import InvoiceForm2 from "./invoiceForm2/InvoiceForm2"
import BackArrow from '../../Component/BackArrow/BackArrow'

function CreateInvoice() {
    const [time, setTime] = useState("day")

    const getTime = (val) => {
        setTime(val)
    }
    return (
        <>

            <BackArrow />

            <Managebtn getTime={getTime} value={time} />

            {/* <InvoiceForm time={time} /> */}
            <InvoiceForm2 getTime={getTime} value={time} />

        </>
    )
}

export default CreateInvoice