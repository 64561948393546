import React from "react";
import { useLanguage } from "../../Providers/LanguageContext";
import SignUpButton from "./SignUpButton";
import iMg1 from "../../images/Proveedores de Capital_3.png";
import iMg2 from "../../images/Proveedores de Capital_1.png";
import iMg3 from "../../images/elemento de Proveedores de Capital e Inversores.png";
import iMg4 from "../../images/Proveedores de Capital_4.png";
import iMg5 from "../../images/Proveedores de Capital_5.png";
import iMg6 from "../../images/Proveedores de Capital_6.png";
import iMg7 from "../../images/Uso Personal y Doméstico_7 INGLES.png";
import bodyimage from "../../images/fondo.png";
import tick from "../../images/tick.png";
import { useNavigate } from "react-router-dom";
import SignUpButton2 from "./SignUpButton2";
const CapitalProviders = () => {
  const { language } = useLanguage();
  const navigate = useNavigate();

  return (
    <>
      {/* <section id='agriculture_sec'>
        <div className='container'>
          <div className='agriculture_head'>
            <div>
              <h3>{language.fisibility_key_feture}:</h3>
              <h2>{language.capital_providers_and_investors}</h2>
            </div>
          </div>
          <div className='agriculture_list'>
            <ul>
              <li><strong>{language.consolidation_finantial_data}: </strong>
                {language.fisi_console_financial}
              </li>
               <li><strong>{language.standardization_financial_reports}: </strong>
                {language.fisi_standardise_financial}
              </li>
              <li><strong>{language.analysis_finan_data}: </strong>
                {language.fisi_analysis_financial}
              </li>
             <li><strong>{language.real_time_finan_info}: </strong>
                {language.fisi_real_access}
              </li>
              <li><strong>{language.improve_risk_management}: </strong>
                {language.by_providing_investors}
              </li>
              <li><strong>{language.increased_efficiency}: </strong>
                {language.fisi_increase_effi}
              </li>
              <li><strong>{language.enhanced_due_diligence}: </strong>
                {language.fisi_enhance_dili}
              </li>
              <li><strong>{language.better_decision_making}: </strong>
                {language.fisi_timely_financial}
              </li>
              <li><strong>{language.improved_communication}: </strong>
                {language.fisi_improve_commu}
              </li>
              <li><strong>{language.cost_effective_sol}: </strong>
                {language.fisi_cost_sol}
              </li>
              
            </ul>
          </div>

          <SignUpButton />
        </div>
      </section> */}

      <section
        id="agriculture_sec"
        className="banking"
        style={{
          backgroundImage: `url('${bodyimage}')`,
          position: "relative",
          paddingBottom: "0",
        }}
      >
        <div className="container">
          <div className="agriculture_head" style={{ marginBottom: "0" }}>
            <h3 style={{ color: "#0e2537" }}>
              {language.fisi_key_benifits}
            </h3>
          </div>
          <div className="row mb-5">
            <div className="col-md-6 col-12">
              <div className="agriculture_headtext">
                <h5>{language.capital_provider}</h5>
                <div className="tickpara">
                  <div className="tick">
                    <img src={tick}></img>
                  </div>
                  <div>
                    <h4 style={{ color: "#333333" }}>{language.consolidation_finantial_data}</h4>
                    <p>
                       {language.fisi_console_financial}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12 m-auto">
              <div className="agriculture_img">
                <img src={iMg2} className="img-fluid" alt="" />
              </div>
            </div>
          </div>

          <div className="col-md-12 col-12 rightsideGap">
            <div className="agriculture_headtext">
              <div className="tickpara">
                <div className="tick">
                  <img src={tick}></img>
                </div>
                <div>
                  <h4 style={{ color: "#333333" }}>{language.standardization_financial_reports}</h4>
                  <p>
                    {language.fisi_standardise_financial}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-5" style={{ alignItems: "center" }}>
            <div className="col-md-8 col-12">
              <div className="agriculture_headtext">
                <div className="tickpara">
                  <div className="tick">
                    <img src={tick}></img>
                  </div>
                  <div>
                    <h4 style={{ color: "#333333" }}>
                      {" "}
                      {language.analysis_finan_data}
                    </h4>
                    <p>
                      {language.fisi_analysis_financial}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-12 m-auto">
              <div className="agriculture_img" style={{width:"60%"}}>
                <img src={iMg3} className="img-fluid" alt="" />
              </div>
            </div>
          </div>

          <div className="row mb-5" style={{ alignItems: "center" }}>
            <div className="col-md-4 col-12 m-auto">
              <div className="agriculture_img">
                <img src={iMg1} className="img-fluid" alt="" />
              </div>
            </div>
            <div className="col-md-8 col-12">
              <div className="agriculture_headtext">
                <div className="tickpara">
                  <div className="tick">
                    <img src={tick}></img>
                  </div>
                  <div>
                    <h4 style={{ color: "#333333" }}>
                      {language.real_time_finan_info}
                    </h4>
                    <p>
                      {language.fisi_real_access}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-8 col-12">
            <div className="agriculture_headtext">
              <div className="tickpara">
                <div className="tick">
                  <img src={tick}></img>
                </div>
                <div>
                  <h4 style={{ color: "#333333" }}>
                    {language.improve_risk_management}
                  </h4>
                  <p>
                    {language.by_providing_investors}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-5" style={{ alignItems: "center" }}>
            <div className="col-md-4 col-12 m-auto">
              <div className="agriculture_img">
                <img src={iMg4} className="img-fluid" alt="" />
              </div>
            </div>
            <div className="col-md-8 col-12">
              <div className="agriculture_headtext">
                <div className="tickpara">
                  <div className="tick">
                    <img src={tick}></img>
                  </div>
                  <div>
                    <h4 style={{ color: "#333333" }}>
                      {language.increased_efficiency}
                    </h4>
                    <p>
                      {language.fisi_increase_effi}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-5" style={{ alignItems: "center" }}>
            <div className="col-md-8 col-12">
              <div className="agriculture_headtext">
                <div className="tickpara">
                  <div className="tick">
                    <img src={tick}></img>
                  </div>
                  <div>
                    <h4 style={{ color: "#333333" }}>{language.enhanced_due_diligence}</h4>
                    <p>
                      {language.fisi_enhance_dili}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-12 m-auto">
              <div className="agriculture_img">
                <img src={iMg5} className="img-fluid" alt="" />
              </div>
            </div>
          </div>

          <div className="col-md-12 col-12 rightsideGap">
            <div className="agriculture_headtext">
              <div className="tickpara">
                <div className="tick">
                  <img src={tick}></img>
                </div>
                <div>
                  <h4 style={{ color: "#333333" }}>
                    {language.better_decision_making}
                  </h4>
                  <p>
                    {language.fisi_timely_financial}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-5" style={{ alignItems: "center" }}>
            <div className="col-md-8 col-12">
              <div className="agriculture_headtext">
                <div className="tickpara">
                  <div className="tick">
                    <img src={tick}></img>
                  </div>
                  <div>
                    <h4 style={{ color: "#333333" }}>
                      {language.improved_communication}
                    </h4>
                    <p>
                      {language.fisi_improve_commu}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-12 m-auto">
              <div className="agriculture_img">
                <img src={iMg6} className="img-fluid" alt="" />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-5 col-12 m-auto">
              <div className="agriculture_img">

                {/* <div
                  className="registrarAnddescargar"
                  style={{
                    transform: "translate(-50% , -155%)",
                    top: "44%",
                    left: "70%",
                    flexDirection: "column",
                  }}
                >
                  <button
                    className="registrar_Btn"
                    style={{
                      background:
                        " linear-gradient(to right, #18465d 1%,#0f283b 100%,#2989d8 100%)",
                      border: "0",
                      marginBottom: "20px",
                    }}
                    onClick={() => navigate('/login')}
                  >
                    {language.homeBannerBtn1}
                  </button>
                  <button className="descargar_Btn">{language.homeBannerBtn2}</button>
                </div> */}
                <SignUpButton2/>
              </div>
            </div>
            <div className="col-md-7 col-12">
              <div className="agriculture_headtext">
                <div className="tickpara">
                  <div className="tick">
                    <img src={tick}></img>
                  </div>
                  <div>
                    <h4 style={{ color: "#333333" }}>{language.cost_effective_sol}</h4>
                    <p>
                      {language.fisi_cost_sol}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CapitalProviders;
