import React from 'react'
import './BuildReports.css'
import BuildRepoStart from "../../../Component/BuildRepoStart";
import moment from 'moment';
import { useUserData } from '../../../Providers/UserWrapper';

function BuildReport() {
  const { profileData } = useUserData()

  console.log("profileDataxc", profileData?.name)
  return (
    <>
      <section>
        <div className="container">
          <div className="buildReprtTop">
            <ul>
              <li><span className='buildtopSpn'>Company Name: </span> {profileData && profileData?.companyName}</li>
              <li><span className='buildtopSpn'>Report Generated By:</span> {profileData?.name + " " + profileData?.surName}</li>
              <li><span className='buildtopSpn'>Report Generated On:</span> {moment().format("LL")}</li>
              {/* <li><span className='buildtopSpn'>Report Period:</span> 01.02.23 - 27.02.24</li> */}
            </ul>
          </div>
          <div className='buildRepoSection'>
            <BuildRepoStart />
          </div>
        </div>
      </section>
    </>
  )
}

export default BuildReport