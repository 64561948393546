import React, { useContext, useEffect, useRef, useState } from 'react'
import InvoiceList from '../../Component/invoice/InvoiceList'
import Managementtop from '../../Component/Managementtop'
import { useLanguage } from '../../Providers/LanguageContext'
import { SpeechContext } from '../../Providers/SpeechWrapper'
import HttpClient from '../../utils/HttpClient'
import view_invoice_en1 from "../../Audio_files/V. Invoice Manager Audio/V. b. View Invoices User Selected Option/v_view_invoices_audio_a.mp3";
import view_invoice_es1 from "../../spanish_audio_files/V. Invoice Manager Audio/V. b. View Invoices User Selected Option/v_view_invoices_audio_a_es.mp3";
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import ViewInvoiceForm2 from './invoiceForm2/NormalInvoice/NormalInvList'
import toast from 'react-hot-toast'
import { useUserData } from '../../Providers/UserWrapper'
import SatNormalInvList from './invoiceForm2/SatNormalInvoice/SatNormalInvList'

function ViewInvoice() {

    const { language, currentLanguage } = useLanguage()
    const { currAudio, setCurrAudio, playing } = useContext(SpeechContext);
    const [isCleared, setIsCleared] = useState(false)
    const { setIsLoading } = useUserData();
    const timerRef = useRef()
    const searchBoxRef = useRef()
    const [invoiceList, setInvoiceList] = useState([]);
    const navigate = useNavigate();

    const selectRef = useRef();

    const [normalInvData, steNormalInvData] = useState([]);
    const [satNormalInvoiceData, setSatNormalInvoiceData] = useState([]);

    // normal invoice get
    const getAllNormalInvData = async () => {
        setIsLoading(true);
        const res = await HttpClient.requestData("view-invoice-web", "GET", {});
        console.log("resWebNorm", res);
        if (res && res?.status) {
            let arr = res?.data.filter((item) => item.customerName?.includes(filters.customer))
            steNormalInvData(arr)
            // steNormalInvData(res?.data);
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    }

    // sat normal invoice get
    const getSatNormalInvoice = async () => {
        setIsLoading(true);
        const res = await HttpClient.requestData("view-invoice-web-sat", "GET", {});
        console.log("resWebNormSats", res);
        if (res && res?.status) {
            let arr = res?.data.filter((item) => item.RecipientName?.includes(filters.customer))
            setSatNormalInvoiceData(arr)
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    }

    const clearFilters = () => {
        setFilters({
            date: "",
            customer: "",
            classification: ""
        })
        selectRef.current.selectedIndex = "0"
        setIsCleared(true)
        viewInvoices2()
        toast.success(language.clear_filter)
    }


    const [filters, setFilters] = useState({
        date: "",
        customer: "",
        classification: ""
    })
    console.log('filterinvv', filters)

    const getVendorName = (e) => {
        // console.log(e.target.value)
        const value = e.target.value;
        if (timerRef.current) {
            clearTimeout(timerRef.current)
        }
        timerRef.current = setTimeout(() => {
            setFilters(prev => ({ ...prev, customer: value }))
        }, 500)
    }

    const getCreationDate = (value) => {
        console.log(value, "Creation date")
        setFilters(prev => ({ ...prev, date: value }))
    }

    // fetch old/first invoce
    async function viewInvoices() {
        const sendData = {
            date: "",
            customer: "",
            classification: ""
        }
        const res = await HttpClient.requestData("view-invoice", "POST", sendData);
        console.log('resfilt', res);
        if (res && res.status) {
            // console.log('resfilt', res);
            // const arr1 = res.data.filter((item) => item.customerName.toLowerCase()?.includes(filters.vendor.toLowerCase()))
            const arr1 = res.data.filter((item) => item.customerName.toLowerCase()?.includes(filters.customer.toLowerCase()))
            let arr2 = removeDuplicateFromArrayOfObject(arr1)
            setInvoiceList(arr2);
        } else {
            // console.log("error", res)
        }
    }

    const removeDuplicateFromArrayOfObject = (arr) => {

        const unique = arr.filter((obj, index) => {
            return index === arr.findIndex(o => obj.customerName === o.customerName);
        });
        return unique
    }

    async function viewInvoices2() {
        const res = await HttpClient.requestData("view-invoice", "POST", {
            date: "",
            customer: "",
            classification: ""
        });
        if (res && res.status) {
            setInvoiceList(res.data)
        } else {
            console.log("error", res)
        }
    }


    // classification filters
    const handleFilterClassChange = async (e) => {
        const val = e.target.value;

        if (val === 'Z-A') {
            let arr = invoiceList;
            sortByNamesVendor(arr);
            let arr2 = arr.reverse();
            setInvoiceList([...arr2]);

            let normArr = normalInvData;
            sortByNamesVendorNormal(normArr);
            let normArr2 = normArr.reverse();
            steNormalInvData([...normArr2]);

            let satNormArr = satNormalInvoiceData;
            sortByNamesVendorSatNormal(satNormArr);
            let satNormArr2 = satNormArr.reverse();
            setSatNormalInvoiceData([...satNormArr2]);
        }
        else if (val === 'A-Z') {
            let arr = invoiceList;
            sortByNamesVendor(arr);
            setInvoiceList([...arr]);

            let normArr = normalInvData;
            sortByNamesVendorNormal(normArr);
            steNormalInvData([...normArr]);

            let satNormArr = satNormalInvoiceData;
            sortByNamesVendorSatNormal(satNormArr);
            setSatNormalInvoiceData([...satNormArr]);
        }
        else if (val === 'Recent') {
            let arr = invoiceList;
            sortByDate(arr);
            let arr2 = arr.reverse()
            setInvoiceList([...arr2]);

            let normArr = normalInvData;
            sortByDateNormal(normArr);
            let normArr2 = normArr.reverse()
            setInvoiceList([...normArr2]);

            let satNormArr = satNormalInvoiceData;
            sortByDateSatNormal(satNormArr);
            let satNormArr2 = satNormArr.reverse()
            setSatNormalInvoiceData([...satNormArr2]);
        }
        else if (val === 'oldest') {
            let arr = invoiceList;
            sortByDate(arr);
            setInvoiceList([...arr]);

            let normArr = normalInvData;
            sortByDateNormal(normArr);
            setInvoiceList([...normArr]);

            let satNormArr = satNormalInvoiceData;
            sortByDateSatNormal(satNormArr);
            setSatNormalInvoiceData([...satNormArr]);
        }
    }

    // sorting an array of object by date
    const sortByDate = (val) => {
        console.log('sortval')
        val.sort((a, b) => {
            let da = new Date(a.date),
                db = new Date(b.date);
            return da - db;
        });
        // console.log('sortval', val)
        return val
    }

    // sorting an array of object in ascending
    const sortByNamesVendor = (val) => {
        val.sort((a, b) => {
            let fa = a.customerName?.toLowerCase(),
                fb = b.customerName?.toLowerCase();
            if (fa < fb) {
                return -1;
            }
            if (fa > fb) {
                return 1;
            }
            return 0;
        });
    }

    // sort normal a-z
    const sortByNamesVendorNormal = (val) => {
        val.sort((a, b) => {
            let fa = a.customerName?.toLowerCase(),
                fb = b.customerName?.toLowerCase();
            if (fa < fb) {
                return -1;
            }
            if (fa > fb) {
                return 1;
            }
            return 0;
        });
    }

    // sort normal a-z date
    const sortByDateNormal = (val) => {
        console.log('sortval')
        val.sort((a, b) => {
            let da = new Date(a.invoiceDate),
                db = new Date(b.invoiceDate);
            return da - db;
        });
        // console.log('sortval', val)
        return val
    }

    // sort sat normal a-z
    const sortByNamesVendorSatNormal = (val) => {
        val.sort((a, b) => {
            let fa = a.RecipientName?.toLowerCase(),
                fb = b.RecipientName?.toLowerCase();
            if (fa < fb) {
                return -1;
            }
            if (fa > fb) {
                return 1;
            }
            return 0;
        });
    }

    // sort sat normal a-z date
    const sortByDateSatNormal = (val) => {
        // console.log('sortval')
        val.sort((a, b) => {
            let da = new Date(a.createdOn),
                db = new Date(b.createdOn);
            return da - db;
        });
        // console.log('sortval', val)
        return val
    }




    useEffect(() => {
        viewInvoices();
        getAllNormalInvData();
        getSatNormalInvoice();
    }, [filters])


    useEffect(() => {
        if (playing) {
            if (currAudio) {
                if (!currAudio.paused) {
                    currAudio.pause()
                }
            }
            if (currentLanguage === "eng") {
                setCurrAudio(new Audio(view_invoice_en1))
            } else {
                setCurrAudio(new Audio(view_invoice_es1))
            }
        } else {
            if (currAudio) {
                if (!currAudio.paused) {
                    currAudio.pause()
                    setCurrAudio(null)
                }
            }
        }

        return () => {
            if (currAudio && !currAudio.paused) {
                currAudio.pause()
            }
        }
    }, [playing, currentLanguage])

    return (
        <div className='container'>
            <div
                className="back-arrow-left"
                onClick={() => {
                    navigate(-1)
                    console.log("object")
                }
                }
            >
                <AiOutlineArrowLeft />
            </div>

            <Managementtop
                title={language.search_invoice}
                isCleared={isCleared}
                setIsCleared={setIsCleared}
                getVendorName={getVendorName}
                getCreationDate={getCreationDate}
                searchBoxRef={searchBoxRef}

            />
            {/* <Dropbox text={language.select_project} transactionFilter={filters}
             getValue={getProjectVal} data={} /> */}

            {/* invouce 2 / old invoice */}


            {/* classification */}
            <div id='clafication'>
                <div className='container'>
                    <div className='table-top'>
                        <div className='top-left'>
                            <form>
                                <div className='top-select'>
                                    <div class="form-group">
                                        <select
                                            class="form-control"
                                            id="exampleFormControlSelect1"
                                            ref={selectRef}
                                            onChange={handleFilterClassChange}
                                        >
                                            <option>{language.classification}</option>
                                            <option value="Recent">{language.most_recent}</option>
                                            <option value="oldest">{language.oldest}</option>
                                            <option value="A-Z">Name A-Z</option>
                                            <option value="Z-A">Name Z-A</option>
                                        </select>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className='top-right'>
                            <span onClick={clearFilters} className='todo-btn' id="filterbtn">{language.all_results}</span>
                        </div>
                    </div>
                </div>
            </div>

            <InvoiceList
                invoices={invoiceList}
                setFilters={setFilters}
                setIsCleared={setIsCleared}
                fetchInvoices={viewInvoices2}
                isCleared={isCleared}
                handleFilterClassChange={handleFilterClassChange}
                filters={filters}
            />
            {
                invoiceList.length === 0 &&
                <div className='d-flex justify-content-center m-3'>
                    <h4>{language.no_data}</h4>
                </div>
            }

            {/* 2nd / normal invoice */}
            <ViewInvoiceForm2
                filters={filters}
                normalInvData={normalInvData}
            />

            {/* sat Normal Invoice */}
            <SatNormalInvList
                filters={filters}
                satNormalInvoiceData={satNormalInvoiceData}
            />
        </div>
    )
}

export default ViewInvoice;