import React, { useEffect, useState } from "react";
import "./DocumentManager.css";
import ImportDocument from './ImportDocument';
import Slider from "react-slick";
import DocumentVault from "./DocumentVault";
import { useLocation } from "react-router-dom";
import HttpClient from "../../utils/HttpClient";



function Index() {
    const location = useLocation()
    // for sliders
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: location.state.slideNo
    };


    const [docData, setDocData] = useState([]);


    // get all document
    const getAllDocument = async () => {
        const res = await HttpClient.requestData("view/count/selfservice-document", "GET", {})
        if (res && res?.status) {
            setDocData(res?.data)
        }
    }


    useEffect(() => {
        getAllDocument()
    }, [])


    return (
        <>
            <div className="DocumentManager_mainwrap">

                <Slider {...settings}>

                    <div>
                        <ImportDocument
                            getAllDocument={getAllDocument}
                        />
                    </div>

                    <div>
                        <DocumentVault
                            docData={docData}
                        />
                    </div>

                </Slider>

            </div>
        </>
    )
}

export default Index