import React from "react";
import { useLanguage } from "../../Providers/LanguageContext";
import SignUpButton from "./SignUpButton";
import iMg1 from "../../images/Bancos y el Sector Bancario_3-84.png";
import iMg2 from "../../images/Bancos y el Sector Bancario_1-82.png";
import iMg3 from "../../images/Bancos y el Sector Bancario_2-83.png";
import iMg4 from "../../images/Bancos y el Sector Bancario_4-85.png";
import iMg5 from "../../images/Bancos y el Sector Bancario_5-86.png";
import iMg6 from "../../images/Bancos y el Sector Bancario_6-87.png";
import iMg7 from "../../images/Bancos y el Sector Bancario-88.png";
import bodyimage from "../../images/fondo.png";
import tick from "../../images/tick.png";
import { useNavigate } from "react-router-dom";
import { useUserData } from "../../Providers/UserWrapper";
const BankIndustry = () => {
  const { language } = useLanguage();
  const navigate = useNavigate();
  const { logout, isLogin } = useUserData();

  return (
    <>
      {/* <section id='agriculture_sec'>
        <div className='container'>
          <div className='agriculture_head'>
            <div>
              <h3>{language.fisibility_key_feture}:</h3>
              <h2>{language.banks_and_banking_industry}</h2>
            </div>
          </div>
          <div className='agriculture_list'>
            <ul>
              <li><strong>{language.enhance_risk_management}: </strong>
                {language.fisi_compre_data}
              </li>
             <li><strong>{language.improved_effi}: </strong>
                {language.fisi_plat_stand}
              </li>
                <li><strong>{language.increased_trans}: </strong>
                {language.fisi_tran_finan}
              </li>
              <li><strong>{language.streamline_ac}: </strong>
                {language.fisi_digi_chear}
              </li>
              <li><strong>{language.access_compre_finance}: </strong>
                {language.encorage_busi_data}
              </li>
              <li><strong>{language.improve_port_manage}: </strong>
                {language.fisi_busi_invest}
              </li>
              <li><strong>{language.inc_cust_sat}: </strong>
                {language.prov_busi_comp}
              </li>
              <li><strong>{language.red_admin_cost}: </strong>
                {language.fisi_plat_auto}
              </li>
              <li><strong>{language.better_deci_making}: </strong>
                {language.fisi_plat_prov_bank}
              </li>
              <li><strong>{language.imp_rge_comp}: </strong>
                {language.fisi_plat_design}
              </li>
              
            </ul>
          </div>

          <SignUpButton />
        </div>
      </section> */}

      <section
        id="agriculture_sec"
        className="banking"
        style={{ backgroundImage: `url('${bodyimage}')`, position: "relative" }}
      >
        <div className="container">
          <div className="agriculture_head" style={{ marginBottom: "0" }}>
            <h3 style={{ color: "#0e2537" }}>
              {language.fisi_key_benifits}
            </h3>
          </div>
          <div className="row mb-5">
            <div className="col-md-6 col-12">
              <div className="agriculture_headtext">
                <h5>
                  {language.bank_and_industry}
                </h5>
                <div className="tickpara">
                  <div className="tick">
                    <img src={tick}></img>
                  </div>
                  <div>
                    <h4 style={{ color: "#333333" }}>
                      {language.enhance_risk_management}
                    </h4>
                    <p>
                      {language.fisi_compre_data}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-12 m-auto">
              <div className="agriculture_img">
                <img src={iMg2} className="img-fluid" alt="" />
              </div>
            </div>
          </div>

          <div className="col-md-12 col-12 rightsideGap">
            <div className="agriculture_headtext">
              <div className="tickpara">
                <div className="tick">
                  <img src={tick}></img>
                </div>
                <div>
                  <h4 style={{ color: "#333333" }}>{language.improved_effi}</h4>
                  <p>
                    {language.fisi_plat_stand}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-5">
            <div className="col-md-7 col-12">
              <div className="agriculture_headtext">
                <div className="tickpara">
                  <div className="tick">
                    <img src={tick}></img>
                  </div>
                  <div>
                    <h4 style={{ color: "#333333" }}>{language.increased_trans}</h4>
                    <p>
                      {language.fisi_tran_finan}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5 col-12 m-auto">
              <div className="agriculture_img">
                <img src={iMg3} className="img-fluid" alt="" />
              </div>
            </div>
          </div>

          <div className="row mb-5">
            <div className="col-md-4 col-12 m-auto">
              <div className="agriculture_img">
                <img src={iMg1} className="img-fluid" alt="" />
              </div>
            </div>
            <div className="col-md-8 col-12">
              <div className="agriculture_headtext">
                <div className="tickpara">
                  <div className="tick">
                    <img src={tick}></img>
                  </div>
                  <div>
                    <h4 style={{ color: "#333333" }}>
                      {language.streamline_ac}
                    </h4>
                    <p>
                      {language.fisi_digi_chear}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-8 col-12">
            <div className="agriculture_headtext">
              <div className="tickpara">
                <div className="tick">
                  <img src={tick}></img>
                </div>
                <div>
                  <h4 style={{ color: "#333333" }}>
                    {language.access_compre_finance}
                  </h4>
                  <p>
                    {language.encorage_busi_data}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-5">
            <div className="col-md-4 col-12 m-auto">
              <div className="agriculture_img">
                <img src={iMg4} className="img-fluid" alt="" />
              </div>
            </div>
            <div className="col-md-8 col-12">
              <div className="agriculture_headtext">
                <div className="tickpara">
                  <div className="tick">
                    <img src={tick}></img>
                  </div>
                  <div>
                    <h4 style={{ color: "#333333" }}>
                      {language.improve_port_manage}
                    </h4>
                    <p>
                      {language.fisi_busi_invest}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-md-8 col-12">
              <div className="agriculture_headtext">
                <div className="tickpara">
                  <div className="tick">
                    <img src={tick}></img>
                  </div>
                  <div>
                    <h4 style={{ color: "#333333" }}>
                      {language.inc_cust_sat}
                    </h4>
                    <p>
                      {language.prov_busi_comp}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-12 m-auto">
              <div className="agriculture_img">
                <img src={iMg5} className="img-fluid" alt="" />
              </div>
            </div>
          </div>

          <div className="col-md-12 col-12 rightsideGap">
            <div className="agriculture_headtext">
              <div className="tickpara">
                <div className="tick">
                  <img src={tick}></img>
                </div>
                <div>
                  <h4 style={{ color: "#333333" }}>
                    {language.red_admin_cost}
                  </h4>
                  <p>
                    {language.fisi_plat_auto}
                  </p>
                </div>
              </div>
            </div>
          </div>


          <div className="row mb-5">
            <div className="col-md-8 col-12">
              <div className="agriculture_headtext">
                <div className="tickpara">
                  <div className="tick">
                    <img src={tick}></img>
                  </div>
                  <div>
                    <h4 style={{ color: "#333333" }}>
                      {language.better_deci_making}
                    </h4>
                    <p>
                      {language.fisi_plat_prov_bank}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-12 m-auto">
              <div className="agriculture_img">
                <img src={iMg6} className="img-fluid" alt="" />
              </div>
            </div>
          </div>

          <div className="row mb-5">
            <div className="col-md-4 col-12 m-auto">
              <div className="agriculture_img">
                <img src={iMg7} className="img-fluid" alt="" />
                <div className='registrarAnddescargar' style={{ transform: "translate(-50% , -50%)", top: "64%", }}>

                  <button
                    onClick={() => navigate('/price')}
                    className='registrar_Btn'
                    style={{ background: "linear-gradient(to right, rgb(24, 70, 93) 1%, rgb(15, 40, 59) 100%, rgb(41, 137, 216) 100%); border: 0px; margin-bottom: 20px", border: "0" }}
                  >
                    {language.homeBannerBtn1}
                  </button>

                  <a href='https://fisibilitylite.com/app_api/share_url.html' target="_blank">
                    <button className='descargar_Btn' style={{ borderColor: "#102c3f", color: "#102c3f", backgroundColor: "#fff" }}>{language.homeBannerBtn2}</button>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-8 col-12">
              <div className="agriculture_headtext">
                <div className="tickpara">
                  <div className="tick">
                    <img src={tick}></img>
                  </div>
                  <div>
                    <h4 style={{ color: "#333333" }}>
                      {language.imp_rge_comp}
                    </h4>
                    <p>
                      {language.fisi_plat_design}
                    </p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
    </>
  );
};

export default BankIndustry;
