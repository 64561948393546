import React, { useState, useEffect, useContext, useMemo } from "react";
import { useLanguage } from "../../Providers/LanguageContext";
import { SpeechContext } from "../../Providers/SpeechWrapper";
import view_reports_en from "../../Audio_files/View Reports/view_reports_audio_a.mp3";
import view_reports_es from "../../spanish_audio_files/View Reports Audio/view_reports_audio_a_es.mp3";
import CustomDateBtn from "../../Component/CustomDateBtn";
import CustomDateReportBtn from "./CustomDateReportBtn";
import FinancialOverviewTable from "./FinancialOverviewTable";
import { useUserData } from "../../Providers/UserWrapper";
import ReportInputSection from "./ReportInputSection";
import ProfitabilityTable from "./ProfitabilityTable";
import BudgetRevenueTable from "./BudgetRevenueTable";
import BudgetExpenceTable from "./BudgetExpenceTable";
import BudgetProfitTable from "./BudgetProfitTable";
import SendReportModal from "./SendReportModal";
import HttpClient from "../../utils/HttpClient";
import { toast } from "react-hot-toast";
import TransactionTable from "./TransactionTable";
import ExpenseDetailTable from "./ExpenseDetailTable";
import ScheduleModal from "./ScheduleModal";
import { useLocation } from "react-router-dom";
import ViewIncomeStatement from "./ViewIncomeStatement";
import ViewBalanceSheet from "./ViewBalanceSheet";
import ViewTrialBalance from "./ViewTrialBalance";
import ViewCapitalBalance from "./ViewCapitalBalance";
import ViewFinancialReport from "./ViewFinancialReport";
import ViewBudgetForcast from "./ViewBudgetForcast";
import ViewAgingReport from "./ViewAgingReport";
import ViewBussinessFS from "./ViewBussinessFS";
import View13WeekCFS from "./View13WeekCFS";
import View12MonthCFS from "./View12MonthCFS";


function ViewReports() {
  const { language, currentLanguage } = useLanguage();
  const { profileData, setRepSelectCon, setIsLoading, isBusiness } = useUserData();
  const location = useLocation();

  // console.log('profileDataReport', profileData)
  const { currAudio, setCurrAudio, playing } = useContext(SpeechContext);
  const [reportRadio, setReportRadio] = useState("out");
  const [reportSelect, setReportSelect] = useState("Select");
  const [reportDate, setReportDate] = useState({
    startData: "",
    endDate: ""
  });

  const [showSchedModul, setShowSchedModal] = useState(false);
  const [showSendModal, setShowModal] = useState(false);
  const [sendName, setSendName] = useState("");
  const [schedName, setSchedName] = useState("");
  const [weekName, setWeekName] = useState({});
  const [monthName, setMonthName] = useState("");
  const [isLocationData, setIsLocationData] = useState(false);

  const [pdfLoading, setPdfLoading] = useState(false);

  const [sendForm, setSendForm] = useState({
    email: "",
    message: ""
  })
  const [pdfData, setPdfData] = useState("");
  const [hoverLoading, setHoverLoading] = useState(false);
  const [bildRepData, setBuildRepData] = useState("");

  console.log("bildRepData", bildRepData)

  useEffect(() => {
    setSendForm((prev) => { return { ...prev, email: profileData && profileData.email } })
  }, [profileData])

  // getting subject of all report
  const selectNameMemo = useMemo(() => {
    switch (reportSelect) {
      case "Profitability": return isBusiness ? language.profitability : language.margin_report
      case "Revenue": return isBusiness ? language.act_budget_rev : language.act_budget_income_rep
      case "Expense": return language.act_budget_expense
      case "Profit": return isBusiness ? language.act_budget_profit : language.act_budget_margin_rep
      case "Financial": return language.general_fian_overview
      case "Transaction": return language.transaction_detail_report
      case "ExpenseDetail": return language.expense_detail_report

      case "IncomeStatement": return language.income_statement_profit_los
      case "BalanceSheet": return language.balance_sheet
      case "TrialBalance": return language.trial_balance
      case "Capital": return language.cap_table_owner

      default: return "Select"
    }
  }, [reportSelect, currentLanguage]);


  // getting types of all report
  const reportTypeMemo = useMemo(() => {
    switch (reportSelect) {
      case "Profitability": return "Profitibility"
      case "Revenue": return "Revenue"
      case "Expense": return "Expense"
      case "Profit": return "Profit"
      case "Financial": return "OutOfBox"
      case "Transaction": return "Transaction"
      case "ExpenseDetail": return "Self-Expense"

      case "IncomeStatement": return "ProfitLoss"
      case "BalanceSheet": return "BalanceSheet"
      case "TrialBalance": return "TrialBalance"
      case "Capital": return "CapitalizationTable"
      default: return "Select"
    }
  }, [reportSelect]);


  const ScheduleTypeMemo = useMemo(() => {
    switch (reportSelect) {
      case "Profitability": return "Profitibility"
      case "Revenue": return "Revenue"
      case "Expense": return "Expense"
      case "Profit": return "Profit"
      case "Financial": return "OutOfBox"
      case "Transaction": return "Transaction"
      case "ExpenseDetail": return "TransactionExpense"
      default: return "Select"
    }
  }, [reportSelect])

  const schedNameMemo = useMemo(() => {
    switch (schedName) {
      case "daily": return language.daily
      case "weekly": return language.weekly
      case "monthly": return language.monthly
      default: return ""
    }
  }, [schedName]);

  // console.log('schedNameMemo', schedNameMemo)

  // download pdf
  const getPdfDownload = async () => {
    const sendData = {
      startData: reportDate.startData,
      endDate: reportDate.endDate,
      subject: selectNameMemo
    }
    console.log("reportSelectdd", reportSelect)

    if (reportSelect === "Revenue") {
      setPdfLoading(true);
      const res = await HttpClient.requestData(currentLanguage === "eng" ? "self-service-revenue-pdf" : "self-service-revenue-pdf-es", "POST", sendData);
      // console.log('downPdfRev', res)
      if (res && res.status) {
        setPdfData(res.data);
        setPdfLoading(false);
      }
    }
    else if (reportSelect === "Financial") {
      setPdfLoading(true);
      const res = await HttpClient.requestData(currentLanguage === "eng" ? "out-of-box-pdf" : "out-of-box-pdf-es", "POST", sendData);
      // console.log('downPdfRevFinan', res)
      if (res && res.status) {
        setPdfData(res.data)
        setPdfLoading(false);
      }
    }
    else if (reportSelect === "Expense") {
      setPdfLoading(true);
      const res = await HttpClient.requestData(currentLanguage === "eng" ? "self-service-expense-pdf" : "self-service-expense-pdf-es", "POST", sendData);
      // console.log('downPdfRev', res)
      if (res && res.status) {
        setPdfData(res.data);
        setPdfLoading(false);
      }
    }
    else if (reportSelect === "Profit") {
      setPdfLoading(true);
      const res = await HttpClient.requestData(currentLanguage === "eng" ? "self-service-profit-pdf" : "self-service-profit-pdf-es", "POST", sendData);
      // console.log('downPdfRev', res)
      if (res && res.status) {
        setPdfData(res.data);
        setPdfLoading(false);
      }
    }
    else if (reportSelect === "Profitability") {
      setPdfLoading(true);
      const res = await HttpClient.requestData(currentLanguage === "eng" ? "profitability-pdf" : "profitability-pdf-es", "POST", sendData);
      // console.log('downPdfRev', res)
      if (res && res.status) {
        setPdfData(res.data);
        setPdfLoading(false);
      }
    }
    else if (reportSelect === "Transaction") {
      const data = {
        startDate: reportDate.startData,
        endDate: reportDate.endDate,
        subject: selectNameMemo
      }
      setPdfLoading(true);
      const res = await HttpClient.requestData(currentLanguage === "eng" ? "transaction-pdf" : "transaction-pdf-es", "POST", data);
      // console.log('downPdfRev', res)
      if (res && res.status) {
        setPdfData(res.data);
        setPdfLoading(false);
      }
    }
    else if (reportSelect === "ExpenseDetail") {
      const data = {
        startDate: reportDate.startData,
        endDate: reportDate.endDate,
        subject: selectNameMemo
      }
      setPdfLoading(true);
      const res = await HttpClient.requestData(currentLanguage === "eng" ? "expense-pdf" : "expense-pdf-es", "POST", data);
      // console.log('downPdfRev', res)
      if (res && res.status) {
        setPdfData(res.data);
        setPdfLoading(false);
      }
    }
    // else if (reportSelect === "IncomeStatement") {
    //   // setPdfLoading(true);
    //   const sendData = {}
    //   const res = await HttpClient.requestData(currentLanguage === "eng" ? "reportbuilder-add-profitloss" : "reportbuilder-add-profitloss", "POST", sendData);
    //   // // console.log('downPdfRev', res)
    //   // if (res && res.status) {
    //   //   setPdfData(res.data);
    //   //   setPdfLoading(false);
    //   // }
    // }
    else {
      setPdfData("")
    }
  }


  useEffect(() => {
    getPdfDownload()
  }, [reportSelect, currentLanguage, reportDate])


  // send mail
  const handleSend = async () => {
    if (!sendForm.email) {
      toast.error(language.email_valid)
      return;
    }
    const sendData = {
      startData: reportDate.startData,
      endDate: reportDate.endDate,
      email: sendForm.email,
      emailTemplet: sendForm.message,
      subject: selectNameMemo
    }

    // console.log('sendData', sendData)
    setShowModal(false);
    const sentMsg = language.mail_sent_success;

    if (reportSelect === "Financial") {
      // console.log('finanSendData', sendData)
      setIsLoading(true)
      const res = await HttpClient.requestData(currentLanguage === "eng" ? "out-of-box-send" : "out-of-box-send-es", "POST", sendData);
      // console.log('sendRes', res, sendData)
      if (res && res.status) {
        setIsLoading(false)
        toast.success(sentMsg)
      } else {
        setIsLoading(false)
        toast.error("Error")
      }
    }
    else if (reportSelect === "Revenue") {
      setIsLoading(true)
      const res = await HttpClient.requestData(currentLanguage === "eng" ? "self-service-revenue-send" : "self-service-revenue-send-es", "POST", sendData);
      // console.log('sendRes', res, sendData)
      if (res && res.status) {
        setIsLoading(false)
        toast.success(sentMsg)
      } else {
        setIsLoading(false)
        toast.error("Error")
      }
    }
    else if (reportSelect === "Expense") {
      setIsLoading(true)
      const res = await HttpClient.requestData(currentLanguage === "eng" ? "self-service-expense-send" : "self-service-expense-send-es", "POST", sendData);
      // console.log('sendRes', res, sendData)
      if (res && res.status) {
        setIsLoading(false)
        toast.success(sentMsg)
      } else {
        setIsLoading(false)
        toast.error("Error")
      }
    }
    else if (reportSelect === "Profit") {
      setIsLoading(true)
      const res = await HttpClient.requestData(currentLanguage === "eng" ? "self-service-profit-send" : "self-service-profit-send-es", "POST", sendData);
      // console.log('sendResProfit', res, sendData)
      if (res && res.status) {
        setIsLoading(false)
        toast.success(sentMsg)
      } else {
        setIsLoading(false)
        toast.error("Error")
      }
    }
    else if (reportSelect === "Profitability") {
      setIsLoading(true)
      const res = await HttpClient.requestData(currentLanguage === "eng" ? "profitability-send" : "profitability-send-es", "POST", sendData);
      // console.log('sendRes', res, sendData)
      if (res && res.status) {
        setIsLoading(false)
        toast.success(sentMsg)
      } else {
        setIsLoading(false)
        toast.error("Error")
      }
    }
    else if (reportSelect === "Transaction") {
      const data = {
        startDate: reportDate.startData,
        endDate: reportDate.endDate,
        email: sendForm.email,
        emailTemplet: sendForm.message,
        subject: selectNameMemo
      }
      setIsLoading(true)
      const res = await HttpClient.requestData(currentLanguage === "eng" ? "transaction-send" : "transaction-send-es", "POST", data);
      // console.log('sendRes', res, sendData)
      if (res && res.status) {
        setIsLoading(false)
        toast.success(sentMsg)
      } else {
        setIsLoading(false)
        toast.error("Error")
      }
    } else if (reportSelect === "ExpenseDetail") {
      const data = {
        startDate: reportDate.startData,
        endDate: reportDate.endDate,
        email: sendForm.email,
        emailTemplet: sendForm.message,
        subject: selectNameMemo
      }
      setIsLoading(true)
      const res = await HttpClient.requestData(currentLanguage === "eng" ? "expense-send" : "expense-send-es", "POST", data);
      // console.log('sendRes', res, sendData)
      if (res && res.status) {
        setIsLoading(false)
        toast.success(sentMsg)
      } else {
        setIsLoading(false)
        toast.error("Error")
      }
    }
    else if (reportSelect === "IncomeStatement") {
      const data = {
        ...bildRepData,
        email: sendForm?.email,
        language: currentLanguage === "eng" ? "EN" : "ES"
      }
      setIsLoading(true)
      const res = await HttpClient.requestData("reportbuilder/pdf/profitloss", "POST", data);
      // console.log('sendRes', res, sendData)
      if (res && res.status) {
        setIsLoading(false)
        toast.success(sentMsg)
      } else {
        setIsLoading(false)
        toast.error("Error")
      }
    }
    else if (reportSelect === "BalanceSheet") {
      const data = {
        ...bildRepData,
        email: sendForm?.email,
        language: currentLanguage === "eng" ? "EN" : "ES"
      }
      setIsLoading(true)
      const res = await HttpClient.requestData("reportbuilder/pdf/balancesheet", "POST", data);
      // console.log('sendRes', res, sendData)
      if (res && res.status) {
        setIsLoading(false)
        toast.success(sentMsg)
      } else {
        setIsLoading(false)
        toast.error("Error")
      }
    }
    else if (reportSelect === "TrialBalance") {
      const data = {
        ...bildRepData,
        email: sendForm?.email,
        language: currentLanguage === "eng" ? "EN" : "ES"
      }
      setIsLoading(true)
      const res = await HttpClient.requestData("reportbuilder/pdf/trialbalance", "POST", data);
      // console.log('sendRes', res, sendData)
      if (res && res.status) {
        setIsLoading(false)
        toast.success(sentMsg)
      } else {
        setIsLoading(false)
        toast.error("Error")
      }
    }
    else if (reportSelect === "Capital") {
      const data = {
        ...bildRepData,
        email: sendForm?.email,
        language: currentLanguage === "eng" ? "EN" : "ES"
      }
      setIsLoading(true)
      const res = await HttpClient.requestData("reportbuilder/pdf/capitalizationtable", "POST", data);
      // console.log('sendRes', res, sendData)
      if (res && res.status) {
        setIsLoading(false)
        toast.success(sentMsg)
      } else {
        setIsLoading(false)
        toast.error("Error")
      }
    }
    else {

    }
  }

  const SchedDateMemo = useMemo(() => {
    switch (schedName) {
      case "daily": return "";
      case "weekly": return weekName.day;
      case "monthly": return monthName;
      default: return "";
    }
  }, [schedName, weekName, monthName]);


  // click on schedule
  const handleSchedule = async () => {
    // console.log("Schedulee", selectNameMemo)
    const sendData = {
      // email: sendForm.email,
      // scheduleType: schedName,
      // scheduleDate: SchedDateMemo,
      // regCode: "",
      // startData: reportDate.startData,
      // endDate: reportDate.startData,
      // subject: selectNameMemo,
      // type: ScheduleTypeMemo,
      // language: currentLanguage === "eng" ? "EN" : "ES"

      "email": sendForm.email,
      "subject": selectNameMemo,
      "scheduleType": schedName,
      "type": reportTypeMemo,
      "language": currentLanguage === "eng" ? "EN" : "ES"
    }
    // console.log("sendDataSche", sendData)
    setShowSchedModal(false);
    setIsLoading(true)
    const res = await HttpClient.requestData("scheduleEmail", "POST", sendData);
    // console.log("dailySchedule", res);
    if (res && res?.status) {
      setIsLoading(false)
      toast.success(language.report_schedule_success);
    } else {
      setIsLoading(false)
    }
  }

  // gettinh hover data of dash board 2
  const getHoverData = async (time) => {
    const data = {
      "dayType": time
    }

    // revenue hover
    setHoverLoading(true);
    const res = await HttpClient.requestData("dashboard-two-hover-revenue", "POST", data);
    // console.log("resddf", res)
    setHoverLoading(false);
    if (res && res?.status) {
      setReportDate((prev) => ({
        ...prev,
        startData: res?.data?.startDate,
        endDate: res?.data?.endDate,
      }))
    } else {
      setHoverLoading(false);
    }
  }


  //for dashboard 2
  useEffect(() => {
    if (location?.state?.time) {
      getHoverData(location?.state?.time);
    }
  }, [location?.state?.time])


  useEffect(() => {
    setRepSelectCon(reportSelect)
  }, [reportSelect])

  useEffect(() => {
    if (location?.state) {
      setReportRadio(location?.state?.reportType)
      setReportSelect(location?.state?.reportName)
      setIsLocationData(true)
    }
  }, [location])


  useEffect(() => {
    if (playing) {
      if (currAudio) {
        if (!currAudio.paused) {
          currAudio.pause();
        }
      }
      if (currentLanguage === "eng") {
        setCurrAudio(new Audio(view_reports_en));
      } else {
        setCurrAudio(new Audio(view_reports_es));
      }
    } else {
      if (currAudio) {
        if (!currAudio.paused) {
          currAudio.pause();
          setCurrAudio(null);
        }
      }
    }

    return () => {
      if (currAudio && !currAudio.paused) {
        currAudio.pause();
      }
    };
  }, [playing, currentLanguage]);




  // useEffect(() => {
  //   console.log("currAudio", currAudio);
  //   if (currAudio) {
  //     if (!currAudio.paused) {
  //       currAudio.pause();
  //     }
  //     currAudio.play();
  //   }
  //   return () => {
  //     if (currAudio && !currAudio.paused) {
  //       currAudio.pause();
  //     }
  //   };
  // }, [currAudio]);


  return (
    <>
      <div className="container">
        <div id="mng-top" className="view-report">
          <h1>{language.reports_manager}</h1>
        </div>

        {/* radio section */}
        <div className="report-radio">
          <div style={{ position: "relative" }} className="btnn form-check">
            {/* <input
              type="radio"
              id="f-option"
              name="selector"
              className="form-check-input"
            /> */}
            <label
              className="report-radio-label form-check-label"
              htmlFor="f-option"
            >
              {language.out_of_box}
            </label>
            <div
              class="check"
              style={{ backgroundColor: `${reportRadio === "out" ? "#0f2739" : "#fff"}` }}
              onClick={() => {
                setReportRadio("out")
                setReportSelect("Select")
              }}
            >
              {/* <div className="inside" /> */}
            </div>
          </div>
          <div style={{ position: "relative" }} className="btnn">
            {/* <input
              type="radio"
              id="s-option"
              name="selector"
            /> */}
            <label
              className="report-radio-label form-check-label"
              for="s-option"
              style={{ marginLeft: "35px" }}
            >
              {language.user_self_service}
            </label>
            <div
              class="check"
              style={{ backgroundColor: `${reportRadio === "self" ? "#0f2739" : "#fff"}` }}
              onClick={() => {
                setReportRadio("self")
                setReportSelect("Select")
              }}
            >
              <div className="inside" />
            </div>
          </div>
        </div>

        <div className="form-group report-select">
          <select
            className="form-control"
            id="exampleFormControlSelect1"
            value={reportSelect}
            onChange={(e) => setReportSelect(e.target.value)}
          >
            <option value="Select">{language.select}</option>
            {
              reportRadio === "out" &&
              <>
                <option className="report-select-option" value="Financial">{language.general_fian_overview}</option>
                <option className="report-select-option" value="Transaction">{language.transaction_detail_report}</option>
                <option className="report-select-option" value="ExpenseDetail">{language.expense_detail_report}</option>
                <option value="Profitability">{isBusiness ? language.profitability : language.margin_report}</option>
              </>
            }

            {
              reportRadio === "self" &&
              <>
                <option value="Revenue">{isBusiness ? language.act_budget_rev : language.act_budget_income_rep}</option>
                <option value="Expense">{language.act_budget_expense}</option>
                <option value="Profit">{isBusiness ? language.act_budget_profit : language.act_budget_margin_rep}</option>

                {/* build report */}
                <option value="IncomeStatement">{language.income_statement_profit_los}</option>
                <option value="BalanceSheet">{language.balance_sheet}</option>
                <option value="TrialBalance">{language.trial_balance}</option>
                <option value="Capital">{language.cap_table_owner}</option>
                <option value="Budget Forecast">{language.budget_forcast}</option>
                <option value="Accounts Payable Aging Report">{language.account_payable}</option>
                <option value="Financial Statement">{language.personel_financial_statement}</option>
                <option value="Business Financial Statement">{language.bussiness_fs}</option>
                <option value="13 Week Cash Flow Statement">{language.thirteenweek_cfs}</option>
                <option value="12 Month Cash Flow Statement">{language.twelveMonth_cfs}</option>
              </>
            }
          </select>
        </div>

        {/* input section  */}
        {
          reportSelect !== "Select" &&
          <ReportInputSection
            setReportDate={setReportDate}
            reportDate={reportDate}
            reportSelect={reportSelect}
            setSendName={setSendName}
            setShowModal={setShowModal}
            setShowSchedModal={setShowSchedModal}
            setSchedName={setSchedName}
            schedNameMemo={schedNameMemo}
            setWeekName={setWeekName}
            setMonthName={setMonthName}
            pdfData={pdfData}
            pdfLoading={pdfLoading}
            selectNameMemo={selectNameMemo}
            hoverLoading={hoverLoading}
            time={location?.state?.time}
          />
        }


        {/* table sectiobn */}
        {
          reportSelect === "Financial" &&
          < FinancialOverviewTable reportDate={reportDate} />
        }

        {
          reportSelect === "Transaction" &&
          <TransactionTable
            reportDate={reportDate}
          />
        }

        {
          reportSelect === "ExpenseDetail" &&
          <ExpenseDetailTable
            reportDate={reportDate}
          />
        }

        {
          reportSelect === "Profitability" &&
          <ProfitabilityTable
            reportDate={reportDate}
          />
        }

        {
          reportSelect === "Revenue" &&
          <BudgetRevenueTable
            reportDate={reportDate}
          />
        }

        {
          reportSelect === "Expense" &&
          <BudgetExpenceTable
            reportDate={reportDate}
          />
        }

        {
          reportSelect === "Profit" &&
          <BudgetProfitTable
            reportDate={reportDate}
          />
        }

        {/* build report */}
        {reportSelect === "IncomeStatement" && <ViewIncomeStatement setBuildRepData={setBuildRepData} reportDate={reportDate} />}

        {reportSelect === "BalanceSheet" && <ViewBalanceSheet setBuildRepData={setBuildRepData} reportDate={reportDate} />}

        {reportSelect === "TrialBalance" && <ViewTrialBalance setBuildRepData={setBuildRepData} reportDate={reportDate} />}

        {reportSelect === "Capital" && <ViewCapitalBalance setBuildRepData={setBuildRepData} reportDate={reportDate} />}

        {reportSelect === "Budget Forecast" && <ViewBudgetForcast setBuildRepData={setBuildRepData} reportDate={reportDate} />}

        {reportSelect === "Accounts Payable Aging Report" && <ViewAgingReport setBuildRepData={setBuildRepData} reportDate={reportDate} />}

        {reportSelect === "Financial Statement" && <ViewFinancialReport setBuildRepData={setBuildRepData} reportDate={reportDate} />}

        {reportSelect === "Business Financial Statement" && <ViewBussinessFS setBuildRepData={setBuildRepData} reportDate={reportDate} />}

        {reportSelect === "13 Week Cash Flow Statement" && <View13WeekCFS setBuildRepData={setBuildRepData} reportDate={reportDate} />}

        {reportSelect === "12 Month Cash Flow Statement" && <View12MonthCFS setBuildRepData={setBuildRepData} reportDate={reportDate} />}
        
        {
          showSendModal &&
          <SendReportModal
            setSendName={setSendName}
            setShowModal={setShowModal}
            sendName={sendName}
            selectNameMemo={selectNameMemo}
            handleSend={handleSend}
            setSendForm={setSendForm}
            sendForm={sendForm}
          />
        }

        {
          showSchedModul &&
          <ScheduleModal
            setSendName={setSendName}
            setShowSchedModal={setShowSchedModal}
            sendName={sendName}
            selectNameMemo={selectNameMemo}
            handleSchedule={handleSchedule}
            setSendForm={setSendForm}
            sendForm={sendForm}
            schedName={schedName}
          />
        }

      </div>
    </>
  );
}

export default ViewReports;
